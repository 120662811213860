import {
  RoundedCardStyled,
  RoundedCardTitleContainer,
  RoundedCardIcon,
  RoundedCardTitle,
  RoundedCardChildrenContainer,
} from './StyledDinamizations';

export default function RoundedCard(props: any) {
  const { icon, text, children } = props;

  return (
    <RoundedCardStyled>
      <RoundedCardTitleContainer>
        <RoundedCardIcon>{icon}</RoundedCardIcon>
        <RoundedCardTitle dangerouslySetInnerHTML={{ __html: text }} />
      </RoundedCardTitleContainer>
      <RoundedCardChildrenContainer>
        {children}
      </RoundedCardChildrenContainer>
    </RoundedCardStyled>
  );
}
