import Theme from '../../../styles/Theme';
import CardPoints from '../../organisms/Dinamizations/CardPoints';
import CardRanking from '../../organisms/Dinamizations/CardRanking';
import BigCardRanking from '../../organisms/Dinamizations/BigCardRanking';
import {
  ContainerItems,
  DinamizationText,
  RankingMultipleCardContainer,
} from '../../organisms/Dinamizations/StyledDinamizations';

export default function RankingMultipleCard(props:any) {
  const { data } = props;
  const firstCard = data?.[0];
  const otherCards = data?.slice(1);

  return (
    <RankingMultipleCardContainer>
      {firstCard && (
        <BigCardRanking
          number={firstCard?.number}
          title={firstCard?.title}
          subtitle={firstCard?.subtitle}
        >
          <ContainerItems
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <CardPoints
              color={Theme.colorPrimary}
              title="PUNTOS"
              value={firstCard?.points}
              colorBackground={Theme.colorWhite}
              fontSize="16px"
              showShadow
            />
            {firstCard?.products?.map((product:any) => (
              <ContainerItems
                display="block"
                key={product?.title}
                textAlign="center"
              >
                <DinamizationText
                  fontSize="14px"
                  fontWeight="400"
                  color={Theme.fontColorSecondary}
                  margin="0"
                >
                  {product?.title}
                </DinamizationText>
                <DinamizationText
                  fontSize="16px"
                  fontWeight="700"
                  color={Theme.fontColorSecondary}
                  margin="0"
                >
                  {product?.value}
                </DinamizationText>
              </ContainerItems>
            ))}
          </ContainerItems>
        </BigCardRanking>
      )}
      <ContainerItems
        width="94%"
        display="grid"
        gap="18px"
        margin="0 auto"
      >
        {otherCards && otherCards.length > 0 && otherCards.map((card:any) => (
          <CardRanking
            key={card?.title}
            number={card?.number}
            title={card?.title}
            subtitle={card?.subtitle}
          >
            <ContainerItems
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <CardPoints
                color={Theme.colorPrimary}
                title="PUNTOS"
                value={card?.points}
                colorBackground={Theme.colorWhite}
                fontSize="16px"
                showShadow
              />
              {card?.products?.map((product:any) => (
                <ContainerItems
                  display="block"
                  textAlign="center"
                >
                  <DinamizationText
                    fontSize="14px"
                    fontWeight="400"
                    color={Theme.fontColorSecondary}
                    margin="0"
                  >
                    {product.title}
                  </DinamizationText>
                  <DinamizationText
                    fontSize="16px"
                    fontWeight="700"
                    color={Theme.fontColorSecondary}
                    margin="0"
                  >
                    {product.value}
                  </DinamizationText>
                </ContainerItems>
              ))}
            </ContainerItems>
          </CardRanking>
        ))}
      </ContainerItems>
    </RankingMultipleCardContainer>
  );
}
