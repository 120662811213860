import {
  RappelInfoStyled,
  RappelDescription,
  RappelInfoContainer,
  RappelInfoSegment,
  RappelSegment,
  DinamizationText,
  ContainerItems,
} from './StyledDinamizations';
import RappelSegmentCard from './RappelSegmentCard';
import Theme from '../../../styles/Theme';

export default function RappelInfo(props: any) {
  const { data } = props;
  const segmentsName = Object.keys(data?.segments);

  const firstSegment = segmentsName[0];
  const lastSegment = segmentsName[segmentsName.length - 1];

  return (
    <RappelInfoStyled>
      <ContainerItems
        width="100%"
        justifyContent="space-between"
        display="inline-flex"
        gap="10px"
      >
        <RappelDescription>{data.description}</RappelDescription>
        <DinamizationText
          fontSize="23px"
          fontWeight="700"
          color={Theme.colorBlack}
          margin="0 0 20px 0"
        >
          Importe Rappel:
          &nbsp;
          {data.total_amount}
        </DinamizationText>
      </ContainerItems>
      <RappelInfoContainer>
        {segmentsName && segmentsName?.map((segment:any) => (
          <RappelInfoSegment key={segment}>
            <RappelSegment
              cumple={data.segments[segment]?.cumple}
              isFirst={firstSegment === segment}
              isLast={lastSegment === segment}
            />
            <RappelSegmentCard
              title={segment}
              cumple={data.segments[segment]?.cumple}
              numberDelegates={data.segments[segment]?.number_delegates}
              incentives={data.segments[segment]?.incentives}
              moreInfo={data.segments[segment]?.more_info}
              isFirst={firstSegment === segment}
            />
          </RappelInfoSegment>
        ))}
      </RappelInfoContainer>
    </RappelInfoStyled>
  );
}
