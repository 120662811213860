import { useQueryClient } from 'react-query';
import Theme from '../../../styles/Theme';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
// import ImageBanner from '../../atoms/ImageBanner/ImageBanner';
import CardBoxList2024 from '../../atoms/CardBox/RankingCard';
import CardBoxImagesList from '../../atoms/CardBox/CardBoxImagesList';
import Ranking2025 from './Ranking2025';
import isMediator from '../../../hooks/useUserType';
import useYear from '../../../hooks/useYear';
// import rankingBannerImage from '../../../assets/images/ranking.jpg';

export default function RankingGlobalPosition() {
  const queryClient = useQueryClient();
  const userCategory: any = queryClient.getQueryState(['UserCategory']);
  const userCategorId = userCategory?.data?.userCategory?.id;
  const { year } = useYear();

  return (
    userCategory?.data?.userCategory !== undefined && (
      userCategory && (
        <>
          {year <= 2024 && (
            <>
              {/* <Container>
                <ImageBanner img={rankingBannerImage} />
              </Container> */}
              {!isMediator(userCategorId) && (
                <CardBoxImagesList />
              )}
              <Container>
                <Typography color={Theme.colorRanking} size="36px" weight="700" type="h1">
                  Ranking DGT
                </Typography>
              </Container>
              <CardBoxList2024 />
            </>
          )}
          {year >= 2025 && (<Ranking2025 isMediator={isMediator(userCategorId)} />)}
        </>
      )
    )
  );
}
