import {
  ChallengeCardStyled,
  ChallengeCardHeaderContainer,
  ChallengeCardContainer,
} from './StyledDinamizations';
import ChallengeCardData from './ChallengeCardData';
import { getIcon } from '../../pages/Dinamizations/DinamizationsQuarterly';
import Theme from '../../../styles/Theme';

export default function ChallengeCard({
  title,
  icon,
  data1Amount,
  data1TotalAmount,
  data1Description,
  data2Amount,
  data2TotalAmount,
  data2Description,
  color,
}: any) {
  return (
    <ChallengeCardStyled>
      <ChallengeCardHeaderContainer color={color}>
        {getIcon(icon, Theme.colorPrimary)}
        {title}
      </ChallengeCardHeaderContainer>
      <ChallengeCardContainer>
        <ChallengeCardData
          description={data1Description}
          amount={data1Amount}
          totalAmount={data1TotalAmount}
          color={color}
        />
        <ChallengeCardData
          description={data2Description}
          amount={data2Amount}
          totalAmount={data2TotalAmount}
          color={color}
        />
      </ChallengeCardContainer>
    </ChallengeCardStyled>
  );
}
