import imgCampaign from '../../../assets/images/img_campaign.jpg';
import imgRappels from '../../../assets/images/img_rappels.jpg';
import imgConvention from '../../../assets/images/image04.jpg';
import imgCampaign2023 from '../../../assets/images/bases_campaign.jpg';
import imgRappels2023 from '../../../assets/images/bases_rappel.jpg';
import imgConvention2023 from '../../../assets/images/argentina_2024.jpg';
import imgRanking2023 from '../../../assets/images/Ranking2.jpg';
import imgCampaign2024 from '../../../assets/images/bases_campaign_2024.jpg';
import imgRappels2024 from '../../../assets/images/bases_rappel_2024.jpg';
import imgConvention2024 from '../../../assets/images/convention_2024.jpg';
import imgRanking2024 from '../../../assets/images/ranking_2024.jpg';
import imgCampaign2025 from '../../../assets/images/bases_campaign_2025.jpg';
import imgRappels2025 from '../../../assets/images/bases_rappel_2025.webp';
import imgConvention2025 from '../../../assets/images/convention_2025.webp';
import imgRanking2025 from '../../../assets/images/ranking_2025.jpg';
import { DataCalendar } from '../../../interfaces/Calendar.d';

export default function getCalendarData() {
  const data: Array<DataCalendar> = [
    {
      year: 2022,
      info: [
        {
          id: 0,
          title: 'Campañas',
          paragraph: 'Consulta aquí las bases de las Campañas',
          image: imgCampaign,
          link: {
            name: 'Consultar campañas',
            url: 'campaign',
          },
        },
        {
          id: 1,
          title: 'Rappeles',
          paragraph: 'Consulta aquí las bases de los Rappeles',
          image: imgRappels,
          link: {
            name: 'Consultar rappeles',
            url: 'rappel',
          },
        },
        {
          id: 2,
          title: 'Convención',
          paragraph: 'Consulta aquí las bases de la Convención',
          image: imgConvention,
          link: {
            name: 'Consultar convención',
            url: 'convention',
          },
        },
      ],
    },
    {
      year: 2023,
      info: [
        {
          id: 0,
          title: 'Campañas',
          paragraph: 'Consulta aquí las bases de las Campañas',
          image: imgCampaign2023,
          link: {
            name: 'Consultar campañas',
            url: 'campaign',
          },
        },
        {
          id: 1,
          title: 'Rappeles',
          paragraph: 'Consulta aquí las bases de los Rappeles',
          image: imgRappels2023,
          link: {
            name: 'Consultar rappeles',
            url: 'rappel',
          },
        },
        {
          id: 2,
          title: 'Convención',
          paragraph: 'Consulta aquí las bases de la Convención',
          image: imgConvention2023,
          link: {
            name: 'Consultar convención',
            url: 'convention',
          },
        },
        {
          id: 3,
          title: 'Ranking',
          paragraph: 'Consulta aquí los procedimientos de los rankings',
          image: imgRanking2023,
          link: {
            name: 'Consultar rankings',
            url: 'ranking',
          },
        },
      ],
    },
    {
      year: 2024,
      info: [
        {
          id: 0,
          title: 'Campañas',
          paragraph: 'Consulta aquí las bases de las Campañas',
          image: imgCampaign2024,
          link: {
            name: 'Consultar campañas',
            url: 'campaign',
          },
        },
        {
          id: 1,
          title: 'Rappeles',
          paragraph: 'Consulta aquí las bases de los Rappeles',
          image: imgRappels2024,
          link: {
            name: 'Consultar rappeles',
            url: 'rappel',
          },
        },
        {
          id: 2,
          title: 'Convención',
          paragraph: 'Consulta aquí las bases de la Convención',
          image: imgConvention2024,
          link: {
            name: 'Consultar convención',
            url: 'convention',
          },
        },
        {
          id: 3,
          title: 'Ranking',
          paragraph: 'Consulta aquí los procedimientos de los rankings',
          image: imgRanking2024,
          link: {
            name: 'Consultar rankings',
            url: 'ranking',
          },
        },
      ],
    },
    {
      year: 2025,
      info: [
        {
          id: 0,
          title: 'Campañas',
          paragraph: 'Consulta aquí las bases de las Campañas',
          image: imgCampaign2025,
          link: {
            name: 'Consultar campañas',
            url: 'campaign',
          },
        },
        {
          id: 1,
          title: 'Rappeles',
          paragraph: 'Consulta aquí las bases de los Rappeles',
          image: imgRappels2025,
          link: {
            name: 'Consultar rappeles',
            url: 'rappel',
          },
        },
        {
          id: 2,
          title: 'Convención',
          paragraph: 'Consulta aquí las bases de la Convención',
          image: imgConvention2025,
          link: {
            name: 'Consultar convención',
            url: 'convention',
          },
        },
        {
          id: 3,
          title: 'Ranking',
          paragraph: 'Consulta aquí los procedimientos de los rankings',
          image: imgRanking2025,
          link: {
            name: 'Consultar rankings',
            url: 'ranking',
          },
        },
      ],
    },
  ];

  return { data };
}
