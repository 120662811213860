import Theme from '../../../styles/Theme';
import HeaderCard from '../../organisms/Dinamizations/HeaderCard';
import RoundedCard from '../../organisms/Dinamizations/RoundedCard';
import CardCampaign from '../../organisms/Dinamizations/CardCampaign';
import PositionCard from '../../organisms/Dinamizations/PositionCard';
import RoundedPercentageGraphic from '../../organisms/Dinamizations/RoundedPercentageGraphic';
import {
  CampaignCardsContainer,
  CampaignHeaderCardsContainer,
  CampaignCards,
  DinamizationText,
  ContainerItems,
} from '../../organisms/Dinamizations/StyledDinamizations';
import { getIcon } from './DinamizationsQuarterly';

export default function SummaryCampaignCard(props:any) {
  const { info, cards, products } = props;

  return (
    <HeaderCard
      title="Resumen"
      color={Theme.colorPrimary}
      width="100%"
    >
      <CampaignCardsContainer>
        <CampaignHeaderCardsContainer>
          <ContainerItems id="old-position">
            {/* start Tarjeta posición semana anterior */}
            <HeaderCard
              title="SEMANA ANTERIOR"
              color={Theme.colorSecondary3}
              width="100px"
              center
              headerFontSize="12px"
              miniCard
              backgroundColor={Theme.colorWhite}
            >
              <DinamizationText
                fontSize="22px"
                fontWeight="700"
                color={Theme.colorSecondary3}
                margin="0 auto"
              >
                {info?.oldPosition}
              </DinamizationText>
            </HeaderCard>
            {/* end Tarjeta posición semana anterior */}
            <PositionCard position={info?.currentPosition} />
          </ContainerItems>
          <ContainerItems id="current-position">
            {/* start Cards */}
            {cards && cards.length > 0 && cards.map((card:any) => (
              <RoundedCard
                icon={getIcon(card.icon, Theme.fontColorSecondary)}
                text={card.text}
                key={card.text}
              >
                {card.showRoundedGraphic ? (
                  <RoundedPercentageGraphic
                    percentageValue={card.percentage}
                    width="60"
                  >
                    { card.value ? (
                      <DinamizationText
                        fontSize="16px"
                        fontWeight="600"
                        color={Theme.colorPrimary}
                        margin="0 auto"
                      >
                        {card.value}
                      </DinamizationText>
                    ) : undefined }
                  </RoundedPercentageGraphic>
                ) : (
                  <DinamizationText
                    fontSize="32px"
                    fontWeight="600"
                    color={Theme.colorPrimary}
                    margin="0 auto"
                  >
                    {card.value}
                  </DinamizationText>
                )}
              </RoundedCard>
            ))}
            {/* end Cards */}
          </ContainerItems>
        </CampaignHeaderCardsContainer>
        <CampaignCards>
          {products && products.length > 0 && products.map((product:any) => (
            <CardCampaign
              key={product.title}
              title={product.title}
              subtitle={product.subtitle}
              amount={product.amount}
              totalAmount={
                product.totalAmount
              }
              points={product.points}
              percentage={product.percentage}
            />
          ))}
        </CampaignCards>
      </CampaignCardsContainer>
    </HeaderCard>
  );
}
