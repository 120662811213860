import {
  ButtonsContainer,
  Button,
} from './StyledDinamizations';

export default function DinamizationButtons(props: any) {
  const {
    buttons,
    selected,
    setSelected,
    color,
    margin,
  } = props;

  return (
    <ButtonsContainer margin={margin}>
      {buttons?.map((button:string) => (
        <Button
          key={button}
          selected={selected === button}
          onClick={() => setSelected(button)}
          color={color}
        >
          {button}
        </Button>
      ))}
    </ButtonsContainer>
  );
}
