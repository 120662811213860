import {
  useState, useEffect, Fragment, useRef,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { columnsSummary, headers } from './dataRappelsTeam';
import { IFilter } from '../../../interfaces/IFilter.d';
import Theme from '../../../styles/Theme';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import ImageBanner from '../../atoms/ImageBanner/ImageBanner';
import { Card } from '../../atoms/Card/Card';
import { LinkTable, Table } from '../../atoms/Table/Table';
import { Tabs, TabPanel } from '../../atoms/Tabs';
import LinkTo from '../../atoms/Link/LinkTo';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import GenericError from '../Error/GenericError';
import FilterModel from '../../molecules/Filter/FilterModel';
import Score from '../../molecules/Score/Score';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import SubscriptionButton from '../../molecules/Subscriptios/SubscriptionButton';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import useCsvNumber from '../../../hooks/useCsvNumber';
import useFormatDate from '../../../hooks/useDate';
import useNumberFormat from '../../../hooks/useNumberFormat';
import useProcessData from '../../../hooks/useProcessData';
import useYear from '../../../hooks/useYear';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_TEAM_TIMEFRAME_RESULTS_GQL from '../../../graphql/queries/getTeamTimeframeResultsGql';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import GET_MY_TEAM_PLANS_BY_PARAMS_GQL from '../../../graphql/queries/getMyTeamPlansByParamsGql';
import rappelBannerImage from '../../../assets/images/rappel_header.jpg';

export interface TeamRappelsParams {
  id: string;
  user: any;
}

export default function RappelsTeam({ id, user }:TeamRappelsParams) {
  const type = 'Rappel';
  const userId = user?.me?.id;
  const graphQLClient = createGraphQLClient();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { grouping } = useParams();
  const { year } = useYear();

  const selectedFilters: IFilter = { temporalidad: [] };
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const [activeTab, setActiveTab] = useState(grouping || id || '');

  const variablesFilters = activeTab !== 'resumen' ? {
    grouping: activeTab.toUpperCase(),
    type,
    year,
  } : {
    type,
    year,
  };

  const {
    data: dataFilters,
    error: errorFilters,
    isFetching: isFetchingFilters,
  } = useQuery(
    ['RappelTeamFilters', variablesFilters],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      variablesFilters,
    )),
    {
      staleTime: Infinity,
    },
  );

  const rappelParams:any = {
    type,
    grouping: activeTab !== 'resumen' ? activeTab.toUpperCase() : undefined,
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    category: globalFilter.category || undefined,
    dgt: globalFilter.dgt || undefined,
    dt: globalFilter.dt || undefined,
    od: globalFilter.od || undefined,
    firstName: globalFilter.firstName || undefined,
    lastName: globalFilter.lastName || undefined,
    nif: globalFilter.nif || undefined,
    search: globalFilter.search || '',
    year,
    status: globalFilter.status || '',
  };

  Object.keys(rappelParams).forEach(
    (key:any) => (rappelParams[key] === undefined || rappelParams[key] === '') && delete rappelParams[key],
  );

  const {
    data: planRappel,
    error: errorRappel,
    refetch: refetchRappel,
    isLoading: isLoadingRappel,
    isFetching: isFetchingRappel,
    status: statusRappel,
  } = useQuery(
    ['RappelTeam', rappelParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_PLANS_BY_PARAMS_GQL,
      rappelParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const groupingList :any = queryClient.getQueryState(['GroupingList']);
  const list = groupingList?.data?.groupingList?.grouping;
  const tabs = list && list[year] ? list[year].Rappel : [];

  useEffect(() => {
    setGlobalFilter({ ...globalFilter, grouping: activeTab });
  }, []);

  useEffect(() => {
    navigate(`/team/rappel/${activeTab}`, { replace: true });
    setGlobalFilter({ ...globalFilter, grouping: activeTab });
  }, [activeTab]);

  useEffect(() => {
    if (statusRappel !== 'success') {
      refetchRappel();
    }
  }, [globalFilter]);

  const debouncedSearch = (value :any, currentGlobalFilter :FilterModel) => {
    setGlobalFilter({ ...currentGlobalFilter, search: value });
  };

  const setServicesValueDebounced = useRef(_.debounce(debouncedSearch, 1000));

  const handleChange = (e :any) => {
    setServicesValueDebounced.current(e.currentTarget.value, globalFilter);
  };

  let dataRappels:Array<[]> = [];

  dataRappels = planRappel?.myTeamPlans?.nodes?.map((plan:any) => ({
    plan: plan?.planDescription,
    amount: useNumberFormat(plan?.resultsAmount, true),
    meets: plan?.winsNumber,
    situation: plan?.planStatusName,
    comply: plan?.fulfillsNumber,
    participants: useNumberFormat(plan?.participantsNumber),
    dataDate: useFormatDate(plan?.planDataDate),
    details: <LinkTo to={`/team/rappel/resumen/${plan?.planCode}`} color={Theme.colorPrimary}>Ver detalle</LinkTo>,
    timeframe: plan?.planTimeframe,
    startDate: plan?.planStartDate,
    endDate: plan?.planEndDate,
    grouping: plan?.planGrouping,
    subscribed: <SubscriptionButton
      subscribed={plan.planSubscribed}
      planCode={plan.planCode}
      userId={userId}
    />,
    download: <LinkTable url={plan?.planBasesUrl} title="Descargar" />,
  }));

  const rappelsByTimeFrame = dataRappels && dataRappels.reduce((rv:any, x:any) => {
    let dynamicTimeFrame = '';
    if (x.timeframe === 'TRIMESTRAL') {
      const startDateSplited = x.startDate.split('-')[1];
      if (startDateSplited === '01') {
        dynamicTimeFrame = '1T';
      } else if (startDateSplited === '04') {
        dynamicTimeFrame = '2T';
      } else if (startDateSplited === '07') {
        dynamicTimeFrame = '3T';
      } else if (startDateSplited === '10') {
        dynamicTimeFrame = '4T';
      }
    }
    if (dynamicTimeFrame.length > 0) {
      (rv[dynamicTimeFrame] = rv[dynamicTimeFrame] || []).push(x);
    } else {
      (rv[x.timeframe] = rv[x.timeframe] || []).push(x);
    }
    return rv;
  }, {});

  const orderedCamps:any = {
    '1T': rappelsByTimeFrame?.['1T'] || [],
    '2T': rappelsByTimeFrame?.['2T'] || [],
    '3T': rappelsByTimeFrame?.['3T'] || [],
    '4T': rappelsByTimeFrame?.['4T'] || [],
    Anual: rappelsByTimeFrame?.ANUAL || [],
    Semestral: rappelsByTimeFrame?.SEMESTRAL || [],
    Mensual: rappelsByTimeFrame?.MENSUAL || [],
    Interanual: rappelsByTimeFrame?.INTERANUAL || [],
    Semanal: rappelsByTimeFrame?.SEMANAL || [],
    Quincenal: rappelsByTimeFrame?.QUINCENAL || [],
    Bimestral: rappelsByTimeFrame?.BIMESTRAL || [],
    Cuatrimestral: rappelsByTimeFrame?.CUATRIMESTRAL || [],
  };

  const csvData:any = {};
  Object.keys(orderedCamps).forEach((k) => {
    csvData[k] = [];
    if (orderedCamps[k].length) {
      Object.entries(orderedCamps[k])?.forEach(([key]: any) => {
        csvData[k][key] = {};
        Object.entries(orderedCamps[k][key])?.forEach(([key2, value2]: any) => {
          csvData[k][key][key2] = useCsvNumber(value2);
        });
      });
    }
    return null;
  });

  const sectionTitle:any = {
    '1T': 'Trimestre 1',
    '2T': 'Trimestre 2',
    '3T': 'Trimestre 3',
    '4T': 'Trimestre 4',
    Semanal: 'Semanal',
    Quincenal: 'Quincenal',
    Mensual: 'Mensual',
    Bimestral: 'Bimestral',
    Cuatrimestral: 'Cuatrimestral',
    Semestral: 'Semestral',
    Anual: 'Anual',
    Interanual: 'Interanual',
  };

  const variablesResults = activeTab !== 'resumen' ? {
    grouping: activeTab.toUpperCase(),
    type,
    myTeam: true,
    status: globalFilter.status,
    year,
  } : {
    type,
    myTeam: true,
    status: globalFilter.status,
    year,
  };

  const orderedData:any = {};
  Object.keys(orderedCamps).map((k:any) => {
    const {
      data: dataGetResults,
    } = useQuery(
      ['RappelTeamResults', { ...variablesResults, timeframe: k }],
      async () => (graphQLClient && graphQLClient.request(
        GET_TEAM_TIMEFRAME_RESULTS_GQL,
        { ...variablesResults, timeframe: k },
      )),
      {
        staleTime: Infinity,
      },
    );
    orderedData[k] = dataGetResults?.summaryTeamChartByTimeframe;
    return orderedData;
  });

  const scoreIndicators = (k: any) => ([
    {
      id: 0,
      title: 'Participa',
      subtitle: '',
      score: useNumberFormat(orderedData[k]?.participantsCount),
      icon: 'participant',
    },
    {
      id: 1,
      title: 'Cumplen',
      subtitle: '',
      score: useNumberFormat(orderedData[k]?.totalUserMetCount),
      icon: 'comply',
    },
    {
      id: 2,
      title: 'Importe',
      subtitle: '',
      score: useNumberFormat(orderedData[k]?.totalAmount, true),
      icon: 'euro',
    },
    {
      id: 3,
      title: 'Tréboles',
      subtitle: '',
      score: useNumberFormat(orderedData[k]?.totalTreboles),
      icon: 'clover',
    },
  ]);

  if (errorRappel || errorFilters) {
    return <GenericError />;
  }

  return (
    <Container>
      <ImageBanner img={rappelBannerImage} />
      <Typography color={Theme.colorRappel} size="38px" margin="0 0 1rem" display="block" align="center" weight="500">Rappeles</Typography>
      <ButtonBack onClick={() => navigate('/team/summary/general')} type="button" style={{ marginBottom: '30px' }}>Volver</ButtonBack>
      <Card background="transparent" display="flex" alignItems="flex-start" justifyContent="space-between">
        <GroupFilterGeneral
          selectedFilters={selectedFilters}
          filter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <input
          type="text"
          placeholder="Buscar por nombre de rappel"
          onChange={handleChange}
          onPaste={handleChange}
          className="search"
          style={{ height: '38px', maxWidth: '450px', margin: '1rem 0 0' }}
        />
      </Card>

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={setActiveTab}
        theme={Theme.colorRappel}
      />

      <TabPanel isActivePanel id="rappel-tab-panel">
        {(activeTab !== 'resumen') && (
          <div style={{ textAlign: 'right', margin: '3rem 0 2rem' }}>
            <LinkTo
              to={`/team/rappel/results/${activeTab}?code=${planRappel?.myTeamPlans?.nodes[0]?.planCode}`}
              background={Theme.colorRappel}
              border="8px"
              color={Theme.colorWhite}
              padding="0.72rem 1.2rem"
              underline="none"
            >
              {`Ver resultados nominales ${activeTab}`}
            </LinkTo>
          </div>
        )}

        {!isFetchingFilters ? (
          <FilterForm
            dataFilters={dataFilters}
            filter={globalFilter}
            setFilter={setGlobalFilter}
            type={type}
            grouping={activeTab}
            fetching={isFetchingRappel}
          />
        ) : <FilterFormSkeleton countFilters={10} />}

        {(!isLoadingRappel && !isFetchingRappel && columnsSummary && dataRappels && dataFilters) ? (
          Object.keys(orderedCamps).map((k:any) => (
            orderedCamps[k].length ? (
              <Fragment key={k}>
                <Typography
                  align="center"
                  color={Theme.colorRappel}
                  display="block"
                  size="28px"
                  margin="2rem auto"
                  weight="500"
                >
                  {sectionTitle[k]}
                </Typography>
                <Card
                  background={Theme.colorWhite}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap="1rem"
                  margin="0"
                >
                  {headers && csvData[k].length > 0 && (
                    <CardDataExportXLSX
                      headers={headers}
                      data={csvData[k]}
                      margin="0 0 0.5rem auto"
                      name={`rappel_team_${activeTab}_${k.toLowerCase()}`}
                    />
                  )}
                </Card>
                <Table
                  columns={columnsSummary}
                  data={useProcessData(orderedCamps[k])}
                  theme={Theme.colorRappel}
                  className="tb-rappel__team"
                />
                <Score score={scoreIndicators(k)} cols={4} maxWidth="600px" margin="0 auto 4rem auto" />
              </Fragment>
            ) : ''
          ))
        ) : <TableSkeleton countCell={6} theme={Theme.colorRappel} />}
      </TabPanel>
    </Container>
  );
}
