import Theme from '../../../styles/Theme';
import {
  CardCampaignContainer,
  CardCampaignHeaderStyled,
  CardCampaignContainerStyled,
  CardCampaignContainerValues,
  CardCampaignAmountDelegates,
  CardCampaignCurrentPositionDelegate,
  DinamizationText,
  ContainerItems,
  CardCampaignCurrentPositionDelegateContainer,
} from './StyledDinamizations';
import ChangePositionCard from './ChangePositionCard';

export default function CardCampaignDelegates(props: any) {
  const {
    title,
    subtitle,
    amount,
    totalAmount,
    oldPosition,
    currentPosition,
  } = props;

  return (
    <CardCampaignContainer>
      <CardCampaignHeaderStyled>
        <DinamizationText
          fontSize="20px"
          fontWeight="700"
          color={Theme.colorWhite}
          margin="0"
        >
          {title}
        </DinamizationText>
        <DinamizationText
          fontSize="16px"
          fontWeight="700"
          color={Theme.colorWhite}
          margin="0"
        >
          {subtitle}
        </DinamizationText>
      </CardCampaignHeaderStyled>
      <CardCampaignContainerValues>
        <CardCampaignContainerStyled>
          <CardCampaignAmountDelegates>
            <b>
              {amount}
              &nbsp;/&nbsp;
            </b>
            {totalAmount}
          </CardCampaignAmountDelegates>
          <ChangePositionCard
            oldPosition={oldPosition}
            currentPosition={currentPosition}
          />
        </CardCampaignContainerStyled>
        <CardCampaignCurrentPositionDelegateContainer>
          <ContainerItems
            width="fit-content"
            justifyContent="center"
            display="grid"
            gap="4px"
            margin="auto"
            alignItems="center"
            textAlign="center"
          >
            <DinamizationText
              fontSize="10px"
              fontWeight="700"
              color={Theme.colorPrimary}
              margin="auto 4px"
            >
              POSICIÓN ACTUAL
            </DinamizationText>
            <CardCampaignCurrentPositionDelegate>
              {currentPosition}
            </CardCampaignCurrentPositionDelegate>
          </ContainerItems>
        </CardCampaignCurrentPositionDelegateContainer>
      </CardCampaignContainerValues>
    </CardCampaignContainer>
  );
}
