import {
  PositionCardContainer,
  PositionCardTitle,
  PositionCardValueContainer,
  PositionCardValue,
} from './StyledDinamizations';
import IconCup from '../../atoms/Icon/IconCup';
import Theme from '../../../styles/Theme';

export default function PositionCard(props: any) {
  const { position } = props;

  return (
    <PositionCardContainer>
      <PositionCardTitle>TU POSICIÓN</PositionCardTitle>
      <PositionCardValueContainer>
        <IconCup color={Theme.colorPrimary} width="60px" />
        <PositionCardValue>{position}</PositionCardValue>
      </PositionCardValueContainer>
    </PositionCardContainer>
  );
}
