import {
  Card, CardBody, CardHeaderGraphics,
} from '../../atoms/Card/Card';
import Typography from '../../atoms/Typography/Typography';
import ImageButton from '../../atoms/ImageButton/ImageButton';
import CardBoxImagesListSummary from '../../atoms/CardBox/CardBoxImagesListSummary';
import { LinkTo } from '../Carousel/StyledCarousel';
import useYear from '../../../hooks/useYear';
import imgAutomocion2024 from '../../../assets/images/automocion_2024.jpg';
import imgCorredores2024 from '../../../assets/images/corredores_2024.jpg';
import imgRedAgencial2024 from '../../../assets/images/red_agencial_2024.jpg';
import imgRedEspecifica2024 from '../../../assets/images/red_especifica_2024.jpg';

export default function CardSummaryGlobalPositionRanking({ team = false }: any) {
  const { year } = useYear();

  return (
    <Card
      key="ranking"
      padding="1.5rem"
      display="flex"
    >
      <CardBody>
        <CardHeaderGraphics>
          <Typography
            color="#CEB277"
            size="24px"
            margin="0 0 1rem"
            weight="500"
            type="h3"
          >
            Ranking
          </Typography>
        </CardHeaderGraphics>
        {year === 2024 ? (
          <div style={{ padding: '0 2rem', marginLeft: 'auto', marginRight: 'auto' }}>
            <ImageButton name="Red Agencial" image={imgRedAgencial2024} target="_blank" href="https://app.powerbi.com/groups/me/reports/140cb97a-721c-4b91-bf6c-fdeaf3884590/ReportSection?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&experience=power-bi" />
            <ImageButton name="Red Específica" image={imgRedEspecifica2024} target="_blank" href="https://app.powerbi.com/groups/me/reports/0ae2a640-c06b-4795-bc15-367c2d1fb2be/ReportSectionfd492d01830b2781d11e?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&experience=power-bi" />
            <ImageButton name="Automoción" image={imgAutomocion2024} target="_blank" href="https://app.powerbi.com/groups/me/reports/f44f53dc-4017-4770-ad04-f5275ea9fda0/ReportSection?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&experience=power-bi" />
            <ImageButton name="Corredores" image={imgCorredores2024} target="_blank" href="https://app.powerbi.com/groups/me/reports/8a5f0deb-8466-40c8-aa72-cc77bb8df8c0/ReportSection?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&experience=power-bi" />
          </div>
        ) : (
          <div style={{ width: '100%', margin: '40px auto', textAlign: 'center' }}>
            <CardBoxImagesListSummary />
          </div>
        )}
        <div className="graphic--footer">
          <LinkTo
            to={{ pathname: team ? '/team/ranking' : '/global-position/ranking' }}
            className="link link--graphic"
          >
            <span>Ver resumen</span>
          </LinkTo>
        </div>
      </CardBody>
    </Card>
  );
}
