import { useQuery } from 'react-query';
import IconCar from '../../atoms/Icon/IconCar';
import IconCup from '../../atoms/Icon/IconCup';
import IconLife from '../../atoms/Icon/IconLife';
import IconStart from '../../atoms/Icon/IconStart';
import IconPerson from '../../atoms/Icon/IconPerson';
import GeneralDinamizationsQuarterly from './GeneralDinamizationsQuarterly';
import DelegatesDinamizationsQuarterly from './DelegatesDinamizationsQuarterly';
import SkeletonDinamizations from './SkeletonDinamizations';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_DINAMIZATION_INFO_GQL from '../../../graphql/queries/getDinamizationInfoGql';

export function getIcon(icon: string, color: string) {
  switch (icon) {
  case 'car':
    return <IconCar color={color} width="24px" />;
  case 'cup':
    return <IconCup color={color} width="24px" />;
  case 'heartbeat':
    return <IconLife color={color} width="24px" />;
  case 'person':
    return <IconPerson color={color} width="24px" />;
  case 'star':
    return <IconStart color={color} width="24px" />;
  default:
    return undefined;
  }
}

export default function DinamizationsQuarterly() {
  const graphQLClient = createGraphQLClient();

  const {
    data: responseData,
    isFetching,
  } = useQuery(
    ['DinamizationInfo', { type: 'Q1' }],
    async () => (graphQLClient && graphQLClient.request(
      GET_DINAMIZATION_INFO_GQL,
      { type: 'Q1', year: 2025 },
    )),
    {
      staleTime: Infinity,
    },
  );
  const data = responseData?.dinamizationInfo;

  return (
    <div>
      {isFetching ? (
        <SkeletonDinamizations />
      ) : (data && (
        <div>
          {/* user_scopes:
            ['DGT', 'DT', 'OD', 'DOFICORRED', 'ACPROPATEM', 'ACVISASOFI', 'DELEGADOS'] */}
          {data?.userInfo.scope === 'DELEGADOS' ? (
            /* Los DELEGADOS van a tener su propia vista diferenciada y separada */
            <DelegatesDinamizationsQuarterly data={data} />
          ) : (
            <GeneralDinamizationsQuarterly data={data} />
          )}
        </div>
      ))}
    </div>
  );
}
