import {
  LastUpdateContainer,
  LastUpdateTitle,
  LastUpdateDate,
} from './StyledDinamizations';

export default function LastDinamizationUpdate(props: any) {
  const { date } = props;

  return (
    <LastUpdateContainer>
      <LastUpdateTitle>Última actualización</LastUpdateTitle>
      <LastUpdateDate>{date}</LastUpdateDate>
    </LastUpdateContainer>
  );
}
