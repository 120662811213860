import {
  MultipleProductsAsesoresStyled,
  MultipleProductsAsesoresProductHeader,
  MultipleProductsAsesoresProductContainer,
  MultipleProductsAsesoresProductFooter,
  AmountAsesoresCard,
  MultipleProductsAsesoresPositionContainer,
  MultipleProductsAsesoresPositionText,
  MultipleProductsAsesoresPositionRanking,
  ContainerItems,
} from './StyledDinamizations';
import ChangePositionCard from './ChangePositionCard';

export default function MultipleProductsAsesores({
  info,
  products,
}: any) {
  return (
    <MultipleProductsAsesoresStyled>
      <ContainerItems
        width="100%"
        display="inline-flex"
        gap="1px"
      >
        {products.map((product:any) => (
          <ContainerItems
            width="100%"
            display="grid"
            gap="0px"
          >
            <MultipleProductsAsesoresProductHeader>
              <span>{product.title}</span>
              <span>{product.subtitle}</span>
            </MultipleProductsAsesoresProductHeader>
            <MultipleProductsAsesoresProductContainer>
              <AmountAsesoresCard>
                {product.np_amount}
              </AmountAsesoresCard>
            </MultipleProductsAsesoresProductContainer>
          </ContainerItems>
        ))}
      </ContainerItems>
      <MultipleProductsAsesoresProductFooter>
        <ContainerItems
          width="50%"
          justifyContent="flex-end"
          padding="0 20px"
        >
          <MultipleProductsAsesoresPositionContainer>
            <MultipleProductsAsesoresPositionText>
              POSICIÓN ACTUAL
            </MultipleProductsAsesoresPositionText>
            <MultipleProductsAsesoresPositionRanking>
              {info?.currentPosition}
            </MultipleProductsAsesoresPositionRanking>
          </MultipleProductsAsesoresPositionContainer>
        </ContainerItems>
        <ContainerItems
          width="50%"
          padding="0 20px"
        >
          <ChangePositionCard
            oldPosition={info?.oldPosition}
            currentPosition={info?.currentPosition}
          />
        </ContainerItems>
      </MultipleProductsAsesoresProductFooter>
    </MultipleProductsAsesoresStyled>
  );
}
