import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import Theme from '../../../styles/Theme';
import { Table } from '../../atoms/Table/Table';
import Loading from '../../atoms/Loading/Loading';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import FilterModel from '../../molecules/Filter/FilterModel';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import GenericError from '../Error/GenericError';
import useYear from '../../../hooks/useYear';
import useFormatDate from '../../../hooks/useDate';
import formatValue from '../../../hooks/useFormatValue';
import formatColumn from '../../../hooks/useFormatColumn';
import useProcessData from '../../../hooks/useProcessData';
import useProcessDataCSV from '../../../hooks/useProcessDataCSV';
import useFilterCsvColumns from '../../../hooks/useFilterCsvColumns';
import useParseToCsvColumns from '../../../hooks/useParseToCsvColumns';
import useMappingColumnNames from '../../../hooks/useMappingColumnNames';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_PLAN_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getPlanResultsByCodeGql';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import GET_COMPARATIVE_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getComparativeResultsByCodeGql';

export interface PlanResultsByType {
  description: string,
  code: string,
  startDate:Date,
  endDate: Date,
  grouping: string,
  timeframe: string,
  translations: string,
  updatedAt: string,
  results: { nodes: IResult[] },
}

export interface IResult {
  amount: number,
  category: string,
  currency: string,
  dgt: string,
  dt: string,
  od: string,
  fulfills: boolean,
  nif: string,
  ranking: number,
  rankingGroup: number,
  status: any,
  points: string,
  updateDate: string,
  updatedAt: string,
  wins: any,
  extraFields: any,
  territoryNameDgt: string,
  territoryNameDt: string,
  territoryNameOd: string,
  firstName: string,
  lastName: string,
  valort1: string,
  valort2: string,
  valort3: string,
  valort4: string,
  valorAnual: string,
  dataDate: string,
  puestoId: string,
}

export default function CampaignGPComparative() {
  const { id } = useParams();
  const { year } = useYear();
  const graphQLClient = createGraphQLClient();
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());

  const {
    data: dataPlan, error: errorPlan, isLoading: isLoadingPlan,
  } = useQuery(
    ['CampaignGPComparative', { code: id, numberElements: 10, numberPage: 1 }],
    async () => (graphQLClient && graphQLClient.request(
      GET_PLAN_RESULTS_BY_CODE_GQL,
      { code: id, numberElements: 10, numberPage: 1 },
    )),
  );
  const grouping = dataPlan?.planByCode?.grouping?.name;

  const variables:any = {
    code: id,
    category: globalFilter.category || undefined,
    dgt: globalFilter.dgt || undefined,
    dt: globalFilter.dt || undefined,
    od: globalFilter.od || undefined,
    firstName: globalFilter.firstName || undefined,
    lastName: globalFilter.lastName || undefined,
    year,
    status: globalFilter.status || '',
  };
  Object.keys(variables).forEach(
    (key:any) => (variables[key] === undefined || variables[key] === '') && delete variables[key],
  );

  const {
    data, error, isLoading, refetch,
  } = useQuery(
    ['CampaignComparative', variables],
    async () => (graphQLClient && graphQLClient.request(
      GET_COMPARATIVE_RESULTS_BY_CODE_GQL,
      variables,
    )),
  );

  const variablesFilters = {
    grouping,
    type: 'Campaign',
    code: id,
    year,
  };

  const {
    data: dataFilters,
    error: errorData,
    isLoading: isLoadingData,
  } = useQuery(
    ['CampaignFilters', variablesFilters],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      variablesFilters,
    )),
  );

  useEffect(() => {
    refetch();
  }, [globalFilter]);

  if (isLoading || isLoadingPlan || isLoadingData) {
    return (
      <Container>
        <Loading
          background="transparent"
          loadingColor="#ccc"
          message="Espere un momento por favor"
        />
      </Container>
    );
  }

  if (error || errorPlan || errorData) {
    return <GenericError />;
  }

  const translations = data?.comparativeResultsByCode?.translations
    ? JSON.parse(data?.comparativeResultsByCode?.translations) : {};

  let myDataWithExtras:any = {};
  const dataMyResultsExtraFields:any = {};
  const myDataHash:any = data?.comparativeResultsByCode?.myOwnResult;
  const myData:any = {
    dgt: myDataHash?.territoryNameDgt ? `${myDataHash?.dgt} - ${myDataHash?.territoryNameDgt}` : myDataHash?.dgt,
    dt: myDataHash?.territoryNameDt ? `${myDataHash?.dt} - ${myDataHash?.territoryNameDt}` : myDataHash?.dt,
    od: myDataHash?.territoryNameOd ? `${myDataHash?.od} - ${myDataHash?.territoryNameOd}` : myDataHash?.od,
    denomination: `${myDataHash?.firstName} ${myDataHash?.lastName}`,
    nif: myDataHash?.nif,
    extraFields: myDataHash?.extraFields,
    dataDate: myDataHash?.dataDate,
  };

  if (myData && myData?.extraFields) {
    Object.keys(myData?.extraFields).filter((c: any) => !['DGT', 'DT', 'OD'].includes(c))?.map((k:string) => {
      if (k === 'FECHA ACT') {
        if (myData?.dataDate) {
          dataMyResultsExtraFields['FECHA DATO'] = useFormatDate(myData?.dataDate);
        } else {
          dataMyResultsExtraFields['FECHA DATO'] = useFormatDate(myData?.extraFields[k]);
        }
      } else {
        dataMyResultsExtraFields[k.split('.').join('')] = formatValue(myData?.extraFields[k], k, translations);
      }
      return dataMyResultsExtraFields;
    });
    myDataWithExtras = { ...myData, ...dataMyResultsExtraFields };
  }

  const myOrderedExtraFields:any = [];
  let myTotalCols:any = [];
  dataPlan?.planByCode?.planLevels?.map((pl:any) => {
    if (Object.keys(dataMyResultsExtraFields).includes(pl.field.split('.').join(''))) {
      myOrderedExtraFields.push({ Header: formatColumn(pl.field.split('.').join(''), translations), accessor: pl.field.replace(/^[ \t]+|[ \t]+$/gm, '').split('.').join('') });
    }
    return myOrderedExtraFields;
  });
  delete myData.extraFields;

  Object.keys(myData).map((col:any) => {
    if (col !== 'dataDate') {
      myTotalCols.push({ Header: useMappingColumnNames(col, 'campaign'), accessor: col.replace(/^[ \t]+|[ \t]+$/gm, '') });
    }
    return myTotalCols;
  });
  myTotalCols = [myTotalCols, myOrderedExtraFields].flat();

  const dataResults = data?.comparativeResultsByCode?.otherResults?.nodes?.map((r:IResult) => ({
    dgt: r.territoryNameDgt ? `${r.dgt} - ${r?.territoryNameDgt}` : r.dgt,
    dt: r.territoryNameDt ? `${r.dt} - ${r.territoryNameDt}` : r.dt,
    od: r.territoryNameOd ? `${r.od} - ${r.territoryNameOd}` : r.od,
    denomination: `${r.firstName} ${r.lastName}`,
    nif: r?.nif,
    extraFields: r?.extraFields,
    dataDate: r?.dataDate,
    puestoId: r?.puestoId,
  }));

  let dataResultsExtraFields:any = {};
  const dataWithExtras:any = [];
  dataResults?.map((res:any) => {
    dataResultsExtraFields = {};
    Object.keys(res?.extraFields).filter((c: any) => !['DGT', 'DT', 'OD'].includes(c)).map((k:string) => {
      if (k === 'FECHA ACT') {
        if (res?.dataDate) {
          dataResultsExtraFields['FECHA DATO'] = useFormatDate(res?.dataDate);
        } else {
          dataResultsExtraFields['FECHA DATO'] = useFormatDate(res?.extraFields[k]);
        }
      } else {
        dataResultsExtraFields[k.split('.').join('')] = formatValue(res?.extraFields[k], k, translations);
      }
      return dataResultsExtraFields;
    });
    const rowWithExtras = { ...res, ...dataResultsExtraFields };
    dataWithExtras.push(rowWithExtras);

    return res;
  });

  const orderedExtraFields:any = [];
  let totalCols:any = [];

  dataPlan?.planByCode?.planLevels?.map((pl:any) => {
    if (Object.keys(dataResultsExtraFields).includes(pl.field.split('.').join(''))) {
      orderedExtraFields.push({ Header: formatColumn(pl.field.split('.').join(''), translations), accessor: pl.field.replace(/^[ \t]+|[ \t]+$/gm, '').split('.').join('') });
    }
    return orderedExtraFields;
  });

  if (dataResults.length > 0) {
    delete dataResults[0].extraFields;

    Object.keys(dataResults[0]).map((col:any) => {
      if (col !== 'dataDate') {
        totalCols.push({ Header: useMappingColumnNames(col, 'campaign'), accessor: col.replace(/^[ \t]+|[ \t]+$/gm, '') });
      }
      return totalCols;
    });
    totalCols = [totalCols, orderedExtraFields].flat();
  }

  const uniqCols = myTotalCols.reduce((acc: any, current: any) => {
    const x = acc.find((item: any) => item.accessor === current.accessor);

    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);

  let totalColsCsv: any = totalCols;
  let myTotalColsCsv: any = myTotalCols;

  const uniqueColumns = Array.from(new Map(uniqCols.map((item: any) => (
    [item.Header, item]))).values());

  let finalCols = uniqueColumns;

  if (id === 'CMP-N-T-1T240001') {
    /*  MAPFRE ha pedido que esta campaña en esta vista tenga las columnas en un orden determinado.
        Están avisados de que no es algo que debamos hacer, pero confirman que lo quieren así.
        Si añaden, eliminan o cambian columnas, esto se verá afectado */
    finalCols = [{ Header: 'DGT', accessor: 'dgt' },
      { Header: 'DT', accessor: 'dt' },
      { Header: 'OD', accessor: 'od' },
      { Header: 'Denominación', accessor: 'denomination' },
      { Header: 'NIF', accessor: 'nif' },
      { Header: 'NP Hogar 2024 ', accessor: 'NP_HOGAR' },
      { Header: 'NP Hogar 2023 ', accessor: 'NP_HOGAR_AA' },
      { Header: '% Cump. Hogar ', accessor: '% Cumplimiento Hogar' },
      { Header: 'Puntos Pond Hogar ', accessor: 'Puntos Pond Hogar' },
      { Header: 'NP Ahorro Jub 2024 ', accessor: 'NP_AH_JUB' },
      { Header: 'NP Ahorro Jub 2023 ', accessor: 'NP_AH_JUB_AA' },
      { Header: 'Polizas Riesgo Col ', accessor: 'NP_POL_RIESGO_COL' },
      { Header: 'Obj Polizas Riesgo Col ', accessor: 'OBJETIVO POL RIESGO COL' },
      { Header: '% Cump. Ah Jub ', accessor: '% Cumplimiento Ah Jub' },
      { Header: 'Puntos Pond Ah Jub ', accessor: 'Puntos Pond Ah Jub' },
      { Header: 'NP Producto DGT 2024 ', accessor: 'NP_PRODUCTO_DGT' },
      { Header: 'NP Producto DGT 2023 ', accessor: 'NP_PRODUCTO_DGT_AA' },
      { Header: '% Cump. Producto DGT ', accessor: '% Cumplimiento Producto DGT' },
      { Header: 'Puntos Pond Prod DGT ', accessor: 'Puntos Pond Prod DGT' },
      { Header: 'NP Autos 2024 ', accessor: 'NP_AUTOS' },
      { Header: 'NP Autos 2023 ', accessor: 'NP_AUTOS_AA' },
      { Header: '% Cump. Autos Ind ', accessor: '% Cumplimiento Autos Ind' },
      { Header: 'Total Nuevos Clientes Q1 24', accessor: 'Total Nuevos Clientes' },
      { Header: 'Nuevos Clientes Account Q1 24', accessor: 'Nuevos Clientes Account' },
      { Header: '% Clientes Account ', accessor: '% Clientes Account' },
      { Header: 'Puntos Extra Nuevos Clientes Account ', accessor: 'Puntos Extra Nuevos Clientes Account' },
      { Header: 'Clientes Cartera Multipoliza 2024', accessor: 'Clientes Cartera Multipoliza 2024' },
      { Header: 'Clientes Cartera Multipoliza 2023', accessor: 'Clientes Cartera Multipoliza 2023' },
      { Header: '% Clientes Cartera Multipolizas ', accessor: '% Clientes Cartera Multipolizas' },
      { Header: 'Puntos Extra Clientes Multipolizas ', accessor: 'Puntos Extra Clientes Multipolizas' },
      { Header: 'Extrapuntos Clientes ', accessor: 'Extrapuntos Clientes' },
      { Header: 'Puntuación Total ', accessor: 'TOTAL PUNTOS' },
      { Header: 'Objetivos Cumplidos', accessor: 'Objetivos Cumplidos' },
      { Header: 'Posición Ranking', accessor: 'Posicion Ranking' },
      { Header: 'Gana', accessor: 'GANA' },
      { Header: 'Datos a día', accessor: 'FECHA DATO' },
    ];
    totalColsCsv = finalCols;
    myTotalColsCsv = finalCols;
  }

  if (id === 'CMP-N-T-2T240001') {
    /*  MAPFRE ha vuelto a pedir que esta campaña en esta vista tenga
        las columnas en un orden determinado.
        Están avisados de que no es algo que debamos hacer, pero confirman que lo quieren así.
        Si añaden, eliminan o cambian columnas, esto se verá afectado */
    finalCols = [{ Header: 'DGT', accessor: 'dgt' },
      { Header: 'DT', accessor: 'dt' },
      { Header: 'OD', accessor: 'od' },
      { Header: 'Denominación', accessor: 'denomination' },
      { Header: 'NIF', accessor: 'nif' },
      { Header: 'NP Multirriesgo', accessor: 'NP_MULTIRRIESGO' },
      { Header: 'Ppto. Multirriesgo', accessor: 'PPTO_MULTIRRIESGO' },
      { Header: '% Cump. Multirriesgo', accessor: '%_CUMPLIMIENTO_MULTIRRIESGO' },
      { Header: 'NP Riesgo y Acc Ind', accessor: 'NP_RIESGO_ACC_IND' },
      { Header: 'Ppto. Riesgo y Acc Ind', accessor: 'PPTO_RIESGO_ACC_IND' },
      { Header: '% Cump. Riesgo y Acc. Ind', accessor: '%_CUMPLIMIENTO_RIESGO_ACC_IND' },
      { Header: 'NP Producto DGT', accessor: 'NP_PRODUCTO_DGT' },
      { Header: 'Ppto. NP Producto DGT', accessor: 'PPTO_NP_PRODUCTO_DGT' },
      { Header: '% Cump. Producto DGT', accessor: '% Cumplimiento Producto DGT' },
      { Header: 'Total Nuevos Clientes', accessor: 'Total Nuevos Clientes' },
      { Header: 'Nuevos Clientes Account', accessor: 'Nuevos Clientes Account' },
      { Header: '% Clientes Account', accessor: '% Clientes Account' },
      { Header: 'Puntos Extra Nuevos Clientes Account', accessor: 'Puntos Extra Nuevos Clientes Account' },
      { Header: 'Clientes Cartera Multipoliza Q2 24', accessor: 'Clientes Cartera Multipoliza 2024' },
      { Header: 'Clientes Cartera Multipoliza Q1 24', accessor: 'Clientes Cartera Multipoliza 2023' },
      { Header: '% Clientes Cartera Multipolizas', accessor: '% Clientes Cartera Multipolizas' },
      { Header: 'Puntos Extra Clientes Multipolizas', accessor: 'Puntos Extra Clientes Multipolizas' },
      { Header: 'Extrapuntos Clientes', accessor: 'Extrapuntos Clientes' },
      { Header: 'Puntuación Total', accessor: 'TOTAL PUNTOS' },
      { Header: 'Objetivos Cumplidos', accessor: 'Objetivos Cumplidos' },
      { Header: 'Posición Ranking', accessor: 'Posicion Ranking' },
      { Header: 'Gana', accessor: 'GANA' },
      { Header: 'Datos a día', accessor: 'FECHA DATO' },
    ];
    totalColsCsv = finalCols;
    myTotalColsCsv = finalCols;
  }

  if (id === 'CMP-N-T-3T240001') {
    /*  MAPFRE pide por tercera vez el mismo cambio.
        Leer comentarios anteriores. */
    finalCols = [{ Header: 'DGT', accessor: 'dgt' },
      { Header: 'DT', accessor: 'dt' },
      { Header: 'OD', accessor: 'od' },
      // { Header: 'Denominación', accessor: 'denomination' },
      { Header: 'NIF', accessor: 'nif' },
      { Header: 'NP Multirriesgo + RC', accessor: 'NP_MULTIRRIESGO_RC' },
      { Header: 'Ppto. Multirriesgo + RC', accessor: 'PPTO_NP_MULTIRRIESGO_RC' },
      { Header: '% Cump. Multirriesgo + RC', accessor: '%_CUMPLIMIENTO_MULTIRRIESGO_RC' },
      { Header: 'NP Riesgo Acc y Salud ITT', accessor: 'NP Total Riesgo y Salud' },
      { Header: 'NP Riesgo y Acc', accessor: 'NP_RIESGO_ACC' },
      { Header: 'NP Salud ITT', accessor: 'NP_SALUD_ITT' },
      { Header: 'NP Pólizas Riesgo Col', accessor: 'NP_POL_RIESGO_COL' },
      { Header: 'Ppto. Riesgo y Acc + Salud ITT', accessor: 'PPTO_RIESGO_ACC_TOTAL' },
      { Header: '% Cump. Riesgo y Acc. + Salud ITT', accessor: '%_CUMPLIMIENTO_RIESGO_ACC' },
      { Header: 'NP Producto DGT', accessor: 'NP_PRODUCTO_DGT' },
      { Header: 'Ppto. NP Producto DGT', accessor: 'PPTO_NP_PRODUCTO_DGT' },
      { Header: '% Cump. Producto DGT', accessor: '% Cumplimiento Producto DGT' },
      { Header: 'Total Nuevos Clientes', accessor: 'Total Nuevos Clientes' },
      { Header: 'Nuevos Clientes Account', accessor: 'Nuevos Clientes Account' },
      { Header: '% Clientes Account', accessor: '% Clientes Account' },
      { Header: 'Puntos Extra Nuevos Clientes Account', accessor: 'Puntos Extra Nuevos Clientes Account' },
      { Header: 'Puntuación Total', accessor: 'TOTAL PUNTOS' },
      { Header: 'Objetivos Cumplidos', accessor: 'Objetivos Cumplidos' },
      { Header: 'Posición Ranking', accessor: 'Posicion Ranking' },
      { Header: 'Gana', accessor: 'GANA' },
      { Header: 'Datos a día', accessor: 'FECHA DATO' },
    ];
    totalColsCsv = finalCols;
    myTotalColsCsv = finalCols;
  }

  if (id === 'CMP-N-T-4T240001') {
    /*  MAPFRE pide por cuarta vez el mismo cambio.
        Leer comentarios anteriores. */
    finalCols = [{ Header: 'DGT', accessor: 'dgt' },
      { Header: 'DT', accessor: 'dt' },
      { Header: 'OD', accessor: 'od' },
      { Header: 'NIF', accessor: 'NIF' },
      { Header: 'NP Salud Total', accessor: 'NP Salud Total' },
      { Header: 'PPTO Salud Total', accessor: 'PPTO Salud Total' },
      { Header: '% Cumplimiento Salud Total', accessor: '% Cumplimiento Salud Total' },
      { Header: 'NP FP + AJ Total', accessor: 'NP FP + AJ Total' },
      { Header: 'PPTO FP + AJ Total', accessor: 'PPTO FP + AJ Total' },
      { Header: '% Cumplimiento FP + AJ Total', accessor: '% Cumplimiento FP + AJ Total' },
      { Header: 'NP Producto DGT', accessor: 'NP Producto DGT' },
      { Header: 'Ppto. NP Producto DGT', accessor: 'PPTO NP Producto DGT' },
      { Header: '% Cump. Producto DGT', accessor: '% Cumplimiento Producto DGT' },
      { Header: 'Ofertas Salud Individual', accessor: 'Ofertas Salud Individual' },
      { Header: 'Obj Ofertas Salud Ind', accessor: 'Obj Ofertas Salud Ind' },
      { Header: 'Ofertas Riesgo Ind', accessor: 'Ofertas Riesgo Ind' },
      { Header: 'Obj Ofertas Riesgo Ind', accessor: 'Obj Ofertas Riesgo Ind' },
      { Header: 'Ofertas Riesgo Col', accessor: 'Ofertas Riesgo Col' },
      { Header: 'Obj Ofertas Riesgo Col', accessor: 'Obj Ofertas Riesgo Col' },
      { Header: 'Ofertas Riesgo Total', accessor: 'Ofertas Riesgo Total' },
      { Header: 'Obj Riesgo Total', accessor: 'Obj Riesgo Total' },
      { Header: 'Puntos Ofertas Salud', accessor: 'Puntos Ofertas Salud' },
      { Header: 'Puntos Ofertas Riesgo', accessor: 'Puntos Ofertas Riesgo' },
      { Header: 'Puntos Extra Ofertas', accessor: 'Extrapuntos Total' },
      { Header: 'Puntuación Total', accessor: 'TOTAL PUNTOS' },
      { Header: 'Objetivos Cumplidos', accessor: 'Objetivos Cumplidos' },
      { Header: 'Posición Ranking', accessor: 'Posicion Ranking' },
      { Header: 'Gana', accessor: 'GANA' },
      { Header: 'Datos a día', accessor: 'FECHA DATO' },
    ];
    totalColsCsv = finalCols;
    myTotalColsCsv = finalCols;
  }

  if (id === 'CMP-N-T-1T250001') {
    /*  MAPFRE ha vuelto a pedir que esta campaña en esta vista tenga
        las columnas en un orden determinado.
        Están avisados de que no es algo que debamos hacer, pero confirman que lo quieren así.
        Si añaden, eliminan o cambian columnas, esto se verá afectado */
    finalCols = [{ Header: 'DGT', accessor: 'dgt' },
      { Header: 'DT', accessor: 'dt' },
      { Header: 'OD', accessor: 'od' },
      { Header: 'NIF', accessor: 'NIF' },
      { Header: 'NP Multirriesgo + RC 2025', accessor: 'NP MULTIRRIESGO RC' },
      { Header: 'NP Multirriesgo + RC 2024', accessor: 'NP MULTIRRIESGO RC AA' },
      { Header: '% Cumplimiento Multi+RC', accessor: '%CUMPLIMIENTO_MULTIRRIESGO_RC' },
      { Header: 'NP Ahorro Jubilación 2025', accessor: 'NP_AH_JUB' },
      { Header: 'NP Ahorro Jubilación 2024', accessor: 'NP_AH_JUB_AA' },
      { Header: '% Cumplimiento Ah Jub', accessor: '%CUMPLIMIENTO_AHORRO_JUB' },
      { Header: 'NP Producto DGT 2025', accessor: 'NP Producto DGT' },
      { Header: 'NP Producto DGT 2024', accessor: 'NP_PRODUCTO_DGT_AA' },
      { Header: '% Cump. Producto DGT', accessor: '% CUMPLIMIENTO_PROD_DGT' },
      { Header: 'Pólizas Riesgo Col', accessor: 'NP POL RIESGO COL' },
      { Header: 'Obj Pol Riesgo Col', accessor: 'OBJETIVO POL RIESGO COL' },
      { Header: 'NP Riesgo Colectivo', accessor: 'NP_RIESGO COL' },
      { Header: 'Objetivo NP Riesgo Col', accessor: 'Objetivo NP Riesgo Col' },
      { Header: 'Ofertas Riesgo Ind', accessor: 'Ofertas Riesgo Ind' },
      { Header: 'Obj Ofertas Riesgo Ind', accessor: 'Obj Ofertas Riesgo Ind' },
      { Header: '% Ofertas Riesgo Ind', accessor: '% Ofertas Riesgo Ind' },
      { Header: 'Pólizas Riesgo Ind', accessor: 'POLIZAS RIESGO INDIVIDUAL' },
      { Header: '% Conversión', accessor: '% Conversion' },
      { Header: 'Puntos Extra Ofertas', accessor: 'Extrapuntos Total' },
      { Header: 'Puntuación Total', accessor: 'TOTAL PUNTOS' },
      { Header: 'Objetivos Cumplidos', accessor: 'Objetivos Cumplidos' },
      { Header: 'Posición Ranking', accessor: 'Posicion Ranking' },
      { Header: 'Gana', accessor: 'GANA' },
      { Header: 'Datos a día', accessor: 'FECHA DATO' },
    ];
    totalColsCsv = finalCols;
    myTotalColsCsv = finalCols;
  }

  const uniqueColumnsComparative = finalCols;

  const { columns: myTotalColumCsv } = useParseToCsvColumns(myTotalColsCsv);
  const { columns: totalColumCsv } = useParseToCsvColumns(totalColsCsv);

  // Filtering csv cols
  const uniqueColumnsCsvComparative = useFilterCsvColumns(
    totalColumCsv,
    dataWithExtras,
  );
  // Fin filtering csv cols
  const processMyDataWithExtras = useProcessData([myDataWithExtras]);
  const processMyDataWithExtrasCSV = useProcessDataCSV([myDataWithExtras]);
  const processDataWithExtras = useProcessData(dataWithExtras);
  const processDataWithExtrasCSV = useProcessDataCSV(dataWithExtras);

  return (
    <Container>
      <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>
      <Typography
        align="center"
        color={Theme.colorCampaign}
        display="block"
        size="28px"
        margin="2rem auto"
        weight="500"
      >
        {dataPlan?.planByCode?.description}
      </Typography>
      {(myTotalCols && myDataWithExtras) ? (
        <>
          <FilterForm
            dataFilters={dataFilters}
            filter={globalFilter}
            setFilter={setGlobalFilter}
            type="Campaign"
            grouping={grouping}
          />
          <Typography
            align="center"
            color={Theme.colorCampaign}
            display="block"
            size="20px"
            margin="2rem auto"
            weight="500"
          >
            {`Datos de ${Object.keys(myDataWithExtras).length > 0 ? myDataWithExtras?.denomination : ''}`}
          </Typography>
          {myTotalColumCsv.length > 0 && [myDataWithExtras].length > 0 && (
            <CardDataExportXLSX
              headers={myTotalColumCsv}
              data={processMyDataWithExtrasCSV}
              name="campaign_global_position_current_user"
            />
          )}
          <Table
            columns={finalCols}
            data={processMyDataWithExtras}
            theme={Theme.colorCampaign}
            className="table-campaign-GPComparative-1"
          />
        </>
      ) : 'Sin datos propios en esta campaña'}
      <br />
      {(totalCols?.length > 0 && dataWithExtras?.length > 0) ? (
        <>
          <Typography
            align="center"
            color={Theme.colorCampaign}
            display="block"
            size="20px"
            margin="2rem auto"
            weight="500"
          >
            {`Datos campaña ${dataPlan?.planByCode?.description}`}
          </Typography>
          <CardDataExportXLSX
            headers={uniqueColumnsCsvComparative}
            data={processDataWithExtrasCSV}
            name="campaign_global_position_comparative"
          />
          <Table
            columns={uniqueColumnsComparative}
            data={processDataWithExtras}
            theme={Theme.colorCampaign}
            className="table-campaign-GPComparative-2"
          />
        </>
      ) : ''}
    </Container>
  );
}
