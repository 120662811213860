import { useState } from 'react';
import RappelDelegatesDinamization from './RappelDelegatesDinamization';
import DelegatesChallengesCampaignCard from './DelegatesChallengesCampaignCard';
import Theme from '../../../styles/Theme';
import Container from '../../atoms/Container/Container';
import {
  DinamizationContainer,
  DinamizationHeaderContainer,
  DinamizationHeaderButtonsContainer,
  DinamizationContent,
  ContainerItems,
  DinamizationText,
  DelegateInfoData,
} from '../../organisms/Dinamizations/StyledDinamizations';
import DinamizationBigButton from '../../organisms/Dinamizations/DinamizationBigButton';
import LastDinamizationUpdate from '../../organisms/Dinamizations/LastDinamizationUpdate';

export default function DelegatesDinamizationsQuarterly(props:any) {
  const { data } = props;

  const buttonsDelegates = [
    {
      title: data?.userInfo?.campaign_name,
      subtitle: 'CAMPAÑA NACIONAL',
      color: Theme.colorPrimary,
    },
    {
      title: 'RAPPELES',
      subtitle: undefined,
      color: Theme.colorRappelDinamizations,
    },
  ];

  const [selectedButton, setSelectedButton] = useState<string>(buttonsDelegates[0]?.title);

  return (
    <Container>
      <DinamizationContainer>
        <DinamizationHeaderContainer>
          <DinamizationHeaderButtonsContainer>
            {buttonsDelegates.map((button:any) => (
              <DinamizationBigButton
                title={button.title}
                subtitle={button.subtitle}
                selected={selectedButton === button.title}
                onClick={() => setSelectedButton(button.title)}
                color={button.color}
                type="button"
                key={button.title}
              />
            ))}
          </DinamizationHeaderButtonsContainer>
          <ContainerItems
            width="fit-content"
            display="grid"
            gap="8px"
            margin="0"
            justifyItems="end"
          >
            <LastDinamizationUpdate date={data?.challenges?.products?.[0].dataDate} />
            {selectedButton === buttonsDelegates[1].title && data?.rappels?.info?.total_amount && (
              <DelegateInfoData>
                <ContainerItems
                  width="fit-content"
                  justifyContent="center"
                  display="inline-flex"
                  gap="12px"
                  margin="auto"
                  alignItems="center"
                  textAlign="center"
                >
                  <DinamizationText
                    fontSize="14px"
                    fontWeight="400"
                    color={Theme.fontColorSecondary}
                    margin="0"
                  >
                    Total provisional
                  </DinamizationText>
                  <DinamizationText
                    fontSize="18px"
                    fontWeight="700"
                    color={Theme.colorRappelDinamizations}
                    margin="0"
                  >
                    {data?.rappels?.info?.total_amount}
                  </DinamizationText>
                </ContainerItems>
              </DelegateInfoData>
            )}
          </ContainerItems>
        </DinamizationHeaderContainer>
        <DinamizationContent>
          {/* start CAMPAÑA NACIONAL TAB */}
          {selectedButton === buttonsDelegates[0].title
            && (
              <DelegatesChallengesCampaignCard
                info={data?.challenges?.info}
                products={data?.challenges?.products}
              />
            )}
          {/* end CAMPAÑA NACIONAL TAB */}

          {/* start RAPPELES TAB */}
          {selectedButton === buttonsDelegates[1].title
            && (
              <RappelDelegatesDinamization data={data?.rappels} />
            )}
          {/* end RAPPELES TAB */}
        </DinamizationContent>
      </DinamizationContainer>
    </Container>
  );
}
