import Styled, { css } from 'styled-components';
import Theme from '../../../styles/Theme';

/*  start WIDGET POSITION  */
export const PositionWidgetElement = Styled.div`
  background: ${Theme.colorRanking};
  border-radius: 40px 0 0 40px;
  position: fixed;
  overflow: hidden;
  top: 14rem;
  right: 0;
  z-index: 2;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08), 0 0 10px 0 rgba(0, 0, 0, 0.08);
`;

export const StyledPositionWidget = Styled.div`
  position: relative;
  height: 90px;
  width: 140px;
  display: grid;
  align-items: center;
  justify-content: center;
  right: 0;
`;

export const PositionWidgetContainer = Styled.div`
  width: 100%;
  position: relative;
  margin: 0.25rem auto 0 auto;
  text-align: center;
  display: grid;
`;

export const PositionWidgetCurrentValueContainer = Styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  text-align: center;
`;

export const PositionWidgetCurrentValue = Styled.span`
  color: ${Theme.colorRanking};
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
`;

export const PositionWidgetCurrentValueTitle = Styled.span`
  color: ${Theme.colorWhite};
  font-size: 11px;
  font-weight: bold;
  white-space: nowrap;
`;

export const PositionWidgetOldContainer = Styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  color: ${Theme.colorRanking};
  background-color: ${Theme.colorWhite};
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
`;
export const PositionWidgetOldValue = Styled.span`
  margin-left: 4px;
  font-size: 16px;
`;
/*  end WIDGET POSITION  */

/* start DINAMIZATION BIG CONTAINER */
export const DinamizationContainer = Styled.div`
  display: block;
  border-radius: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1), 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

export const DinamizationHeaderContainer = Styled.div`
  width: 100%;
  background-color: ${Theme.colorSecondary2};
  border-radius: 30px;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  position: relative;
  align-items: center;
  padding-right: 30px;
`;

export const DinamizationHeaderButtonsContainer = Styled.div`
  width: fit-content;
  display: flex;
  background-color: ${Theme.colorTertiary};
  border-radius: 30px;
`;

export const DinamizationContent = Styled.div`
  width: 100%;
  padding: 60px 40px 40px 40px;
  margin-top: -20px;
  border-radius: 0 0 30px 30px;
  background-color: ${Theme.colorWhite};
`;

export const DelegateInfoData = Styled.div`
  background-color: ${Theme.colorWhite};
  border-radius: 15px;
  padding: 5px 15px;
  width: fit-content;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08), 0 0 10px 0 rgba(0, 0, 0, 0.08);
`;
/* end DINAMIZATION BIG CONTAINER */

/* start POSITION CARD */
export const PositionCardContainer = Styled.div`
  display: inline-flex;
  height: 90px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08), 0 0 10px 0 rgba(0, 0, 0, 0.08);
  margin: 0;
`;

export const PositionCardTitle = Styled.div`
  background-color: ${Theme.colorPrimary};
  color: ${Theme.colorWhite};
  font-weight: 900;
  font-size: 19px;
  padding: 20px;
  border-radius: 20px 0 0 20px;
  text-wrap: nowrap;
  align-content: center;
`;

export const PositionCardValueContainer = Styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  text-align: center;
  background-color: ${Theme.colorWhite};
  border-radius: 0 20px 20px 0;
  align-content: center;
  padding: 20px;
`;

export const PositionCardValue = Styled.div`
  color: ${Theme.colorWhite};
  position: absolute;
  top: 39px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  font-weight: bold;
`;
/* end POSITION CARD */

/*  start LAST UPDATE DATE  */
export const LastUpdateContainer = Styled.div`
  width: fit-content;
  align-items: center;
`;

export const LastUpdateTitle = Styled.span`
  color: ${Theme.fontColorSecondary};
  font-weight: 900;
  font-size: 14px;
  margin-right: 10px;
`;

export const LastUpdateDate = Styled.span`
  color: ${Theme.colorPrimary};
  font-weight: 900;
  font-size: 14px;
  background-color: ${Theme.colorSecondary2};
  padding: 6px 0 6px 12px;
`;
/*  end LAST UPDATE DATE  */

/*  start RAPPELS  */
// start RAPPEL HEADER
export const RappelHeaderContainer = Styled.div`
  background-color: ${Theme.colorSecondary2};
  border-radius: 30px;
  padding: 30px 40px;
  margin-top: 50px;
  margin-bottom: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const RappelHeaderTitle = Styled.span`
  font-family: 'Fixture Condensed Medium', sans-serif;
  color: ${Theme.colorRappelDinamizations};
  font-size: 46px;
  font-weight: 400;
`;

export const RappelHeaderCardContainer = Styled.div`
  background-color: ${Theme.colorWhite};
  border-radius: 15px;
  padding: 10px 15px;
  align-content: center;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.06), -10px 0 10px rgba(0, 0, 0, 0.06);
`;

export const RappelHeaderCardTitle = Styled.span`
  font-size: 16px;
  color: ${Theme.fontColorSecondary};
  font-weight: 400;
`;

export const RappelHeaderCardAmount = Styled.span`
  font-size: 20px;
  color: ${Theme.colorRappelDinamizations};
  font-weight: 700;
  margin-left: 12px;
`;
// end RAPPEL HEADER

// start SEGMENT RAPPEL
export const GeneralRappelStyled = Styled.div`
  overflow-x: auto;

  &::-webkit-scrollbar {
    background-color: #efefef;
    width: 7px;
    height: 0.5rem;
  }
  
  &::-webkit-scrollbar-thumb {
    height: 0.5rem;
    border: 0 none;
    background-clip: padding-box;
    -webkit-border-radius: 0.7rem;
    border-radius: 0.7rem;
    background-color: ${Theme.fontColorSecondary};
    -webkit-box-shadow: inset -0.1rem -0.1rem 0 rgba(0, 0, 0, 0.05), inset 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.05);
    box-shadow: inset -0.1rem -0.1rem 0 rgba(0, 0, 0, 0.05), inset 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.05);
  }
  
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const RappelInfoStyled = Styled.div`
  background-color: ${Theme.colorSecondary2};
  padding: 40px;
  border-radius: 30px;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.06), -10px 0 10px rgba(0, 0, 0, 0.06);
  margin: 40px 0;
  min-width: fit-content;
`;

export const RappelDescription = Styled.div<{fontWeight?: string}>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '700')};
  font-size: 19px;
  color: ${Theme.colorRappelDinamizations};
  margin-bottom: 20px;
  width: fit-content;
`;

export const RappelDescriptionData = Styled.div`
  font-weight: 700;
  font-size: 22px;
  color: ${Theme.colorRappelDinamizations};
  margin-bottom: 20px;
  background-color: ${Theme.colorWhite};
  margin: 0 2px 20px 2px;
  padding: 8px 12px;
  border-radius: 30px;
  width: fit-content;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.06), -10px 0 10px rgba(0, 0, 0, 0.06);
`;

export const RappelInfoContainer = Styled.div`
  display: flex;
  gap: 3px;
  margin-bottom: 10px;
`;

export const RappelInfoSegment = Styled.div`
  flex: 1;
`;

export const RappelSegment = Styled.div<{cumple: boolean, isFirst: boolean, isLast: boolean}>`
  height: 40px;
  background-color: ${(props) => (props.cumple ? Theme.colorRappelDinamizations : Theme.colorSecondary3)};
  border-radius: ${(props) => (props.isFirst ? '21px 0 0 21px' : (props.isLast ? '0 21px 21px 0' : '0 0 0 0'))}
`;

export const RappelExtraDataContainer = Styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
// end SEGMENT RAPPEL

// start SEGMENTS RAPPEL CARDS
export const RappelSegmentCardStyled = Styled.div`
  height: 100%;
`;

export const SegmentCardStyled = Styled.div`
  border-radius: 15px;
  margin: 30px 3px 0 3px;
  background-color: ${Theme.colorBorder};
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1), -10px 0 10px rgba(0, 0, 0, 0.1);
  display: grid;
  gap: 1px;
`;

export const SegmentCardHeader = Styled.div<{cumple: boolean, showOnlyHeader: boolean}>`
  border-radius: ${(props) => (props.showOnlyHeader ? '15px' : '15px 15px 0 0')};
  padding: 18px 0;
  background-color: ${(props) => (props.cumple ? Theme.colorWhite : Theme.colorTertiary)};
  color: ${Theme.colorRappelDinamizations};
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

export const SegmentCardContainer = Styled.div<{cumple: boolean, center: boolean}>`
  border-radius: 0 0 15px 15px;
  padding: 15px;
  background-color: ${(props) => (props.cumple ? Theme.colorWhite : Theme.colorTertiary)};
  display: flex;
  flex-flow: row wrap;
  justify-content: ${(props) => (props.center ? 'center' : 'space-between')};
`;

export const DelegatesInfo = Styled.div<{center: boolean}>`
  color: ${Theme.colorRappelDinamizations};
  margin-bottom: 20px;
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
`;

export const IncentiveInfo = Styled.div<{center: boolean}>`
  color: ${Theme.colorBlack};
  width: fit-content;
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
`;
// end SEGMENTS RAPPEL CARDS

// start FIRST SEGMENT RAPPEL CARD
export const FirstSegmentCard = Styled.div`
  color: ${Theme.colorSecondary3};
  font-size: 22px;
  font-weight: 700;
  padding: 30px 10px 40px 10px;
  text-align: center;
  height: 100%;
`;

export const SegmentCardHeaderSubtitle = Styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${Theme.fontColorSecondary};
  width: fit-content;
`;

export const SegmentCardHeaderSubtitleValue = Styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${Theme.colorRappelDinamizations};
  margin-left: 5px;
  width: fit-content;
`;
// end FIRST SEGMENT RAPPEL CARD
/*  end RAPPELS  */

/*  start GENERIC CARDS  */
export const CardsContainer = Styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 30px;
  align-items: normal;
`;

// start HEADER CARD //
export const HeaderCardStyled = Styled.div<{width: string, height?: string, backgroundColor: string}>`
  width: ${(props) => props.width};
  border-radius: 20px;
  margin: 0;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1), -10px 0 10px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.backgroundColor};
  height: ${(props) => (props.height ? props.height : 'fit-content')};
  display: flex;
  flex-flow: column;
`;

export const HeaderTitleContainer = Styled.div<{color: string, center: boolean, miniCard: boolean}>`
  background-color: ${(props) => props.color};
  color: ${Theme.colorWhite};
  padding: ${(props) => (props.miniCard ? '8px 12px' : '20px')};
  border-radius: 20px 20px 0 0;
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
  display: flex;
`;

export const HeaderTitle = Styled.div<{fontSize?: string, center: boolean}>`
  font-weight: 700;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
  width: fit-content;
`;

export const HeaderSubtitle = Styled.div`
  font-weight: 400;
  font-size: 20px;
  margin-left: 10px;
  width: fit-content;
`;

export const HeaderBoxContainer = Styled.div<{miniCard: boolean}>`
  padding: ${(props) => (props.miniCard ? '6px 10px' : '12px 18px')};
  display: flex;
  height: fit-content;
  flex: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: #efefef;
    width: 7px;
    height: 0.5rem;
  }
  
  &::-webkit-scrollbar-thumb {
    height: 0.5rem;
    border: 0 none;
    background-clip: padding-box;
    -webkit-border-radius: 0.7rem;
    border-radius: 0.7rem;
    background-color: ${Theme.colorPrimary};
    -webkit-box-shadow: inset -0.1rem -0.1rem 0 rgba(0, 0, 0, 0.05), inset 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.05);
    box-shadow: inset -0.1rem -0.1rem 0 rgba(0, 0, 0, 0.05), inset 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.05);
  }
  
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
// end HEADER CARD //

// start CARD CAMPAIGN
export const CampaignCardsContainer = Styled.div`
  width: 100%;
  display: block;
`;

export const CampaignHeaderCardsContainer = Styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 10px;
  flex-flow: row wrap;
  gap: 20px;
`;

export const CampaignCards = Styled.div<{flex?:string}>`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  gap: 15px;
  flex-flow: row wrap;
  flex: ${(props) => (props.flex ? props.flex : 'unset')}
`;

export const CardCampaignContainer = Styled.div`
  display: block;
  flex: 1;
`;

export const CardCampaignHeaderStyled = Styled.div`
  background-color: ${Theme.colorPrimary};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 15px 20px;
  border-radius: 20px 20px 0 0;
  align-items: center;L
`;

export const CardCampaignContainerValues = Styled.div`
  display: flex;
  flex: 1;
`;

export const CardCampaignContainerStyled = Styled.div`
  width: 65%;
  min-width: 170px;
  border-radius: 0 0 10px 20px;
  padding: 16px 20px;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.07), -10px 0 10px rgba(0, 0, 0, 0.07);
  background-color: ${Theme.colorWhite};
  z-index: 2;
  position: relative;
`;

export const CardCampaignAmount = Styled.div`
  font-size: 22px;
  color: ${Theme.colorSecondary};
  margin-bottom: 20px;
`;

export const CardCampaignAmountDelegates = Styled.div`
  font-size: 16px;
  color: ${Theme.colorSecondary};
  margin-bottom: 20px;
`;

export const CardCampaignPercentage = Styled.div`
  width: calc(35% + 6px);
  min-width: 140px;
  border-radius: 0 0 20px 0;
  margin-left: -6px;
  padding: 30px 20px;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.07), -10px 0 10px rgba(0, 0, 0, 0.07);
  background-color: ${Theme.colorSecondary2};
  text-align: center;
`;
// end CARD CAMPAIGN

// start CARD POINTS
export const CardPointsStyled = Styled.div<{showShadow:boolean}>`
  display: flex;
  box-shadow: ${(props) => (props.showShadow ? '8px 0 8px rgba(0, 0, 0, 0.07), -6px 0 10px rgba(0, 0, 0, 0.07)' : 'none')};
  border-radius: 20px;
  max-height: 41px;
  width: fit-content;
`;

export const PointsTitle = Styled.div<{color: string, colorBackground: string, fontSize: string}>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.colorBackground};
  padding: 10px 10px 10px 14px;
  border-radius: 20px 0 0 20px;
  font-size: ${(props) => props.fontSize};
  font-weight: 500;
`;

export const PointsValue = Styled.div<{color: string, fontSize: string}>`
  color: ${Theme.colorSecondary2};
  background-color: ${(props) => props.color};
  padding: 10px 14px 10px 10px;
  border-radius: 0 20px 20px 0;
  font-size: ${(props) => props.fontSize};
  font-weight: 500;
`;
// end CARD POINTS

// start CARD POSITION DELEGATES
export const RoundedDelegateContainer = Styled.div<{color: string, height?: string, borderRadius?: string, margin?: string, showShadow?: boolean}>`
  color: ${Theme.colorWhite};
  background-color: ${(props) => props.color};
  padding: 5px 20px;
  margin: ${(props) => (props.margin ? props.margin : '0')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '30px')};
  width: fit-content;
  display: inline-flex;
  align-items: center;
  height: ${(props) => (props.height ? props.height : 'unset')};
  box-shadow: ${(props) => (props.showShadow ? '10px 0 10px rgba(0, 0, 0, 0.07), -10px 0 10px rgba(0, 0, 0, 0.07)' : 'unset')};
`;

export const ChangePositionCardContainer = Styled.div`
  display: grid;
  width: fit-content;
`;

export const PositionTextcontainer = Styled.div`
  display: inline-flex;
  align-items: baseline;
`;

export const CardCampaignCurrentPositionDelegateContainer = Styled.div`
  width: calc(35% + 6px);
  min-width: 140px;
  border-radius: 0 0 20px 0;
  margin-left: -6px;
  padding: 10px;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.07), -10px 0 10px rgba(0, 0, 0, 0.07);
  background-color: ${Theme.colorSecondary2};
  text-align: center;
  display: grid;
`;

export const CardCampaignCurrentPositionDelegate = Styled.span`
  color: ${Theme.colorWhite};
  background-color: ${Theme.colorPrimary};
  border-radius: 30px;
  font-weight: 700;
  font-size: 22px;
  padding: 10px 20px;
  height: fit-content;
  width: fit-content;
  margin: 0 auto;
`;
// end CARD POSITION DELEGATES

// start CHALLENGE CARD
export const ChallengeCardsContainer = Styled.div<{display?: string}>`
  display: ${(props) => (props.display ? props.display : 'grid')};
  width: 100%;
  padding: 10px;
  gap: 20px;
`;

export const ChallengeCardStyled = Styled.div`
  display: grid;
  box-shadow: 8px 0 8px rgba(0, 0, 0, 0.07), -6px 0 10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  gap: 1px;
  width: 100%;
`;

export const ChallengeCardHeaderContainer = Styled.div<{color: string}>`
  background-color: ${Theme.colorWhite};
  color: ${(props) => props.color};
  font-family: 'Fixture Condensed Medium', sans-serif;
  font-size: 25px;
  font-weight: 500;
  padding: 10px 20px;
  display: flex;
  gap: 10px;
  border-radius: 10px 10px 0 0;
  align-items: center;
`;

export const ChallengeCardContainer = Styled.div`
  background-color: ${Theme.colorWhite};
  padding: 10px 20px;
  border-radius: 0 0 10px 10px;
  display: flex;
  gap: 15px;
`;

export const ChallengeCardDataContainer = Styled.div`
  display: block;
  flex: 1;
`;

export const ChallengeCardDataDescription = Styled.div`
  font-weight: 700;
  font-size: 13px;
  color: ${Theme.colorSecondary};
  display: flex;
  margin: 16px 0;
  align-items: center;
  gap: 8px;
`;

export const GraphicContainer = Styled.div`
  display: grid;
`;

export const Divider = Styled.div<{color: string}>`
  height: 1px;
  background-color: ${(props) => props.color};
  width: 100%;
`;

export const VerticalDivider = Styled.div<{color: string, height: string}>`
  background-color: ${(props) => props.color};
  height: ${(props) => (props.height ? props.height : 'auto')};
  width: 1px;
`;
// end CHALLENGE CARD

// start ROUNDED PERCENTAGE GRAPHIC
export const RoundedPercentageGraphicStyled = Styled.div<{color?: string, percentage?: number, width?: number}>`
  --p: ${(props) => props.percentage};
  --b: 6px;
  --c: ${(props) => props.color};
  --w: ${(props) => (props.width ? `${props.width}px` : '100px')};

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
  }
  &::before {
    inset:0;
    background: radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat, conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
    mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
    background-size: 0 0, auto;
    z-index: 1;
  }
  &::after {
    --c: ${Theme.colorTertiary};
    --p: 100;
    inset: 0;
    background: radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat, conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
    mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
  }
`;
// end ROUNDED PERCENTAGE GRAPHIC

// start LINEAR PERCENTAGE GRAPHIC
export const LinearPercentageGraphicStyled = Styled.div<{color?: string, percentage?: number}>`
  --p: ${(props) => props.percentage};
  --b: 6px; // Grosor de la barra
  --c: ${(props) => props.color};
  --w: 100%;

  width: var(--w);
  height: var(--b);
  position: relative;
  display: inline-block;
  background-color: ${Theme.colorTertiary}; // Color de fondo de la barra

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(var(--p) * 1%);
    background-color: var(--c);
    border-radius: var(--b);
    transition: width 0.3s ease;
  }
`;
// end LINEAR PERCENTAGE GRAPHIC

// export const ValueInsideGraphic = Styled.span<{color?: string}>`
//   color: ${(props) => props.color};
//   font-family: 'Fixture Condensed Medium', sans-serif;
//   font-weight: 500;
//   font-size: 30px;
//   margin-top: -2px;
// `;

export const ValueInGraphic = Styled.div<{color?: string}>`
  color: ${(props) => props.color};
  font-family: 'Fixture Condensed Medium', sans-serif;
  font-weight: 500;
  font-size: 30px;

  span {
    margin-left: 6px;
    color: ${Theme.colorSecondary3};
    font-family: Arial, sans-serif;
    font-weight: 700;
    font-size: 12px;
  }
`;

export const ValueUnderGraphic = Styled.span`
  color: ${Theme.colorSecondary3};
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
`;
/*  end GENERIC CARDS  */

/* start GENERIC ITEMS */
export const DinamizationText = Styled.p<{fontFamily?: string, fontSize?: string, fontWeight?: string, color?: string, margin?: string, textShadow?: string}>`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : 'DM Sans, sans-serif')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  color: ${(props) => (props.color ? props.color : Theme.colorBlack)};
  margin: ${(props) => (props.margin ? props.margin : 'auto')};
  text-shadow: ${(props) => (props.textShadow ? props.textShadow : 'none')};
`;

export const ContainerItems = Styled.div<{width?: string, justifyContent?: string, display?: string, gap?: string, margin?: string, padding?:string, alignItems?: string, textAlign?: string, justifyItems?: string}>`
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'unset')};
  display: ${(props) => (props.display ? props.display : 'flex')};
  gap: ${(props) => (props.gap ? props.gap : '12px')};
  margin: ${(props) => (props.margin ? props.margin : 'unset')};
  padding: ${(props) => (props.padding ? props.padding : 'unset')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'unset')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'unset')};
  justify-items: ${(props) => (props.justifyItems ? props.justifyItems : 'unset')};
`;

// start BUTTONS
export const ButtonsContainer = Styled.div<{margin?: string}>`
  width: 100%;
  gap: 10px;
  display: flex;
  margin: ${(props) => (props.margin ? props.margin : 'unset')}
`;

export const Button = Styled.button<{selected: boolean, color: string}>`
  font-size: 16px;
  font-weight: 700;
  font-family: Arial, sans-serif;
  background-color: ${(props) => (props.selected ? props.color : Theme.colorTertiary)};
  color: ${(props) => (props.selected ? Theme.colorWhite : Theme.fontColorSecondary)};
  padding: 15px 30px;
  border-radius: 20px;
  border: 0;
  cursor: pointer;
`;
// end BUTTONS

// start BIG BUTTONS
export const BigButton = Styled.button<{selected: boolean, color: string}>`
  max-height: 160px;
  min-height: 110px;
  align-content: center;
  background-color: ${(props) => (props.selected ? props.color : Theme.colorTertiary)};
  padding: 0 40px;
  display: grid;
  align-items: center;
  border-radius: 30px;
  border: 0;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.selected ? props.color : Theme.colorWhite)};
  }
`;

export const BigButtonTitle = Styled.span<{selected: boolean, color: string, hasSubtitle?: boolean}>`
  font-size: ${(props) => (props.hasSubtitle ? '40px' : '46px')};
  font-weight: 400;
  font-family: 'Fixture Condensed Medium', sans-serif;
  color: ${(props) => (props.selected ? Theme.colorWhite : props.color)};
  margin: 0;
`;

export const BigButtonSubtitle = Styled.span<{selected: boolean, color: string}>`
  font-size: 24px;
  font-weight: 400;
  font-family: 'Fixture Condensed Medium', sans-serif;
  color: ${(props) => (props.selected ? Theme.colorWhite : props.color)};
  margin: 0;
  text-align: left;
`;
// end BIG BUTTONS
/* end GENERIC ITEMS */

/* start ROUNDED CARD */
export const RoundedCardStyled = Styled.div`
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08), 0 0 10px 0 rgba(0, 0, 0, 0.08);
  background-color: ${Theme.colorWhite};
  padding: 12px 15px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  height: 90px;
  width: 234px;
`;

export const RoundedCardTitleContainer = Styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  max-width: 60%;
`;

export const RoundedCardIcon = Styled.div`
  width: 24px;
`;

export const RoundedCardTitle = Styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${Theme.fontColorSecondary};
  text-wrap: wrap;
`;

export const RoundedCardChildrenContainer = Styled.div`
  align-self: center;
  width: fit-content;
`;
/* end ROUNDED CARD */

/* start RANKING CARD */
export const RankingCampaignContainerStyled = Styled.div`
  display: block;
  width: 100%;
`;

export const RankingCampaignContent = Styled.div<{height?: string}>`
  width: 100%;
  height: ${(props) => (props.height ? props.height : '540px')};
  overflow-y: auto;
  padding: 10px;

  &::-webkit-scrollbar {
    background-color: #efefef;
    width: 7px;
    height: 0.5rem;
  }
  
  &::-webkit-scrollbar-thumb {
    height: 0.5rem;
    border: 0 none;
    background-clip: padding-box;
    -webkit-border-radius: 0.7rem;
    border-radius: 0.7rem;
    background-color: ${Theme.colorPrimary};
    -webkit-box-shadow: inset -0.1rem -0.1rem 0 rgba(0, 0, 0, 0.05), inset 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.05);
    box-shadow: inset -0.1rem -0.1rem 0 rgba(0, 0, 0, 0.05), inset 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.05);
  }
  
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const BigCardRankingStyled = Styled.div<{fromRankingOneCard: boolean}>`
  background-color: ${Theme.colorWhite};
  border-radius: ${(props) => (props.fromRankingOneCard ? '20px 20px 0 0' : '20px')};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08), 0 0 10px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const BigCardRankingTitleContainer = Styled.div<{fromRankingOneCard: boolean}>`
  width: fit-content;
  position: relative;
  border-radius: ${(props) => (props.fromRankingOneCard ? '20px 100px 100px 0' : '20px 100px 100px 20px')};
  z-index: 2;
  color: ${Theme.colorWhite};
  font-weight: 700;
  font-size: 18px;
  background-color: ${Theme.colorPrimary};
  height: 100px;
  display: flex;
  align-items: center;
`;

export const BigCardRankingNumber = Styled.div`
  width: fit-content;
  position: absolute;
  left: 0;
  border-radius: 0 60px 60px 0;
  z-index: 3;
  color: ${Theme.colorPrimary};
  font-weight: 700;
  font-size: 26px;
  background-color: ${Theme.colorWhite};
  height: 60px;
  padding: 0 15px 0 10px;
  align-items: center;
  align-content: center;
`;

export const BigCardRankingTitle = Styled.div`
  margin: 30px 16px 30px 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  line-height: 1;
  max-height: calc(1.5em * 4);
`;

export const BigCardRankingSubtitle = Styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 18px;
`;

export const BigCardRankingContent = Styled.div`
  align-content: center;
  padding: 0 16px;
  width: fit-content;
  display: grid;
  justify-items: self-end;
`;

export const RankingOneCardContainer = Styled.div`
width: 99%;
  display: block;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08), 0 0 10px 0 rgba(0, 0, 0, 0.08);
`;

export const OneCardContent = Styled.div`
  padding: 30px;
  display: block;
  background-color: ${Theme.colorWhite};
  border-radius: 0 0 20px 20px;
`;

export const CardRankingStyled = Styled.div`
  background-color: ${Theme.colorWhite};
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08), 0 0 10px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

export const CardRankingTitleContainer = Styled.div<{hasSubtitle: boolean}>`
  width: fit-content;
  position: relative;
  border-radius: 20px 100px 100px 20px;
  z-index: 2;
  color: ${Theme.colorPrimary};
  font-weight: 700;
  font-size: ${(props) => (props.hasSubtitle ? '15px' : '18px')};
  height: 100px;
  display: flex;
  align-items: center;
`;

export const CardRankingNumber = Styled.div`
  width: fit-content;
  max-width: 44px;
  position: absolute;
  left: 0;
  border-radius: 0 60px 60px 0;
  z-index: 3;
  color: ${Theme.colorWhite};
  font-weight: 700;
  font-size: 26px;
  background-color: ${Theme.colorPrimary};
  height: 60px;
  padding: 0 13px 0 7px;
  align-items: center;
  align-content: center;
`;

export const CardRankingTitle = Styled.div`
  margin: 30px 16px 30px 50px;
  max-height: 100px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  line-height: 1;
  max-height: calc(1.5em * 5);
`;

export const CardRankingSubtitle = Styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const CardRankingContent = Styled.div`
  align-content: center;
  padding: 0 16px;
  min-width: 75%;
`;

export const RankingMultipleCardContainer = Styled.div`
  width: 99%;
  gap: 18px;
  display: grid;
`;
/* end RANKING CARD */

/* start MULTIPLE PRODUCTS ASESORES */
export const MultipleProductsAsesoresStyled = Styled.div`
  border-radius: 30px;
  background-color: ${Theme.colorBorder};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08), 0 0 10px 0 rgba(0, 0, 0, 0.08);
  margin: 12px;
  display: grid;
  width: 100%;
  overflow: hidden;
  gap: 1px;
`;

export const MultipleProductsAsesoresProductHeader = Styled.div`
  background-color: ${Theme.colorSecondary2};
  color: ${Theme.colorPrimary};
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 15px 20px;
  align-items: center;
`;

export const MultipleProductsAsesoresProductContainer = Styled.div`
  background-color: ${Theme.colorWhite};
  border-radius: 0;
  padding: 45px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const MultipleProductsAsesoresProductFooter = Styled.div`
  background-color: ${Theme.colorSecondary2};
  border-radius: 0 0 30px 30px;
  width: 100%;
  padding: 30px;
  display: flex;
`;

export const AmountAsesoresCard = Styled.div`
  background-color: ${Theme.colorSecondary2};
  color: ${Theme.colorSecondary};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08), 0 0 10px 0 rgba(0, 0, 0, 0.08);
  font-size: 28px;
  font-weight: 700;
  padding: 18px 28px;
  border-radius: 40px;
`;

export const MultipleProductsAsesoresPositionContainer = Styled.div`
  border-radius: 40px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08), 0 0 10px 0 rgba(0, 0, 0, 0.08);
  display: inline-flex;
  overflow: hidden;
`;

export const MultipleProductsAsesoresPositionText = Styled.div`
  background-color: ${Theme.colorWhite};
  padding: 20px 50px 20px 30px;
  color: ${Theme.colorPrimary};
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const MultipleProductsAsesoresPositionRanking = Styled.div`
  background-color: ${Theme.colorPrimary};
  padding: 30px 20px;
  color: ${Theme.colorWhite};
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  padding: 20px 20px 20px 15px;
`;
/* end MULTIPLE PRODUCTS ASESORES */

/* start SKELETON */
const StyledLoading = css`
  background: #d0dadf;
  background: linear-gradient(110deg, #d0dadf 8%, #ececec 18%, #d0dadf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  margin: 0 auto;
  height: 28px;
  width: 100%;

  @keyframes shine {
    to {
      background-position-x: -250%;
    }
  }
`;

export const SkeletonCustom = Styled.div<{width?: string, height?: string, margin?: string, borderRadius?: string}>`
  ${StyledLoading};
  width: ${(props) => (props.width ? props.width : '10px')};
  height: ${(props) => (props.height ? props.height : '10px')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '5px')};
`;
/* end SKELETON */
