import Theme from '../../../styles/Theme';
import RankingOneCard from './RankingOneCard';
import {
  RankingCampaignContainerStyled,
  RankingCampaignContent,
  DinamizationText,
  ContainerItems,
} from '../../organisms/Dinamizations/StyledDinamizations';
import HeaderCard from '../../organisms/Dinamizations/HeaderCard';

export default function RankingCampaignCardDoficorred(props:any) {
  const {
    data,
    width,
  } = props;
  const rankingData = data && data[0] ? data[0]?.OD : undefined;

  return (
    <HeaderCard
      title={data[0]?.moreInfo?.odTitle}
      color={Theme.colorPrimary}
      width={width}
      height="auto"
    >
      <RankingCampaignContainerStyled>
        {data[0]?.moreInfo?.odSubtitle && (
          <DinamizationText
            fontSize="20px"
            fontWeight="700"
            color={Theme.colorPrimary}
            margin="20px 10px"
            textShadow="1px 1px 3px rgba(0, 0, 0, 0.1)"
          >
            {data[0]?.moreInfo?.odSubtitle}
          </DinamizationText>
        )}
        <RankingCampaignContent
          height="300px"
        >
          <ContainerItems
            width="100%"
            justifyContent="space-between"
            display="inline-flex"
            gap="8px"
            margin="auto"
            alignItems="center"
          >
            {rankingData?.map((ranking:any) => (
              <RankingOneCard data={[ranking]} />
            ))}
          </ContainerItems>
        </RankingCampaignContent>
      </RankingCampaignContainerStyled>
    </HeaderCard>
  );
}
