import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { Results } from '../../../interfaces/Results.d';
import Theme from '../../../styles/Theme';
import LinkTo from '../../atoms/Link/LinkTo';
import IconArrow from '../../atoms/Icon/IconArrow';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import { LinkTable, Table } from '../../atoms/Table/Table';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import useYear from '../../../hooks/useYear';
import useFormatDate from '../../../hooks/useDate';
import useCsvNumber from '../../../hooks/useCsvNumber';
import useProcessData from '../../../hooks/useProcessData';
import useNumberFormat from '../../../hooks/useNumberFormat';
import useFilterCsvColumns from '../../../hooks/useFilterCsvColumns';
import useMappingColumnNames from '../../../hooks/useMappingColumnNames';
import GenericError from '../Error/GenericError';
import FilterModel from '../../molecules/Filter/FilterModel';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getResultsByCodeGql';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import GET_PLAN_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getPlanResultsByCodeGql';

export const columnsResults = [
  {
    Header: 'Detalle',
    accessor: 'detail',
    Cell: ({ row }:any) => (
      <label {...row.getToggleRowExpandedProps()}>
        <LinkTable title="Detalle" onClick={() => '#'}>
          {row.isExpanded
            ? <IconArrow color={`${Theme.colorPrimary}`} rotate />
            : <IconArrow color={`${Theme.colorPrimary}`} /> }
        </LinkTable>
      </label>
    ),
  },
  {
    Header: 'Detalle',
    accessor: 'detail',
  },
];

export default function RappelDetailResults() {
  const { id } = useParams();
  const { year } = useYear();
  const graphQLClient = createGraphQLClient();
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());

  const planResultsByCodeParams = {
    code: id,
    myTeam: true,
    numberElements: 10,
    numberPage: 1,
  };
  const {
    data: dataPlan, error: errorPlan,
  } = useQuery(
    ['RappelPlanResultsByCode', planResultsByCodeParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_PLAN_RESULTS_BY_CODE_GQL,
      planResultsByCodeParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const variables:any = {
    code: id,
    category: globalFilter.category || undefined,
    dgt: globalFilter.dgt || undefined,
    dt: globalFilter.dt || undefined,
    od: globalFilter.od || undefined,
    firstName: globalFilter.firstName || undefined,
    lastName: globalFilter.lastName || undefined,
    nif: globalFilter.nif || undefined,
  };

  Object.keys(variables).forEach(
    (key:any) => (variables[key] === undefined || variables[key] === '') && delete variables[key],
  );

  const {
    data, error, refetch, isFetching,
  } = useQuery(
    ['RappelResultsByCode', variables],
    async () => (graphQLClient && graphQLClient.request(
      GET_RESULTS_BY_CODE_GQL,
      variables,
    )),
  );

  const variablesFilters = {
    grouping: dataPlan?.planByCode?.grouping?.name,
    type: 'Rappel',
    code: id,
    year,
  };

  const {
    data: dataFilters, error: errorData, isFetching: isFetchingFilters,
  } = useQuery(
    ['RappelFilters', variablesFilters],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      variablesFilters,
    )),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    refetch();
  }, [globalFilter]);

  if (error || errorData || errorPlan) {
    return <GenericError />;
  }

  const hasGroupedItems = dataPlan?.planByCode?.groupedData;
  const translations = dataPlan ? JSON.parse(dataPlan?.planByCode?.translations) : {};

  const formatColumn = (extraField: string) => {
    if (translations[extraField]) {
      if (translations[extraField]?.includes('decimales')) {
        return translations[extraField]?.replace(/\(decimales\)/gi, '');
      }
      if (translations[extraField]?.includes('porcentaje')) {
        return translations[extraField]?.replace(/\(porcentaje\)/gi, '');
      }
      return translations[extraField];
    } if (extraField === 'denomination') {
      return 'Denominación';
    }
    return extraField;
  };

  const formatValue = (fieldValue: any, col: string) => {
    if (typeof (fieldValue) === 'number') {
      const colInTranslations = Object.values(translations).includes(col);
      const trValues = Object.values(translations);
      const translationsWithoutSpaces: any = {};
      if (typeof translations !== 'undefined') {
        Object.entries(translations)?.forEach(([keyT, valueT]) => {
          translationsWithoutSpaces[keyT.replace(/ /g, '')] = `${valueT}`.replace(/ /g, '');
        });
      }

      if (typeof fieldValue === 'number') {
        if (translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(decimales)')
          || translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(porcentaje)')
        ) {
          if (translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(porcentaje)')) {
            return `${useNumberFormat(fieldValue * 100, true)} %`;
          }
          return `${useNumberFormat(fieldValue, true)}`;
        }
      }

      if (colInTranslations
        && (`${trValues.find((e:any) => e === col)}`?.includes('(decimales)')
        || translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(porcentaje)'))) {
        if (`${trValues.find((e:any) => e === col)}`?.includes('(porcentaje)')) {
          return `${useNumberFormat(fieldValue * 100, true)} %`;
        }
        return `${useNumberFormat(fieldValue, true)}`;
      }
      return useNumberFormat(fieldValue, false);
    }
    return fieldValue;
  };

  const formatValueCsv = (fieldValue: any, col: string) => {
    if (typeof (fieldValue) === 'number') {
      if (translations[col]?.includes('Porcentaje') || translations[col]?.includes('porcentaje')) {
        return `${useNumberFormat(fieldValue * 100, true, true)} %`;
      }
      if (translations[col]
        && (translations[col]?.includes('decimales')
        || translations[col]?.includes('%')
        || translations[col]?.includes('Porcentaje')
        || translations[col]?.includes('porcentaje'))) {
        return `${useNumberFormat(fieldValue, true, true)}${translations[col]?.includes('(porcentaje)') ? '%' : ''}`;
      }
      return useNumberFormat(fieldValue, false, true);
    }
    return fieldValue;
  };

  const isGroupedAttribute = (attribute: string) => {
    const groupedItem = dataPlan?.planByCode?.groupedItems?.split(',').map((e:any) => (attribute.includes(e)));
    return groupedItem.includes(true);
  };

  const dataResults = data?.myTeamResultsByCode?.nodes?.map((r: Results) => ({
    dgt: r.territoryNameDgt ? `${r.dgt} - ${r?.territoryNameDgt}` : r.dgt,
    dt: r.territoryNameDt ? `${r.dt} - ${r.territoryNameDt}` : r.dt,
    od: r.territoryNameOd ? `${r.od} - ${r.territoryNameOd}` : r.od,
    denomination: `${r.firstName} ${r.lastName}`,
    nif: r?.nif,
    extraFields: r?.extraFields,
    dataDate: r?.dataDate,
    resultId: r?.id,
    puestoId: r?.puestoId,
    userId: r?.userId,
  }));

  const levelsCount = dataPlan?.planByCode?.planLevels.map(
    (e:any) => e.level,
  ).sort((a:any, b:any) => b - a)[0];

  // Duplicate variables not to use the same one for table and csv (because of data types)
  let dataResultsExtraFields:any = {};
  const dataWithExtras:any = [];

  dataResults?.map((res:any) => {
    dataResultsExtraFields = {};
    Object.keys(res?.extraFields).filter((c: any) => !['DGT', 'DT', 'OD'].includes(c)).map((k:string) => {
      if (k === 'FECHA ACT') {
        if (res?.dataDate) {
          dataResultsExtraFields['FECHA DATO'.replace(/ /g, '')] = useFormatDate(res?.dataDate);
        } else {
          dataResultsExtraFields['FECHA DATO'.replace(/ /g, '')] = useFormatDate(res?.extraFields[k]?.split('T')[0]);
        }
      } else {
        dataResultsExtraFields[k.replace(/ /g, '').split('.').join('')] = formatValue(res?.extraFields[k], k.replace('Objetivo', 'Ppto NP').replace('Presupuesto', '% Presupuesto'));
      }
      return dataResultsExtraFields;
    });
    if (dataPlan?.planByCode?.grouping?.name !== 'COMERCIAL' && levelsCount > 2) {
      dataResultsExtraFields.detail = <LinkTo to={`/team/rappel/resumen/${res.resultId}/detail/${res.denomination}?grouping=${dataPlan?.planByCode?.grouping?.name}&code=${id}&participantId=${res.userId}`} color={Theme.colorPrimary} style={{ textAlign: 'center', display: 'block' }}>Ver detalle</LinkTo>;
    }
    const rowWithExtras = {
      ...res,
      ...dataResultsExtraFields,
    };
    dataWithExtras.push(rowWithExtras);

    return res;
  });

  let dataResultsExtraFieldsCSV:any = {};
  const dataWithExtrasCSV:any = [];

  dataResults?.map((res:any) => {
    dataResultsExtraFieldsCSV = {};
    Object.keys(res?.extraFields).map((k:string) => {
      if (!hasGroupedItems || (hasGroupedItems && !isGroupedAttribute(k))) {
        if (k === 'FECHA ACT') {
          if (res?.dataDate) {
            dataResultsExtraFieldsCSV['FECHA DATO'.replace(/ /g, '')] = useFormatDate(res?.dataDate);
          } else {
            dataResultsExtraFieldsCSV['FECHA DATO'.replace(/ /g, '')] = useFormatDate(res?.extraFields[k]?.split('T')[0]);
          }
        } else {
          dataResultsExtraFieldsCSV[k.replace(/ /g, '').split('.').join('')] = formatValueCsv(res?.extraFields[k], k);
        }
      } else if (isGroupedAttribute(k)) {
        dataResultsExtraFieldsCSV[k.replace(/ /g, '').split('.').join('')] = formatValueCsv(res?.extraFields[k], k);
      }
      return dataResultsExtraFieldsCSV;
    });
    const rowWithExtrasCSV = { ...res, ...dataResultsExtraFieldsCSV };
    dataWithExtrasCSV.push(rowWithExtrasCSV);

    return res;
  });

  const orderedExtraFields:any = [];
  const orderedExtraFieldsCsv: any = [];
  let totalCols: any = [];
  let totalColsCsv: any = [];

  if (dataResults && dataResults?.length > 0) {
    delete dataResults[0].extraFields;
    Object.keys(dataResults[0]).map((col:any) => {
      if (col !== 'userId') {
        totalCols.push({ Header: useMappingColumnNames(col, 'rappel'), accessor: col?.replace(/ /g, '') });
      }
      return totalCols;
    });
  }
  const dataResultsExtraFieldsParsed = Object.keys(dataResultsExtraFields)?.map((e:any) => e?.replace(/ /g, '').toLowerCase());
  const planLevels1 = dataPlan?.planByCode?.planLevels?.filter((l:any) => l.level === 1);
  const totalColsParsed = totalCols?.map((e:any) => e.accessor?.replace(/ /g, '').toLowerCase());
  const hasDenomination = planLevels1 && planLevels1?.filter((pl:any) => pl.field.toLowerCase() === 'denomination');
  planLevels1?.push({
    id: '0',
    field: 'resultId',
    level: 1,
    order: 0,
  });

  if (hasDenomination && hasDenomination?.length === 0) {
    planLevels1.push({
      id: '01',
      field: 'denomination',
      level: 1,
      order: 3,
    });
  }

  planLevels1?.sort((a: any, b: any) => a.order - b.order).map((pl:any) => {
    const plClean = pl?.field?.replace(/ /g, '')?.split('.')?.join('').toLowerCase();
    if (plClean === 'resultid') {
      orderedExtraFields.push({
        Header: formatColumn(pl?.field),
        accessor: 'resultId',
      });
    } else if (dataResultsExtraFieldsParsed?.includes(plClean)) {
      orderedExtraFields.push({
        Header: formatColumn(pl?.field),
        accessor: pl.field.replace(/ /g, '')?.split('.')?.join(''),
      });
    } else if (totalColsParsed?.includes(plClean)) {
      orderedExtraFields.push({
        Header: formatColumn(pl?.field),
        accessor: pl.field.replace(/ /g, '')?.split('.')?.join('').toLowerCase(),
      });
    }
    return orderedExtraFields;
  });

  if (dataResults?.length > 0) {
    delete dataResults[0].extraFields;

    Object.keys(dataResults[0]).map((col:any) => {
      if (col !== 'resultId' || col !== 'userId') {
        totalColsCsv.push({ label: useMappingColumnNames(col, 'rappel'), key: col.replace(/ /g, '') });
      }
      return totalColsCsv;
    });
  }

  const dataResultsExtraFieldsCsvParsed = Object.keys(dataResultsExtraFieldsCSV)?.map((e:any) => e?.replace(/ /g, '').toLowerCase());
  const totalColsParsedCsv = totalColsCsv?.map((e:any) => e.key?.replace(/ /g, '').toLowerCase());
  planLevels1?.sort((a: any, b: any) => a.order - b.order).map((pl:any) => {
    const plClean = pl?.field?.replace(/ /g, '')?.split('.')?.join('').toLowerCase();

    if (dataResultsExtraFieldsCsvParsed?.includes(plClean)) {
      orderedExtraFieldsCsv.push({
        label: formatColumn(pl?.field),
        key: pl.field.replace(/ /g, '')?.split('.')?.join(''),
      });
    } else if (totalColsParsedCsv?.includes(plClean)) {
      orderedExtraFieldsCsv.push({
        label: formatColumn(pl?.field),
        key: pl.field.replace(/ /g, '')?.split('.')?.join('').toLowerCase(),
      });
    }
    return orderedExtraFieldsCsv;
  });

  totalCols = [orderedExtraFields].flat();
  totalColsCsv = [orderedExtraFieldsCsv].flat();

  const uniqueHeaders: any = [];
  const uniqueCols = totalCols.filter((element: any) => {
    const isDuplicate = uniqueHeaders.includes(element.Header);
    if (!isDuplicate) {
      uniqueHeaders.push(element.Header);
      return true;
    }
    return false;
  });

  const uniqueHeadersCsv: any = [];
  const uniqueColsCsv = totalColsCsv.filter((element: any) => {
    const isDuplicate = uniqueHeadersCsv.includes(element.label);
    if (!isDuplicate) {
      uniqueHeadersCsv.push(element.label);
      return true;
    }
    return false;
  });

  if (levelsCount > 1) {
    if (levelsCount > 2) {
      uniqueCols.push(columnsResults[1]);
      uniqueColsCsv.push({ label: columnsResults[1].Header, key: columnsResults[1].accessor });
    } else {
      uniqueCols.push(columnsResults[0]);
      uniqueColsCsv.push({ label: columnsResults[0].Header, key: columnsResults[0].accessor });
    }
  }

  let processedKeysCsv: any = [];
  processedKeysCsv = uniqueColsCsv;

  const processDataWithExtrasCSV = dataWithExtrasCSV.map((d:any) => ({ ...d }));

  processDataWithExtrasCSV.forEach((item: any, index: number) => {
    Object.entries(item).forEach(([key, value]: any) => {
      const decimals = false;
      processDataWithExtrasCSV[index][key] = useCsvNumber(value, decimals);
    });
  });

  // Filtering csv cols
  processedKeysCsv = useFilterCsvColumns(
    processedKeysCsv,
    processDataWithExtrasCSV,
  );
  // Fin filtering csv cols

  const processDataWithExtras = useProcessData(dataWithExtras);

  return (
    <Container>
      <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>
      <Typography
        align="center"
        color={Theme.colorRappel}
        display="block"
        size="28px"
        margin="2rem auto"
        weight="500"
      >
        {dataPlan?.planByCode?.description}
      </Typography>

      {!isFetchingFilters ? (
        <FilterForm
          dataFilters={dataFilters}
          filter={globalFilter}
          setFilter={setGlobalFilter}
          type="Rappel"
          grouping={dataPlan?.planByCode?.grouping?.name}
        />
      ) : <FilterFormSkeleton countFilters={10} />}

      {processedKeysCsv && processDataWithExtrasCSV && (
        <CardDataExportXLSX
          headers={processedKeysCsv}
          data={processDataWithExtrasCSV}
          margin="0 0 0 auto"
          name={`rappel_${id}_results`}
        />
      )}

      {(!isFetching && uniqueCols.length >= 2) ? (processDataWithExtras && (
        <Table
          columns={uniqueCols}
          data={processDataWithExtras}
          theme={Theme.colorRappel}
        />
      )) : (<TableSkeleton countCell={6} theme={Theme.colorRappel} />)}
    </Container>
  );
}
