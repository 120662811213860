import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const CardBoxContainer = Styled.div`
  background: ${Theme.colorWhite};
  border-radius: ${Theme.radius};
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  gap: 4px;
  width: 100%;
  justify-content: flex-start;
`;

export const CardBoxTitle = Styled.div`
  color: ${Theme.colorSecondary};
  font-size: 14px;
  text-transform: uppercase;
  line-height: 0.92;
`;

export const CardBoxSubtitle = Styled.div`
  color: ${Theme.colorRanking};
  font-family: 'Fixture Condensed Medium', sans-serif;
  font-weight: 100;
  font-size: 28px;
  text-transform: uppercase;
  line-height: 0.92;
`;

export const CardBoxDate = Styled.div`
  color: ${Theme.colorSecondary3};
  text-align: end;
  font-size: 12px;
  line-height: 1.5;
  margin-top: auto;
`;

export const CardBoxRow = Styled.div`
  display: flex;
  gap: 1rem;

  > div {
    flex-grow: 1;
    width: calc(100% - 48px);
    margin-left: auto;
  }

  > svg {
    flex-grow: 1;
    order: -1;
  }
`;

export const StyledChecksContainer = Styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: stretch;
  justify-self: flex-end;
`;

export const CardBoxSpanWithCheck = Styled.span`
  color: ${Theme.colorSecondary};
  font-size: 14px;
  align-items: center;
  display: flex;

  &:before{
    content: "";
    display: inline-block;
    transform: rotate(50deg);
    height: 9px;
    width: 4px;
    border-bottom: 3px solid ${Theme.colorRanking};
    border-right: 3px solid ${Theme.colorRanking};
    position: relative;
    top: 0;
    left: 0;
    margin-right: 13px;
  }
`;

export const StyledLinkTo = Styled.a`
  color: ${Theme.colorSecondary};
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;

  &:hover{
    color: ${Theme.colorRanking};
    text-decoration: underline;

    & > svg{
      visibility:visible;
    }
  }

  & > svg{
    margin-left: 5px;
    visibility:hidden;
  }
`;

export const PositionWrapper = Styled.div`
  overflow: hidden;
  margin-top: -10rem;
  margin-bottom: 4rem;
`;

export const RankingWrapper = Styled.div`
  overflow: hidden;
  margin-top: -10rem;
`;

export const PositionList = Styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: flex-end;
  gap: 1.5%;
  min-height: 250px;
  overflow: auto;
  padding: 1rem 0;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const RankingList = Styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: flex-end;
  gap: 1.5%;
  height: 250px;
  overflow: auto;
  position: relative;
  justify-content: center;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PositionDate = Styled.span`
  color: ${Theme.fontColorSecondary};
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-bottom: -1.5rem;
`;

export const PositionItem = Styled.li<{position: number, total: number}>`
  color: ${Theme.fontColorSecondary};
  font-size: 13px;
  text-align: center;
  position: relative;
  margin-top: 20px;
  width: ${(props) => 100 / props.total}%;

  > div {
    min-height: ${(props) => 180 - (props.position * 10)}px;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    > div {
      background: ${Theme.fontColorSecondary};
    }
  }

  @media screen and (min-width: 1024px) {
    min-width: 100px;
  }
`;

export const RankingItemCard = Styled.div<{position: number}>`
  color: ${Theme.fontColorSecondary};
  font-size: 13px;
  text-align: center;
  position: relative;
  margin: 20px 80px 0px 80px;

  &:nth-child(2),
  &:nth-child(3){
    width: 160px;
    > div {
      height: 180px;
      background: ${Theme.fontColorSecondary};
      padding-right: 1.1rem;
      padding-left: 1.1rem;
    }
  }
  &:nth-child(1){
    position: absolute;
    width: 200px;
    z-index: 3;
    box-shadow: 10px 0 20px rgba(0, 0, 0, 0.1), -10px 0 20px rgba(0, 0, 0, 0.1);

    > div {
      height: 200px;
      background: ${Theme.fontColorSecondary};
    }
  }

  @media screen and (min-width: 1024px) {
    min-width: 100px;
  }
`;

export const PositionItemContent = Styled.div`
  background: ${Theme.colorSecondary3};
  border-radius: 15px;
  color: ${Theme.colorWhite};
  display: flex;
  font-family: 'Fixture Condensed Medium', sans-serif;
  font-size: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 2rem 1rem;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    position: relative;

    small {
      font-size: 14px;
      font-weight: 500;
      font-family: 'DM Sans',sans-serif;
      position: absolute;
      top: 0.92rem;
      left: calc(50% - 3px);
      transform: translateY(-50%);
    }
  }
`;

export const PositionItemContentCard = Styled.div`
  background: ${Theme.colorSecondary3};
  border-radius: 15px 15px 0 0;
  color: ${Theme.colorWhite};
  display: flex;
  font-family: 'Fixture Condensed Medium', sans-serif;
  font-size: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 2rem 1rem 1rem 1rem;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
    position: relative;

    img {
      margin-bottom: 0.6rem;
      width: 100px;
    }
  }
`;

export const PositionName = Styled.span`
  font-size: 13px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  height: 1.5rem;
  margin-top: 0.5rem;
`;

export const DGTName = Styled.span`
  font-size: 13px;
  font-family: 'DM Sans';
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  height: 1.5rem;
  margin-top: 0.5rem;
`;

export const RankingTableWrapper = Styled.div`
  width: 60%;
  margin-right: auto;
  margin-left: auto;
  background-color: white;
  border-radius: 16px;
  padding: 30px;
  > div {
    margin-bottom: 0px;
    width: 100%;
  }

  thead {
    th {
      background-color: ${Theme.colorDisabled};
      color: black;
      &:nth-child(1) {
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      td{
        &:nth-child(1) {
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }
`;

export const CardBoxListImages = Styled.div`
  /* background: ${Theme.colorSecondary4}; */
  padding-bottom: 2rem;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  z-index: 2;
`;

export const CardBoxListImagesContainer = Styled.div`
  margin: 0 auto;
  max-width: 768px;
  width: 94%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  > div {
    width: calc(50% - 1rem);
  }

  @media screen and (min-width: 768px) {
    max-width: 1024px;
    gap: 1rem;

    > div {
      width: calc(25% - 0.75rem);
    }
  }
`;

export const CardBoxItemImage = Styled.div`
  background-color: ${Theme.colorWhite};
  border-radius: 20px;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.05);
  position: relative;

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 4;
  }
`;

export const CardBoxImage = Styled.div<{isFilter?: boolean}>`
  border-radius: 20px;
  color: #FFFFFF;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 1;
  ${(props) => (props.isFilter !== false ? 'filter: grayscale(1);' : '')}

  &:after {
    content: '';
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

export const CardBoxImageName = Styled.h2`
  font-family: 'Fixture Condensed Medium', sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
  color: ${Theme.colorWhite};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  margin: 0 0 1.2rem;
`;

export const CardBoxItemImageImg = Styled.img`
  display: block;
  width: 100%;
`;

export const CardComingSoon = Styled.div`
  position: absolute;
  background-color: ${Theme.colorPrimary};
  padding: 0.25rem 0.5rem;
  font-size: 0.82rem;
  color: ${Theme.colorWhite};
  font-weight: 900;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

export const CardBoxListImagesContainer2025 = Styled.div`
  width: fit-content;
  height: auto;
  display: inline-flex;
  gap: 40px;
  margin: -60px auto 0 auto;
`;

export const CardBoxItemImage2025 = Styled.div<{soon:boolean}>`
  background-color: ${Theme.colorWhite};
  border-radius: 50%;
  box-shadow: 6px 4px 30px 0px rgba(0, 0, 0, 0.15);
  width: 120px;
  height: 120px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  background: ${(props) => (props.soon ? 'rgba(200, 200, 200, 0.5)' : Theme.colorWhite)};
  &:hover {
    div {
      background-color: ${(props) => (props.soon ? '#d8d8d8' : Theme.colorCampaignAlternative)};
    }
  }
`;

export const CardBoxItemImageSummary2025 = Styled.div<{soon:boolean}>`
  background-color: ${Theme.colorWhite};
  border-radius: 50%;
  box-shadow: 6px 4px 30px 0px rgba(0, 0, 0, 0.15);
  width: 120px;
  height: 120px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  background: ${(props) => (props.soon ? 'rgba(200, 200, 200, 0.5)' : Theme.colorWhite)};
  &:hover {
    div {
      background-color: ${(props) => (props.soon ? '#d8d8d8' : '#b19353')};
    }
  }
`;

export const CardBoxItemImageImg2025 = Styled.img`
  width: 50%;
`;

export const CardBoxImageName2025 = Styled.div`
  color: ${Theme.colorCampaign};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
`;

export const CardBoxImageNameSummary2025 = Styled.div`
  color: ${Theme.colorRanking};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
`;

export const CardBoxItem2025 = Styled.div`
  display: grid; 
  gap: 10px;
  a {
    text-decoration: none;
  }
`;

export const CardBoxItemLink2025 = Styled.div<{soon:boolean}>`
  background-color: ${Theme.colorCampaign};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 1px;
  right: 1px;
  background: ${(props) => (props.soon ? 'rgba(200, 200, 200, 0.5)' : Theme.colorCampaign)};
`;

export const CardBoxItemLinkSummary2025 = Styled.div<{soon:boolean}>`
  background-color: ${Theme.colorRanking};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 1px;
  right: 1px;
  background: ${(props) => (props.soon ? 'rgba(200, 200, 200, 0.5)' : Theme.colorRanking)};
`;
