import { gql } from 'graphql-request';

const GET_DINAMIZATION_INFO_GQL = gql`
query MyQuery($type: String!, $year: Int) {
  dinamizationInfo(type: $type, year: $year) {
    userInfo
    summary
    ranking
    challenges
    rappels
  }
}
`;
export default GET_DINAMIZATION_INFO_GQL;
