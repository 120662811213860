import useYear from '../../../hooks/useYear';
import imgAutomocion from '../../../assets/images/ranking_automocion.jpg';
import imgRedAgencial from '../../../assets/images/ranking_red_agencial.jpg';
import imgRedEspecifica from '../../../assets/images/ranking_red_especifica.jpg';
import imgCorredores from '../../../assets/images/ranking_corredores.jpg';
import imgAutomocion2024 from '../../../assets/images/automocion_2024.jpg';
import imgRedAgencial2024 from '../../../assets/images/red_agencial_2024.jpg';
import imgRedEspecifica2024 from '../../../assets/images/red_especifica_2024.jpg';
import imgCorredores2024 from '../../../assets/images/corredores_2024.jpg';
import imgAutomocion2025 from '../../../assets/images/automocion_2025.svg';
import imgRedAgencial2025 from '../../../assets/images/red_agencial_2025.svg';
import imgRedVida2025 from '../../../assets/images/red_vida_2025.svg';
import imgCorredores2025 from '../../../assets/images/corredores_2025.svg';

import {
  CardBoxImage,
  CardBoxImageName,
  CardBoxItemImage,
  CardBoxItemImageImg,
  CardBoxListImages,
  CardBoxListImagesContainer,
  CardComingSoon,
  CardBoxListImagesContainer2025,
  CardBoxItemImage2025,
  CardBoxItemImageImg2025,
  CardBoxImageName2025,
  CardBoxItem2025,
  CardBoxItemLink2025,
} from './StyledCardBox';

import IconPlus from '../Icon/IconPlus';

type RankingImages = {
  id: number,
  name: string,
  image: string,
  soon: boolean,
  link?: string,
}

const rankingImages: any = {
  2023: [{
    id: 0,
    name: 'Red Agencial',
    image: imgRedAgencial,
    soon: false,
    link: 'https://app.powerbi.com/links/O5arO02UKD?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&pbi_source=linkShare',
  },
  {
    id: 1,
    name: 'Red Específica',
    image: imgRedEspecifica,
    soon: false,
    link: 'https://app.powerbi.com/links/00tkGrP4K4?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&pbi_source=linkShare',
  },
  {
    id: 2,
    name: 'Automoción',
    image: imgAutomocion,
    soon: false,
    link: 'https://app.powerbi.com/links/3dZ1JKYmUG?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&pbi_source=linkShare',
  },
  {
    id: 3,
    name: 'Corredores',
    image: imgCorredores,
    soon: false,
    link: 'https://app.powerbi.com/links/PoPgdsWCXD?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&pbi_source=linkShare',
  }],
  2024: [{
    id: 0,
    name: 'Red Agencial',
    image: imgRedAgencial2024,
    soon: false,
    link: 'https://app.powerbi.com/groups/me/reports/140cb97a-721c-4b91-bf6c-fdeaf3884590/ReportSection?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&experience=power-bi',
  },
  {
    id: 1,
    name: 'Red Específica',
    image: imgRedEspecifica2024,
    soon: false,
    link: 'https://app.powerbi.com/groups/me/reports/0ae2a640-c06b-4795-bc15-367c2d1fb2be/ReportSectionfd492d01830b2781d11e?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&experience=power-bi',
  },
  {
    id: 2,
    name: 'Automoción',
    image: imgAutomocion2024,
    soon: false,
    link: 'https://app.powerbi.com/groups/me/reports/f44f53dc-4017-4770-ad04-f5275ea9fda0/ReportSection?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&experience=power-bi',
  },
  {
    id: 3,
    name: 'Corredores',
    image: imgCorredores2024,
    soon: false,
    link: 'https://app.powerbi.com/groups/me/reports/8a5f0deb-8466-40c8-aa72-cc77bb8df8c0/ReportSection?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&experience=power-bi',
  }],
  2025: [{
    id: 0,
    name: 'Red Agencial',
    image: imgRedAgencial2025,
    soon: false,
    link: 'https://app.powerbi.com/links/0Mc5Km1nc2?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&pbi_source=linkShare',
  },
  {
    id: 1,
    name: 'Red Vida',
    image: imgRedVida2025,
    soon: true,
    link: '',
  },
  {
    id: 2,
    name: 'Automoción',
    image: imgAutomocion2025,
    soon: true,
    link: '',
  },
  {
    id: 3,
    name: 'Corredores',
    image: imgCorredores2025,
    soon: true,
    link: '',
  }],
};

export default function CardBoxImagesList() {
  const { year } = useYear();

  return (
    <>
      {year <= 2024 && (
        <CardBoxListImages>
          <CardBoxListImagesContainer>
            {rankingImages[year].map((item: RankingImages) => (
              <CardBoxItemImage key={item.id}>
                <CardBoxImage isFilter={item.soon}>
                  <CardBoxItemImageImg src={item.image} alt={item.name} />
                </CardBoxImage>
                <CardBoxImageName>{item.name}</CardBoxImageName>
                {item.soon && (
                  <CardComingSoon>PRÓXIMAMENTE</CardComingSoon>
                )}
                {item?.link && (
                  <a href={item.link} target="_blank" title={item.name} rel="noreferrer">{item.name}</a>
                ) }
              </CardBoxItemImage>
            ))}
          </CardBoxListImagesContainer>
        </CardBoxListImages>
      )}

      {year >= 2025 && (
        <CardBoxListImagesContainer2025>
          {rankingImages[year].map((item: RankingImages) => (
            <CardBoxItem2025>
              {item.link ? (
                <a href={item.link} target="_blank" title={item.name} rel="noreferrer">
                  <CardBoxItemImage2025 key={item.id} soon={item.soon}>
                    <CardBoxItemImageImg2025 src={item.image} alt={item.name} />
                    <CardBoxItemLink2025 soon={item.soon}><IconPlus color="#ffffff" width="18px" /></CardBoxItemLink2025>
                  </CardBoxItemImage2025>
                  <CardBoxImageName2025>{item.name}</CardBoxImageName2025>
                </a>
              ) : (
                <div>
                  <CardBoxItemImage2025 key={item.id} soon={item.soon}>
                    <CardBoxItemImageImg2025 src={item.image} alt={item.name} />
                    <CardBoxItemLink2025 soon={item.soon}><IconPlus color="#ffffff" width="18px" /></CardBoxItemLink2025>
                  </CardBoxItemImage2025>
                  <CardBoxImageName2025>{item.name}</CardBoxImageName2025>
                </div>
              )}
            </CardBoxItem2025>
          ))}
        </CardBoxListImagesContainer2025>
      )}
    </>
  );
}
