import Theme from '../../../styles/Theme';
import HeaderCard from '../../organisms/Dinamizations/HeaderCard';
import MultipleProductsAsesores from '../../organisms/Dinamizations/MultipleProductsAsesores';

export default function SummaryAsesores(props:any) {
  const { info, products } = props;

  return (
    <HeaderCard
      title={info?.title}
      color={Theme.colorPrimary}
      width="100%"
    >
      {/* TODO: Hacer OneProductAsesores */}
      <MultipleProductsAsesores
        info={info}
        products={products}
      />
    </HeaderCard>
  );
}
