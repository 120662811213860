import {
  ChallengeCardDataContainer,
  ChallengeCardDataDescription,
  GraphicContainer,
  ValueInGraphic,
} from './StyledDinamizations';
import LinearPercentageGraphic from './LinearPercentageGraphic';

export default function ChallengeCardData({
  description,
  amount,
  totalAmount,
  color,
}: any) {
  return (
    <ChallengeCardDataContainer>
      <GraphicContainer>
        <ValueInGraphic color={color}>
          {amount}
          <span>
            de&nbsp;
            {totalAmount}
          </span>
        </ValueInGraphic>
        <LinearPercentageGraphic
          color={color}
          percentageValue={((amount / totalAmount) * 100)}
        />
      </GraphicContainer>
      <ChallengeCardDataDescription>
        {description}
      </ChallengeCardDataDescription>
    </ChallengeCardDataContainer>
  );
}
