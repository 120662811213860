import {
  CardPointsStyled,
  PointsTitle,
  PointsValue,
} from './StyledDinamizations';
import Theme from '../../../styles/Theme';

export default function CardPoints({
  title = '',
  value = '',
  color = Theme.colorPrimary,
  colorBackground = Theme.colorSecondary2,
  fontSize = '13px',
  showShadow = false,
}: any) {
  return (
    <CardPointsStyled showShadow={showShadow}>
      <PointsTitle
        color={color}
        colorBackground={colorBackground}
        fontSize={fontSize}
      >
        {title}
      </PointsTitle>
      <PointsValue color={color} fontSize={fontSize}>{value}</PointsValue>
    </CardPointsStyled>
  );
}
