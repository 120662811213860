import { IIcon } from './Iicon';

export default function IconEqualDinamizations({ color, height, width }: IIcon) {
  return (
    <svg width={width} height={height} viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.6665 1.5H8.6665" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M1.6665 6.5H8.6665" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
