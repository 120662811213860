import {
  BigCardRankingStyled,
  BigCardRankingTitleContainer,
  BigCardRankingNumber,
  BigCardRankingTitle,
  BigCardRankingSubtitle,
  BigCardRankingContent,
} from './StyledDinamizations';

export default function BigCardRanking({
  number = '',
  title = '',
  subtitle = '',
  children = '',
  fromRankingOneCard = false,
}: any) {
  return (
    <BigCardRankingStyled fromRankingOneCard={fromRankingOneCard}>
      <BigCardRankingTitleContainer fromRankingOneCard={fromRankingOneCard}>
        <BigCardRankingNumber>
          {number}
        </BigCardRankingNumber>
        <BigCardRankingTitle>
          {title}
          {subtitle !== '' && (
            <BigCardRankingSubtitle>
              <br />
              {subtitle}
            </BigCardRankingSubtitle>
          )}
        </BigCardRankingTitle>
      </BigCardRankingTitleContainer>
      <BigCardRankingContent>
        {children}
      </BigCardRankingContent>
    </BigCardRankingStyled>
  );
}
