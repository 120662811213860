import Styled, { css } from 'styled-components';
import Theme from '../../../styles/Theme';

export const Ranking2025Container = Styled.div`
  max-width: ${Theme.sizeContainer};
  width: 94%;
  margin: 0 auto;
  z-index: 2;
  height: 40px;
  @media screen and (min-width: 1080px) {
    max-width: ${Theme.sizeContainerXL};
  }
`;

export const RankingTitleContainer = Styled.div`
  padding: 16px 30px;
  margin: 0 0 -35px 0;
  z-index: 2;
  border-radius: 40px;
  background-color: ${Theme.colorWhite};
  display: inline-flex;
  gap: 10px;
  align-items: flex-end;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08), 0 0 10px 0 rgba(0, 0, 0, 0.08);
`;

export const RankingTitle = Styled.span`
  color: ${Theme.colorRanking};
  font-weight: 700;
  font-size: 32px;
  width: fit-content;
`;

export const RankingSubtitle = Styled.span`
  color: ${Theme.fontColorSecondary};
  font-weight: 500;
  font-size: 22px;
  width: fit-content;
  margin-bottom: 4px;
  text-transform: capitalize;
`;

export const RankingContainer = Styled.div`
  background-color: ${Theme.colorSecondary4};
  padding: 100px 50px 130px 50px;
  display: flex;
  justify-content: center;
`;

export const RankingWinnersContainer = Styled.div`
  background-color: #dbe2e6;
  border-radius: 30px;
  padding: 30px;
  display: inline-flex;
  gap: 35px;
`;

export const RankingParticipantsContainer = Styled.div`
  padding: 30px 15px;
  display: inline-flex;
  gap: 30px;
`;

export const RankingBarContainer = Styled.div`
  display: grid;
  justify-items: center;
  flex: 1;
`;

export const RankingBigBar = Styled.div`
  height: 293px;
  min-width: 88px;
  max-width: 130px;
  background-color: ${Theme.colorSecondary2};
  border-radius: 16px;
  padding: 5px;
  margin: 10px 0 15px 0;
  display: flex;
  align-items: flex-end;
  box-shadow: 6px 4px 30px 0px rgba(0, 0, 0, 0.15);
`;

export const RankingValueBar = Styled.div<{percentage?: number}>`
  border-radius: 16px;
  background-color: ${Theme.colorRanking};
  opacity: ${(props) => (props.percentage ? props.percentage : '1')}%;
  height: ${(props) => (props.percentage ? props.percentage : '100')}%;
  width: 100%;
`;

export const RankingValue = Styled.span`
  color: ${Theme.fontColorSecondary};
  font-size: 18px;
  font-weight: 700;
  width: fit-content;
  height: 40px;
  align-content: center;
`;

export const DGTName = Styled.span`
  color: ${Theme.fontColorSecondary};
  font-size: 14px;
  font-weight: 700;
  width: fit-content;
  text-align: center;
  text-transform: capitalize;
`;

export const DGTCode = Styled.span`
  color: ${Theme.fontColorSecondary};
  font-size: 12px;
  font-weight: 300;
  width: fit-content;
  text-align: center;
`;

export const RankingPositionContainer = Styled.div`
  position: relative;
  display: inline-block;
`;

export const RankingPosition = Styled.span`
  font-family: Arial;
  font-size: 20px;
  font-weight: 700;
  color: ${Theme.colorWhite};
  position: absolute;
  top: 2px;
  right: 14px;
`;

export const ContainerItems = Styled.div<{width?: string, height?: string, justifyContent?: string, display?: string, gap?: string, margin?: string, padding?:string, alignItems?: string, textAlign?: string, justifyItems?: string, alignContent?: string}>`
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  height: ${(props) => (props.height ? props.height : 'fit-content')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'unset')};
  display: ${(props) => (props.display ? props.display : 'flex')};
  gap: ${(props) => (props.gap ? props.gap : '12px')};
  margin: ${(props) => (props.margin ? props.margin : 'unset')};
  padding: ${(props) => (props.padding ? props.padding : 'unset')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'unset')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'unset')};
  justify-items: ${(props) => (props.justifyItems ? props.justifyItems : 'unset')};
  align-content: ${(props) => (props.alignContent ? props.alignContent : 'unset')};
`;

export const CardBoxImagesContainer2025 = Styled.div`
  width: 100%;
  margin-bottom: 130px;
  text-align: center;
`;

const StyledLoading = css`
  background: #d0dadf;
  background: linear-gradient(110deg, #d0dadf 8%, #ececec 18%, #d0dadf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  margin: 0 auto;
  height: 28px;
  width: 100%;

  @keyframes shine {
    to {
      background-position-x: -250%;
    }
  }
`;

export const SkeletonCustom = Styled.div<{width?: string, height?: string, margin?: string, borderRadius?: string}>`
  ${StyledLoading};
  width: ${(props) => (props.width ? props.width : '10px')};
  height: ${(props) => (props.height ? props.height : '10px')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '5px')};
`;
