import {
  RoundedPercentageGraphicStyled,
} from './StyledDinamizations';
import Theme from '../../../styles/Theme';

export default function RoundedPercentageGraphic({
  percentageValue = 0,
  color = Theme.colorPrimary,
  width,
  children,
}: any) {
  return (
    <RoundedPercentageGraphicStyled percentage={percentageValue} color={color} width={width}>
      { children }
    </RoundedPercentageGraphicStyled>
  );
}
