import {
  HeaderCardStyled,
  HeaderTitleContainer,
  HeaderTitle,
  HeaderSubtitle,
  HeaderBoxContainer,
} from './StyledDinamizations';
import Theme from '../../../styles/Theme';

export default function HeaderCard({
  title = '',
  subtitle = '',
  color = Theme.colorPrimary,
  width = '100%',
  height = 'fit-content',
  children = undefined,
  center = false,
  headerFontSize = '20px',
  miniCard = false,
  backgroundColor = Theme.colorSecondary2,
}: any) {
  return (
    <HeaderCardStyled width={width} backgroundColor={backgroundColor} height={height}>
      <HeaderTitleContainer color={color} center={center} miniCard={miniCard}>
        <HeaderTitle fontSize={headerFontSize} center={center}>{title}</HeaderTitle>
        {subtitle && <HeaderSubtitle>{subtitle}</HeaderSubtitle>}
      </HeaderTitleContainer>
      <HeaderBoxContainer miniCard={miniCard}>
        {children}
      </HeaderBoxContainer>
    </HeaderCardStyled>
  );
}
