import Theme from '../../../styles/Theme';
import {
  ChangePositionCardContainer,
  RoundedDelegateContainer,
  DinamizationText,
  VerticalDivider,
  PositionTextcontainer,
} from './StyledDinamizations';
import IconEqualDinamizations from '../../atoms/Icon/IconEqualDinamizations';
import IconUpArrowDinamizations from '../../atoms/Icon/IconUpArrowDinamizations';
import IconDownArrowDinamizations from '../../atoms/Icon/IconDownArrowDinamizations';

export default function ChangePositionCard({
  oldPosition,
  currentPosition,
}: any) {
  const color = oldPosition <= currentPosition ? Theme.colorSecondary3 : Theme.colorPrimary;
  const positionDifference = currentPosition - oldPosition;
  const getIcon = () => {
    let response = <>&nbsp;</>;
    if (positionDifference < 0) {
      response = <IconUpArrowDinamizations height="10px" color={Theme.colorWhite} />;
    } else if (positionDifference > 0) {
      response = <IconDownArrowDinamizations height="10px" color={Theme.colorWhite} />;
    } else {
      response = <IconEqualDinamizations height="10px" color={Theme.colorWhite} />;
    }
    return response;
  };

  const getDescription = () => {
    let response = '';
    if (positionDifference < 0) {
      response = 'SUBE';
    } else if (positionDifference > 0) {
      response = 'BAJA';
    } else {
      response = 'TE MANTIENES';
    }
    return response;
  };

  const icon = getIcon();
  const description = getDescription();

  return (
    <ChangePositionCardContainer>
      <DinamizationText
        fontSize="10px"
        fontWeight="700"
        color={Theme.fontColorSecondary}
        margin="0"
      >
        ÚLTIMA SEMANA
      </DinamizationText>
      <RoundedDelegateContainer
        color={color}
        height="40px"
        margin="6px 0 0 0"
      >
        {icon}
        <DinamizationText
          fontSize="12px"
          fontWeight="700"
          color={Theme.colorWhite}
          margin="0 10px 0 5px"
        >
          {description}
        </DinamizationText>
        {(positionDifference !== 0) && (
          <>
            <VerticalDivider
              color={Theme.colorWhite}
              height="25px"
            />
            <PositionTextcontainer>
              <DinamizationText
                fontSize="20px"
                fontWeight="700"
                color={Theme.colorWhite}
                margin="0 4px 0 12px"
              >
                {Math.abs(positionDifference)}
              </DinamizationText>
              <DinamizationText
                fontSize="13px"
                fontWeight="400"
                color={Theme.colorWhite}
                margin="0"
              >
                POSICIONES
              </DinamizationText>
            </PositionTextcontainer>
          </>
        )}
      </RoundedDelegateContainer>
    </ChangePositionCardContainer>
  );
}
