import { IIcon } from './Iicon';

export default function IconRoundedUpArrow({ color, height, width }: IIcon) {
  return (
    <svg width={width} height={height} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.6665 16C1.6665 7.71573 8.38223 1 16.6665 1C24.9508 1 31.6665 7.71573 31.6665 16C31.6665 24.2843 24.9508 31 16.6665 31C8.38223 31 1.6665 24.2843 1.6665 16Z" stroke={color} strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9165 11.3802L11.7272 15.5695L10.6665 14.5089L16.6754 8.5L22.6843 14.5089L21.6236 15.5695L17.4165 11.3624V23.5038H15.9165V11.3802Z" fill={color} />
    </svg>
  );
}
