import Theme from '../../../styles/Theme';
import HeaderCard from '../../organisms/Dinamizations/HeaderCard';
import ChallengeCard from '../../organisms/Dinamizations/ChallengeCard';
import { ChallengeCardsContainer } from '../../organisms/Dinamizations/StyledDinamizations';

export default function ChallengesCampaignCard(props:any) {
  const { data, width } = props;

  return (
    <HeaderCard
      title={data?.info?.title}
      subtitle={data?.info?.subtitle}
      color={Theme.colorPrimary}
      width={width}
      height="unset"
    >
      <ChallengeCardsContainer
        display={width === '100%' ? 'inline-flex' : 'grid'}
      >
        {data && data.products && data.products.length > 0 && data.products.map((challenge:any) => (
          <ChallengeCard
            key={challenge?.title}
            title={challenge?.title}
            icon={challenge?.icon}
            data1Amount={challenge?.data1Amount}
            data1TotalAmount={challenge?.data1TotalAmount}
            data1Description={challenge?.data1Description}
            data2Amount={challenge?.data2Amount}
            data2TotalAmount={challenge?.data2TotalAmount}
            data2Description={challenge?.data2Description}
            color={Theme.colorPrimary}
          />
        ))}
      </ChallengeCardsContainer>
    </HeaderCard>
  );
}
