import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { columns, columnsCSV, renderRowSubComponent } from './dataRappelGlobalPosition';
import Theme from '../../../styles/Theme';
import { IFilter } from '../../../interfaces/IFilter.d';
import Score from '../../molecules/Score/Score';
import FilterModel from '../../molecules/Filter/FilterModel';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import { Table } from '../../atoms/Table/Table';
import Container from '../../atoms/Container/Container';
import SkeletonCard from '../../atoms/Card/SkeletonCard';
import Typography from '../../atoms/Typography/Typography';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import ImageBanner from '../../atoms/ImageBanner/ImageBanner';
import GenericError from '../Error/GenericError';
import useYear from '../../../hooks/useYear';
import usePlan from '../../../hooks/usePlan';
import useProcessData from '../../../hooks/useProcessData';
import useNumberFormat from '../../../hooks/useNumberFormat';
import useGlobalPosition from '../../../hooks/useGlobalPosition';
import rappelBannerImage from '../../../assets/images/rappel_header.jpg';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_CALCULATE_PROVISIONAL_AMOUNT from '../../../graphql/queries/getCalculateProvisionalAmount';
import GET_TOTAL_PLAN_MET_COUNT_GP_CHART_BY_TIMEFRAME from '../../../graphql/queries/getTotalPlanMetCountGPChartByTimeframeGql';

export default function RappelsGlobalPosition() {
  const type = 'Rappel';
  const graphQLClient = createGraphQLClient();
  const { year: currentYear } = useYear();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const [filters, setFilters] = useState<IFilter>({});

  const variables:any = {
    type,
    grouping: globalFilter.grouping || undefined,
    subgrouping: globalFilter.subgrouping || undefined,
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    level: globalFilter.level || undefined,
    year: currentYear,
    status: globalFilter.status || undefined,
  };

  Object.keys(variables)?.forEach(
    (key:any) => (variables[key] === undefined || variables[key] === '') && delete variables[key],
  );

  const {
    data, error, refetch, isFetching,
  } = usePlan(variables);

  const {
    data: gpr,
    isFetching: isFetchingGpr,
    refetch: refetchChart,
  } = useQuery(
    [type, 'globalPositionCharts'],
    async () => graphQLClient && graphQLClient.request(
      GET_TOTAL_PLAN_MET_COUNT_GP_CHART_BY_TIMEFRAME,
      { ...variables, myTeam: false },
    ),
  );

  const varsProvisionalAmount = {
    userId: data !== undefined && data?.me?.id,
    type,
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : '',
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : '',
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : '',
    year: currentYear,
    status: globalFilter.status,
  };

  const {
    data: provisionalAmount,
    refetch: refetchProvisionalAmount,
  } = useQuery(
    ['CampaignCalculateProvisionalAmount', varsProvisionalAmount],
    async () => (graphQLClient && graphQLClient.request(
      GET_CALCULATE_PROVISIONAL_AMOUNT,
      varsProvisionalAmount,
    )),
    {
      refetchOnMount: false,
      enabled: false,
    },
  );

  function getScore() {
    const hashData = gpr?.totalPlanMetCountGlobalPositionChartByTimeframe?.totalPlanMetCount?.[0];
    if (globalFilter?.dateRange) {
      switch (globalFilter?.dateRange) {
      case 'ANUAL':
        return hashData.Anual;
      case 'SEMESTRAL':
        return hashData.Semestral;
      case 'INTERANUAL':
        return hashData.Interanual;
      case 'MENSUAL':
        return hashData.Mensual;
      case 'TRIMESTRAL':
        if (variables?.startDate === `${currentYear}-01-01`) {
          return hashData['1T'];
        } if (variables?.startDate === `${currentYear}-04-01`) {
          return hashData['2T'];
        } if (variables?.startDate === `${currentYear}-07-01`) {
          return hashData['3T'];
        }
        return hashData['4T'];
      default:
        return hashData?.total;
      }
    }
    return hashData?.total;
  }

  const scoreRappel = [{
    id: 0,
    title: 'Ganados',
    subtitle: 'a fecha',
    score: getScore(),
    icon: 'cup',
  },
  {
    id: 1,
    title: 'provisional',
    maintitle: 'Importe gana',
    score: useNumberFormat(provisionalAmount?.calculateProvisionalAmount?.provisionalAmount, true),
    icon: 'euro',
  }];

  const { getPlan: plan } = useGlobalPosition(data);
  const processPlan = useProcessData(plan);

  const mediadores = ['AFIS', 'AGENTES', 'APS', 'DELEGADOS', 'MEDIACONCES', 'MEDIAINMOBI', 'MEDIASALUD', 'MEDIAVIDA', 'GDLG', 'AVINCULADO'];
  const filteredColumns:any = mediadores.includes(data?.me?.calculatedCategory)
    ? columns.filter((c: any) => c.accessor !== 'comparative') : columns;

  useEffect(() => {
    if (!isFetching && !isFetchingGpr) {
      setFilters({
        temporalidad: [],
        rappelType: [],
        year: [],
        status: [],
      });
      refetchProvisionalAmount();
    }
  }, [isFetching, isFetchingGpr, globalFilter.dateRange]);

  useEffect(() => {
    refetch();
    if (!isFetching) {
      refetchChart();
      refetchProvisionalAmount();
    }
  }, [globalFilter.dateRange,
    globalFilter.grouping,
    globalFilter.subgrouping,
    globalFilter.status]);

  useEffect(() => {
    let newDataRange = '';

    switch (searchParams.get('timeframe')) {
    case '1T':
      newDataRange = `TRIMESTRAL,${currentYear}-01-01,${currentYear}-03-31`;
      break;
    case '2T':
      newDataRange = `TRIMESTRAL,${currentYear}-04-01,${currentYear}-06-30`;
      break;
    case '3T':
      newDataRange = `TRIMESTRAL,${currentYear}-07-01,${currentYear}-09-30`;
      break;
    case '4T':
      newDataRange = `TRIMESTRAL,${currentYear}-10-01,${currentYear}-12-31`;
      break;
    case 'Anual':
      newDataRange = 'ANUAL';
      break;
    case 'Semanal':
      newDataRange = 'SEMANAL';
      break;
    case 'Quincenal':
      newDataRange = 'QUINCENAL';
      break;
    case 'Bimestral':
      newDataRange = 'BIMESTRAL';
      break;
    case 'Cuatrimestral':
      newDataRange = 'CUATRIMESTRAL';
      break;
    default:
      break;
    }

    setTimeout(
      () => {
        setGlobalFilter({
          ...globalFilter,
          dateRange: newDataRange,
        });
      },
      1200,
    );
  }, [location]);

  if (error) {
    return <GenericError />;
  }

  return (
    <Container>
      <ImageBanner img={rappelBannerImage} />
      <ButtonBack onClick={() => navigate('/global-position/summary')} type="button">Volver</ButtonBack>
      <Typography color={Theme.colorRappel} size="32px" margin="0 0 2rem" display="block" align="center" weight="500">Rappeles</Typography>

      {Object.getOwnPropertyNames(filters).length > 0 ? (
        <GroupFilterGeneral
          selectedFilters={filters}
          filter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      ) : <FilterFormSkeleton countFilters={3} buttons={false} />}

      {(!isFetchingGpr && scoreRappel.some((item:any) => item.score.length > 0)) ? (
        <Score score={scoreRappel} cols={scoreRappel.length} maxWidth="306px" />
      ) : (<SkeletonCard cols={scoreRappel.length} maxWidth="306px" />)}

      {(processPlan?.length > 0) ? (
        <CardDataExportXLSX
          headers={columnsCSV}
          data={processPlan}
          name="rappels_global_position"
        />
      ) : (<div style={{ height: '53px' }} />)}

      {(!isFetching && data) ? (
        <Table
          columns={filteredColumns}
          data={processPlan}
          theme={Theme.colorRappel}
          renderRowSubComponent={renderRowSubComponent}
        />
      ) : <TableSkeleton countCell={6} theme={Theme.colorRappel} />}
    </Container>
  );
}
