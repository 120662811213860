import { gql } from 'graphql-request';

const GET_CURRENT_USER_DINAMIZATION_GQL = gql`
query MyQuery($year: Int) {
  meDinamizations(year: $year) {
    quarterly
  }
}
`;
export default GET_CURRENT_USER_DINAMIZATION_GQL;
