import { IIcon } from './Iicon';

export default function IconRoundedEqual({ color, height, width }: IIcon) {
  return (
    <svg width={width} height={height} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.33301 16C1.33301 7.71573 8.04874 1 16.333 1C24.6173 1 31.333 7.71573 31.333 16C31.333 24.2843 24.6173 31 16.333 31C8.04874 31 1.33301 24.2843 1.33301 16Z" stroke={color} strokeWidth="2" />
      <path d="M9.33301 13L9.33301 11L23.333 11L23.333 13L9.33301 13Z" fill={color} />
      <path d="M9.33301 21L9.33301 19L23.333 19L23.333 21L9.33301 21Z" fill={color} />
    </svg>
  );
}
