import { useState } from 'react';
import Theme from '../../../styles/Theme';
import RappelInfo from '../../organisms/Dinamizations/RappelInfo';
import RappelHeader from '../../organisms/Dinamizations/RappelHeader';
import DinamizationButtons from '../../organisms/Dinamizations/DinamizationButtons';
import { GeneralRappelStyled } from '../../organisms/Dinamizations/StyledDinamizations';

export default function RappelDinamization(props:any) {
  /* eslint-disable react/destructuring-assignment */
  const { data } = props;
  const rappelNames = data?.products?.map((prod:any) => prod.title);
  const [rappelSelected, setRappelSelected] = useState<any>(rappelNames[0]);

  return (
    <GeneralRappelStyled>
      {rappelNames.length > 0 && (
        <>
          <RappelHeader title={data?.info?.title} value={data?.info?.total_amount} />
          <DinamizationButtons
            buttons={rappelNames}
            selected={rappelSelected}
            setSelected={setRappelSelected}
            color={Theme.colorRappelDinamizations}
          />
          <RappelInfo data={data?.products.find((prod:any) => (prod?.title === rappelSelected))} />
        </>
      )}
    </GeneralRappelStyled>
  );
}
