import {
  BigButton,
  BigButtonTitle,
  BigButtonSubtitle,
} from './StyledDinamizations';

export default function DinamizationBigButton(props: any) {
  const {
    title,
    subtitle,
    selected,
    onClick,
    color,
  } = props;

  return (
    <BigButton
      selected={selected}
      color={color}
      onClick={() => onClick()}
    >
      { subtitle && (
        <BigButtonSubtitle
          selected={selected}
          color={color}
        >
          {subtitle}
        </BigButtonSubtitle>
      )}
      <BigButtonTitle
        selected={selected}
        color={color}
        hasSubtitle={!!subtitle}
      >
        {title}
      </BigButtonTitle>
    </BigButton>
  );
}
