import {
  CardRankingStyled,
  CardRankingTitleContainer,
  CardRankingNumber,
  CardRankingTitle,
  CardRankingSubtitle,
  CardRankingContent,
} from './StyledDinamizations';

export default function CardRanking({
  number = '',
  title = '',
  subtitle = '',
  children = '',
}: any) {
  return (
    <CardRankingStyled>
      <CardRankingTitleContainer hasSubtitle={subtitle !== ''}>
        <CardRankingNumber>
          {number}
        </CardRankingNumber>
        <CardRankingTitle>
          {title}
          <CardRankingSubtitle>
            <br />
            {subtitle}
          </CardRankingSubtitle>
        </CardRankingTitle>
      </CardRankingTitleContainer>
      <CardRankingContent>
        {children}
      </CardRankingContent>
    </CardRankingStyled>
  );
}
