import { useState } from 'react';
import Theme from '../../../styles/Theme';
import RappelDelegatesInfo from '../../organisms/Dinamizations/RappelDelegatesInfo';
import DinamizationButtons from '../../organisms/Dinamizations/DinamizationButtons';
import { GeneralRappelStyled } from '../../organisms/Dinamizations/StyledDinamizations';

export default function RappelDelegatesDinamization(props:any) {
  /* eslint-disable react/destructuring-assignment */
  const { data } = props;
  const rappelNames = data?.products?.map((prod:any) => prod.title);
  const [rappelSelected, setRappelSelected] = useState<any>(rappelNames ? rappelNames[0] : null);

  return (
    <GeneralRappelStyled>
      {data && rappelNames.length > 0 && (
        <>
          <DinamizationButtons
            buttons={rappelNames}
            selected={rappelSelected}
            setSelected={setRappelSelected}
            color={Theme.colorRappelDinamizations}
          />
          <RappelDelegatesInfo
            data={data?.products?.find((prod:any) => (prod?.title === rappelSelected))}
          />
        </>
      )}
    </GeneralRappelStyled>
  );
}
