import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { IFilter } from '../../../interfaces/IFilter.d';
import { IPlan } from '../../../interfaces/IPlan.d';
import Theme from '../../../styles/Theme';
import GenericError from '../Error/GenericError';
import Container from '../../atoms/Container/Container';
import { LinkTable, Table } from '../../atoms/Table/Table';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import FilterModel from '../../molecules/Filter/FilterModel';
import MonthsLine from '../../molecules/MonthsLine/MonthsLine';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import useYear from '../../../hooks/useYear';
import useFormatDate from '../../../hooks/useDate';
import useRemoveUndefinedProperties from '../../../hooks/useRemoveUndefinedProperties';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_PLANS_BY_TYPE_GQL from '../../../graphql/queries/getPlansByTypeGql';

export default function CampaignCalendar() {
  const graphQLClient = createGraphQLClient();
  const { year } = useYear();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const selectedFilters: IFilter = { temporalidad: [], campaignType: [], year: [] };

  const campaignParams:any = {
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0])
      ? globalFilter.dateRange.split(',')[0].toString() : undefined,
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1])
      ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2])
      ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    grouping: globalFilter.grouping || undefined,
    subgrouping: globalFilter.subgrouping || undefined,
    type: 'Campaign',
    year,
  };

  useRemoveUndefinedProperties(campaignParams);

  const {
    data: planCampaign,
    error: errorCampaign,
    refetch: refetchCampaign,
    isLoading: isLoadingCampaign,
    isFetching: isFetchingCampaign,
  } = useQuery(
    ['CampaignCalendar', campaignParams],
    async () => (graphQLClient && graphQLClient.request(GET_PLANS_BY_TYPE_GQL, campaignParams)),
  );

  const dataCampaign = planCampaign?.planByType?.nodes?.map((plan: IPlan) => ({
    plan: plan?.description,
    startDate: plan?.startDate && useFormatDate(plan?.startDate),
    endDate: plan?.endDate && useFormatDate(plan?.endDate),
    detail: plan?.basesUrl === '#' ? <Container>No disponible</Container> : <LinkTable url={plan?.basesUrl} title="Descargar" />,
    grouping: plan?.grouping?.name,
  }));

  useEffect(() => {
    refetchCampaign();
  }, [globalFilter.dateRange, globalFilter.grouping, globalFilter.subgrouping]);

  if (errorCampaign) {
    return <GenericError />;
  }

  const columns = [
    { Header: 'Campañas', accessor: 'plan' },
    { Header: 'Agrupación', accessor: 'grouping' },
    { Header: 'Fecha inicio', accessor: 'startDate' },
    { Header: 'Fecha fin', accessor: 'endDate' },
    { Header: 'Detalle', accessor: 'detail' },
  ];

  return (
    <Container>
      <GroupFilterGeneral
        selectedFilters={selectedFilters}
        filter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <MonthsLine
        dateRange={globalFilter.dateRange}
      />
      {(
        !isLoadingCampaign
        && !isFetchingCampaign
        && columns
        && dataCampaign) ? (
          <Table
            columns={columns}
            data={dataCampaign}
            theme={Theme.colorCampaign}
            className="tb__calendar"
          />
        ) : <TableSkeleton countCell={6} theme={Theme.colorCampaign} />}
    </Container>
  );
}
