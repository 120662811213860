import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import Theme from '../../../styles/Theme';
import LinkTo from '../../atoms/Link/LinkTo';
import { Table } from '../../atoms/Table/Table';
import Loading from '../../atoms/Loading/Loading';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import useYear from '../../../hooks/useYear';
import useFormatDate from '../../../hooks/useDate';
import useCsvNumber from '../../../hooks/useCsvNumber';
import useProcessData from '../../../hooks/useProcessData';
import useNumberFormat from '../../../hooks/useNumberFormat';
import useFilterCsvColumns from '../../../hooks/useFilterCsvColumns';
import useParseToCsvColumns from '../../../hooks/useParseToCsvColumns';
import useMappingColumnNames from '../../../hooks/useMappingColumnNames';
import useRemoveUndefinedProperties from '../../../hooks/useRemoveUndefinedProperties';
import GenericError from '../Error/GenericError';
import FilterModel from '../../molecules/Filter/FilterModel';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_PLAN_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getPlanResultsByCodeGql';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import GET_COMPARATIVE_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getComparativeResultsByCodeGql';

export interface PlanResultsByType {
  description: string,
  code: string,
  startDate:Date,
  endDate: Date,
  grouping: string,
  timeframe: string,
  translations: string,
  updatedAt: string,
  results: { nodes: IResult[] },
}

export interface IResult {
  amount: number,
  category: string,
  currency: string,
  dgt: string,
  dt: string,
  od: string,
  fulfills: boolean,
  nif: string,
  ranking: number,
  rankingGroup: number,
  status: any,
  points: string,
  updateDate: string,
  updatedAt: string,
  wins: any,
  extraFields: any,
  territoryNameDgt: string,
  territoryNameDt: string,
  territoryNameOd: string,
  firstName: string,
  lastName: string,
  valort1: string,
  valort2: string,
  valort3: string,
  valort4: string,
  valorAnual: string,
  valorTotal: string,
  dataDate: string,
  puestoId: string,
}

export default function RappelGPComparative() {
  const { id } = useParams();
  const { year } = useYear();
  const navigate = useNavigate();
  const graphQLClient = createGraphQLClient();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());

  const {
    data: dataPlan,
    error: errorPlan,
    isLoading: isLoadingPlan,
  } = useQuery(
    ['RappelResultByCode', { code: id, numberElements: 10, numberPage: 1 }],
    async () => (graphQLClient && graphQLClient.request(
      GET_PLAN_RESULTS_BY_CODE_GQL,
      { code: id, numberElements: 10, numberPage: 1 },
    )),
  );

  const variables:any = {
    code: id,
    category: globalFilter.category || undefined,
    dgt: globalFilter.dgt || undefined,
    dt: globalFilter.dt || undefined,
    od: globalFilter.od || undefined,
    firstName: globalFilter.firstName || undefined,
    lastName: globalFilter.lastName || undefined,
  };
  useRemoveUndefinedProperties(variables);

  const {
    data, error, isLoading, refetch,
  } = useQuery(
    ['RappelGPComparative', variables],
    async () => (graphQLClient && graphQLClient.request(
      GET_COMPARATIVE_RESULTS_BY_CODE_GQL,
      variables,
    )),
  );

  const grouping = dataPlan?.planByCode?.grouping?.name;
  const variablesFilters = {
    grouping,
    type: 'Rappel',
    code: id,
    year,
  };

  const {
    data: dataFilters,
    error: errorData,
    isLoading: isLoadingData,
  } = useQuery(
    ['RappelFilters', variablesFilters],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      variablesFilters,
    )),
  );

  useEffect(() => {
    refetch();
  }, [globalFilter]);

  if (isLoading || isLoadingPlan || isLoadingData) {
    return (
      <Container>
        <Loading
          background="transparent"
          loadingColor="#ccc"
          message="Espere un momento por favor"
        />
      </Container>
    );
  }

  if (error || errorPlan || errorData) {
    return <GenericError />;
  }

  const translations = JSON.parse(data?.comparativeResultsByCode?.translations);

  const formatColumn = (extraField: string) => {
    if (translations[extraField]) {
      if (translations[extraField]?.includes('(decimales)')) {
        return translations[extraField]?.replace(/\(decimales\)/gi, '');
      }
      if (translations[extraField]?.includes('porcentaje')) {
        return translations[extraField]?.replace(/\(porcentaje\)/gi, '');
      }
      return translations[extraField];
    }
    return extraField;
  };

  const formatValue = (fieldValue: any, col: string) => {
    if (typeof (fieldValue) === 'number') {
      const colInTranslations = Object.values(translations).includes(col);
      const trValues = Object.values(translations);
      const translationsWithoutSpaces: any = {};
      if (typeof translations !== 'undefined') {
        Object.entries(translations)?.forEach(([keyT, valueT]) => {
          translationsWithoutSpaces[keyT.replace(/ /g, '')] = `${valueT}`.replace(/ /g, '');
        });
      }

      if (typeof fieldValue === 'number') {
        if (translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(decimales)')
          || translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(porcentaje)')
        ) {
          if (translationsWithoutSpaces[col.replace(/ /g, '')].includes('(porcentaje)')) {
            return `${useNumberFormat(fieldValue * 100, true)} %`;
          }
          return `${useNumberFormat(fieldValue, true)}`;
        }
      }

      if (colInTranslations
        && (`${trValues.find((e:any) => e === col)}`?.includes('(decimales)')
        || translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(porcentaje)'))) {
        if (`${trValues.find((e:any) => e === col)}`?.includes('%')) {
          return `${useNumberFormat(fieldValue * 100, true)} %`;
        }
        return `${useNumberFormat(fieldValue, true)}`;
      }
      return useNumberFormat(fieldValue, false);
    }
    return fieldValue;
  };

  let myDataWithExtras:any = {};
  const dataMyResultsExtraFields:any = {};
  const myDataHash:any = data?.comparativeResultsByCode?.myOwnResult;
  const myData:any = {
    dgt: myDataHash?.territoryNameDgt ? `${myDataHash?.dgt} - ${myDataHash?.territoryNameDgt}` : myDataHash?.dgt,
    dt: myDataHash?.territoryNameDt ? `${myDataHash?.dt} - ${myDataHash?.territoryNameDt}` : myDataHash?.dt,
    od: myDataHash?.territoryNameOd ? `${myDataHash?.od} - ${myDataHash?.territoryNameOd}` : myDataHash?.od,
    denomination: `${myDataHash?.firstName} ${myDataHash?.lastName}`,
    nif: myDataHash?.nif,
    extraFields: myDataHash?.extraFields,
    dataDate: myDataHash?.dataDate,
    detail: <LinkTo
      to={`/global-position/rappel/${myDataHash?.id}/detail/${myDataHash?.firstName}-${myDataHash?.lastName}?code=${dataPlan?.planByCode?.code}&participantId=${Number(myDataHash?.userId)}`}
      color={Theme.colorPrimary}
      style={{ textAlign: 'center', display: 'block' }}
    >
      Ver detalle
      {/* eslint-disable-next-line react/jsx-indent, react/jsx-closing-tag-location */ }
    </LinkTo>,
  };

  if (myData && myData?.extraFields) {
    Object.keys(myData?.extraFields).filter((c: any) => !['DGT', 'DT', 'OD'].includes(c)).map((k:string) => {
      if (k === 'FECHA ACT') {
        if (myData?.dataDate) {
          dataMyResultsExtraFields['FECHA DATO'] = useFormatDate(myData?.dataDate);
        } else {
          dataMyResultsExtraFields['FECHA DATO'] = useFormatDate(myData?.extraFields[k]);
        }
      } else {
        dataMyResultsExtraFields[k.split('.').join('')] = formatValue(myData?.extraFields[k], k.replace('Objetivo', 'Ppto NP').replace('Presupuesto', '% Presupuesto'));
      }
      return dataMyResultsExtraFields;
    });

    myDataWithExtras = { ...myData, ...dataMyResultsExtraFields };
  }

  const dataResults = data?.comparativeResultsByCode?.otherResults?.nodes?.map((r:IResult) => ({
    dgt: r.territoryNameDgt ? `${r.dgt} - ${r?.territoryNameDgt}` : r.dgt,
    dt: r.territoryNameDt ? `${r.dt} - ${r.territoryNameDt}` : r.dt,
    od: r.territoryNameOd ? `${r.od} - ${r.territoryNameOd}` : r.od,
    denomination: `${r.firstName} ${r.lastName}`,
    nif: r?.nif,
    extraFields: r?.extraFields,
    dataDate: r?.dataDate,
    puestoId: r?.puestoId,
  }));

  let dataResultsExtraFields:any = {};
  const dataWithExtras:any = [];
  dataResults?.map((res:any) => {
    dataResultsExtraFields = {};
    Object.keys(res?.extraFields).filter((c: any) => !['DGT', 'DT', 'OD'].includes(c)).map((k:string) => {
      if (k === 'FECHA ACT') {
        if (res?.dataDate) {
          dataResultsExtraFields['FECHA DATO'] = useFormatDate(res?.dataDate);
        } else {
          dataResultsExtraFields['FECHA DATO'] = useFormatDate(res?.extraFields[k]);
        }
      } else {
        dataResultsExtraFields[k.split('.').join('')] = formatValue(res?.extraFields[k], k.replace('Objetivo', 'Ppto NP').replace('Presupuesto', '% Presupuesto'));
      }
      return dataResultsExtraFields;
    });
    const rowWithExtras = { ...res, ...dataResultsExtraFields };
    dataWithExtras.push(rowWithExtras);

    return res;
  });

  const orderedExtraFields:any = [];
  let totalCols:any = [];
  dataPlan?.planByCode?.planLevels?.sort((a: any, b: any) => a.order - b.order).map((pl:any) => {
    if (pl?.level === 1 && Object.keys(dataResultsExtraFields).includes(pl.field.split('.').join(''))) {
      orderedExtraFields.push({ Header: formatColumn(pl.field.split('.').join('')), accessor: pl.field.replace(/^[ \t]+|[ \t]+$/gm, '').split('.').join('') });
    }
    return orderedExtraFields;
  });

  if (dataResults.length > 0) {
    delete dataResults[0].extraFields;

    Object.keys(dataResults[0]).map((col:any) => {
      if (col !== 'dataDate') {
        totalCols.push({ Header: useMappingColumnNames(col, 'rappel'), accessor: col.replace(/^[ \t]+|[ \t]+$/gm, '') });
      }
      return totalCols;
    });

    totalCols = [totalCols, orderedExtraFields].flat();

    if (dataPlan?.planByCode?.planLevels?.map(
      (e:any) => e.level,
    ).sort((a:any, b:any) => b - a)[0] > 1) {
      totalCols.push({ Header: 'Detalle', accessor: 'detail' });
    }
  }

  const uniqueColumns = totalCols.reduce((acc: any, current: any) => {
    const x = acc.find((item: any) => item.accessor === current.accessor);

    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);

  const totalColumnsComparative = Array.from(new Map(uniqueColumns.map((item: any) => (
    [item.Header, item]))).values());

  const totalColsCsv: any = uniqueColumns;

  const { columns: totalColumCsv } = useParseToCsvColumns(totalColsCsv);

  // Filtering csv cols
  const totalColumnsCsvComparative = useFilterCsvColumns(
    totalColumCsv,
    dataWithExtras,
  );
  // Fin filtering csv cols

  const processMyDataCsv = [myDataWithExtras].map((d: any) => ({ ...d }));
  processMyDataCsv.forEach((item: any, index: number) => {
    Object.entries(item).forEach(([key, value]: any) => {
      const decimals = false;
      processMyDataCsv[index][key] = useCsvNumber(value, decimals);
    });
  });

  const processDataCsv = dataWithExtras.map((d: any) => ({ ...d }));
  processDataCsv.forEach((item: any, index: number) => {
    Object.entries(item).forEach(([key, value]: any) => {
      const decimals = false;
      processDataCsv[index][key] = useCsvNumber(value, decimals);
    });
  });
  const processMyDataWithExtras = useProcessData([myDataWithExtras]);
  const processDataWithExtras = useProcessData(dataWithExtras);
  const totalAllColumnsComparative = totalColumnsComparative.filter((e:any) => e.accessor !== 'detail');

  return (
    <Container>
      <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>
      <Typography
        align="center"
        color={Theme.colorRappel}
        display="block"
        size="28px"
        margin="2rem auto"
        weight="500"
      >
        {dataPlan?.planByCode?.description}
      </Typography>
      {(totalColumnsComparative && myDataWithExtras) ? (
        <>
          <FilterForm
            dataFilters={dataFilters}
            filter={globalFilter}
            setFilter={setGlobalFilter}
            type="Rappel"
            grouping={grouping}
          />
          <Typography
            align="center"
            color={Theme.colorRappel}
            display="block"
            size="20px"
            margin="2rem auto"
            weight="500"
          >
            {`Datos de ${Object.keys(myDataWithExtras).length > 0 ? myDataWithExtras?.denomination : ''}`}
          </Typography>
          {totalColumnsCsvComparative.length > 0 && [myDataWithExtras].length > 0 && (
            <CardDataExportXLSX
              headers={totalColumnsCsvComparative}
              data={processMyDataCsv}
              name="rappel_global_position_current_user"
            />
          )}
          <Table
            columns={totalColumnsComparative}
            data={processMyDataWithExtras}
            theme={Theme.colorRappel}
          />
        </>
      ) : 'Sin datos propios en esta campaña'}
      <br />
      {(totalCols && dataWithExtras) ? (
        <>
          <Typography
            align="center"
            color={Theme.colorRappel}
            display="block"
            size="20px"
            margin="2rem auto"
            weight="500"
          >
            {`Datos ${dataPlan?.planByCode?.description}`}
          </Typography>
          <CardDataExportXLSX
            headers={totalColumnsCsvComparative}
            data={processDataCsv}
            name="rappel_global_position_comparative"
          />
          <Table
            columns={totalAllColumnsComparative}
            data={processDataWithExtras}
            theme={Theme.colorRappel}
          />
        </>
      ) : ''}
    </Container>
  );
}
