import { useQuery } from 'react-query';
import { Table } from './Table';
import Container, { ContainerRight, StyledContainerIntoContainer } from '../Container/Container';
import Loading from '../Loading/Loading';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import useNumberFormat from '../../../hooks/useNumberFormat';
import useProcessDataCSV from '../../../hooks/useProcessDataCSV';
import GenericError from '../../pages/Error/GenericError';
import { getGraphQLClient } from '../../../graphql/graphqlClient';
import GET_MULTI_DATA from '../../../graphql/queries/getMultiDataGql';

export interface ITableResult{
  resultId: any;
  parent: number;
}

export default function TableResults({ resultId, parent }: ITableResult) {
  const isMpg = window.location.pathname.includes('global-position');
  const graphQLClient = getGraphQLClient();

  const {
    data: multiData,
    isLoading: isLoadingMultiData,
    error: errorMultiData,
  } = useQuery(
    ['tableResults', resultId],
    async () => (
      graphQLClient && graphQLClient.request(
        GET_MULTI_DATA,
        {
          resultId,
          myTeam: !isMpg,
        },
      )),
    {
      staleTime: Infinity,
    },
  );

  let multiDataSelected:any = multiData
    ?.getMultiData
    ?.groupedResult?.find((a: any) => a.level === 2);

  // Para el caso de Mi posición global, donde siempre muestra el resultado en un subnivel
  if (isMpg) {
    multiDataSelected = multiData
      ?.getMultiData
      ?.groupedResult?.find((a: any) => a.level === 1);
  }

  let totalColsFiltered: any = [];
  let dataLevelFormatted:any = {};

  if (multiDataSelected) {
    if (multiDataSelected?.has_grouping === true) {
      const arrayGroupedCols = Object.keys(multiDataSelected?.result)?.map((res: any) => {
        if (multiDataSelected?.result[res].length > 0) {
          return Object.keys(multiDataSelected?.result[res][0]);
        }
        return undefined;
      });

      const formattedCols = arrayGroupedCols.flat()?.map((column: any) => ({
        Header: column.replaceAll('(porcentaje)', '').replaceAll('(decimales)', ''),
        accessor: column.split('.').join(''),
      }));
      const uniqueColumns = formattedCols.reduce((acc: any, current: any) => {
        const x = acc.find((item: any) => item.accessor.split('.').join('') === current.accessor.split('.').join(''));

        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, []);

      const colsFilterDetail = uniqueColumns.filter((column: any) => column.Header !== 'groupedItem');
      totalColsFiltered = [{ Header: 'Agrupación', accessor: 'groupedItem' }, ...colsFilterDetail];
    } else {
      totalColsFiltered = Object?.keys(multiDataSelected?.result)?.map((column: any) => ({
        Header: column.replaceAll('(porcentaje)', '').replaceAll('(decimales)', ''),
        accessor: column.split('.').join(''),
      })).filter((column: any) => (column.Header !== 'Cumple Rentabilidad'));
    }

    if (multiDataSelected?.has_grouping === true) {
      const arrayGroupedItems = Object.keys(multiDataSelected?.result)?.map((res: any) => {
        if (multiDataSelected?.result[res].length > 0) {
          return multiDataSelected?.result[res];
        }
        return undefined;
      });

      dataLevelFormatted = Object.keys(arrayGroupedItems.flat()).map((key: any) => {
        const formattedGroupedData: any = {};
        Object.keys(arrayGroupedItems.flat()[key])?.map((key2: any) => {
          const currentValue = arrayGroupedItems.flat()[key][key2];
          const decimals = key2?.includes('(porcentaje)') || key2?.includes('(decimales)');
          formattedGroupedData[key2.split('.').join('')] = (!Number.isNaN(currentValue) && currentValue !== null && currentValue !== undefined && currentValue !== '')
            ? ((key2?.includes('(porcentaje)')) ? `${useNumberFormat(currentValue * 100, decimals)} %` : currentValue)
            : currentValue;
          return formattedGroupedData;
        });
        return formattedGroupedData;
      });
    } else {
      Object.keys(multiDataSelected?.result)?.map((key: any) => {
        const currentValue = multiDataSelected?.result[key];
        const decimals = key?.includes('(porcentaje)') || key?.includes('(decimales)');
        if (!Number.isNaN(currentValue) && currentValue !== null && currentValue !== undefined && currentValue !== '') {
          if (key?.includes('(porcentaje)')) {
            if (typeof currentValue === 'number') {
              const formattedValue = useNumberFormat(currentValue * 100, decimals);
              dataLevelFormatted[key.split('.').join('')] = `${formattedValue} %`;
              return formattedValue;
            }
          } else if (typeof currentValue === 'number') {
            const formattedValue = useNumberFormat(currentValue, decimals);
            dataLevelFormatted[key.split('.').join('')] = formattedValue;
            return formattedValue;
          }
        }
        dataLevelFormatted[key.split('.').join('')] = currentValue;
        return currentValue;
      });

      if (dataLevelFormatted.OD?.length === undefined || dataLevelFormatted.OD?.length === '') {
        delete dataLevelFormatted.OD;
      }
      dataLevelFormatted = [dataLevelFormatted];
    }
  }

  if (isLoadingMultiData) {
    return (
      <Container>
        <Loading
          background="transparent"
          loadingColor="#ccc"
          message="Espere un momento por favor"
        />
      </Container>
    );
  }

  if (errorMultiData) {
    return <GenericError />;
  }

  const location = window.location.pathname;

  const processCols = totalColsFiltered.map((c:any) => ({ label: c.Header, key: c.accessor }));

  const processData = useProcessDataCSV(dataLevelFormatted);

  return (
    <ContainerRight>
      { location?.includes('rappel') || location?.includes('convention') ? (
        <CardDataExportXLSX
          headers={processCols}
          data={processData}
          name="result_sub_table"
        />
      ) : null}
      <StyledContainerIntoContainer>
        <Table
          columns={totalColsFiltered}
          data={dataLevelFormatted}
          isScrollingSubtable={parent < totalColsFiltered.length}
          isSubTable
        />
      </StyledContainerIntoContainer>
    </ContainerRight>
  );
}
