import Container from '../../atoms/Container/Container';
import {
  DinamizationContainer,
  DinamizationHeaderContainer,
  DinamizationHeaderButtonsContainer,
  DinamizationContent,
  SkeletonCustom,
  CampaignHeaderCardsContainer,
  ContainerItems,
  CampaignCards,
} from '../../organisms/Dinamizations/StyledDinamizations';

export default function SkeletonDinamizations() {
  return (
    <Container>
      <DinamizationContainer>
        <DinamizationHeaderContainer>
          <DinamizationHeaderButtonsContainer>
            <SkeletonCustom width="230px" height="110px" borderRadius="30px" />
            <SkeletonCustom width="200px" height="110px" borderRadius="30px" />
          </DinamizationHeaderButtonsContainer>
          <SkeletonCustom width="250px" height="30px" margin="auto 0 auto auto" />
        </DinamizationHeaderContainer>
        <DinamizationContent>
          <SkeletonCustom width="190px" height="25px" margin="0 0 8px 0" />
          <SkeletonCustom width="255px" height="55px" margin="0" />
          <SkeletonCustom width="100%" height="65px" margin="20px auto" borderRadius="20px 20px 0 0" />
          <CampaignHeaderCardsContainer>
            <ContainerItems>
              <SkeletonCustom width="100px" height="90px" margin="0 3px 0 16px" borderRadius="20px" />
              <SkeletonCustom width="260px" height="90px" margin="0" borderRadius="20px" />
            </ContainerItems>
            <ContainerItems>
              <SkeletonCustom width="230px" height="90px" margin="0" borderRadius="20px" />
              <SkeletonCustom width="230px" height="90px" margin="0 16px 0 3px" borderRadius="20px" />
            </ContainerItems>
          </CampaignHeaderCardsContainer>
          <CampaignCards>
            <SkeletonCustom width="30%" height="230px" margin="0 0 0 16px" borderRadius="20px" />
            <SkeletonCustom width="30%" height="230px" margin="0" borderRadius="20px" />
            <SkeletonCustom width="30%" height="230px" margin="0 16px 0 0" borderRadius="20px" />
          </CampaignCards>
        </DinamizationContent>
      </DinamizationContainer>
    </Container>
  );
}
