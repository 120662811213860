import useYear from '../../../hooks/useYear';
import IconPlus from '../Icon/IconPlus';
import imgRedVidaSummary2025 from '../../../assets/images/red_vida_summary_2025.png';
import imgCorredoresSummary2025 from '../../../assets/images/corredores_summary_2025.png';
import imgAutomocionSummary2025 from '../../../assets/images/automocion_summary_2025.png';
import imgRedAgencialSummary2025 from '../../../assets/images/red_agencial_summary_2025.png';
import {
  CardBoxListImagesContainer2025,
  CardBoxItemImageSummary2025,
  CardBoxItemImageImg2025,
  CardBoxImageNameSummary2025,
  CardBoxItem2025,
  CardBoxItemLinkSummary2025,
} from './StyledCardBox';

type RankingImages = {
  id: number,
  name: string,
  image: string,
  soon: boolean,
  link?: string,
}

const rankingImages: any = {
  2025: [{
    id: 0,
    name: 'Red Agencial',
    image: imgRedAgencialSummary2025,
    soon: false,
    link: 'https://app.powerbi.com/links/0Mc5Km1nc2?ctid=5cc6c66d-ffb2-469f-9385-cda840e57836&pbi_source=linkShare',
  },
  {
    id: 1,
    name: 'Red Vida',
    image: imgRedVidaSummary2025,
    soon: true,
    link: '',
  },
  {
    id: 2,
    name: 'Automoción',
    image: imgAutomocionSummary2025,
    soon: true,
    link: '',
  },
  {
    id: 3,
    name: 'Corredores',
    image: imgCorredoresSummary2025,
    soon: true,
    link: '',
  }],
};

export default function CardBoxImagesListSummary() {
  const { year } = useYear();

  return (
    <div>
      {year >= 2025 && (
        <CardBoxListImagesContainer2025>
          {rankingImages[year].map((item: RankingImages) => (
            <CardBoxItem2025>
              {item.link ? (
                <a href={item.link} target="_blank" title={item.name} rel="noreferrer">
                  <CardBoxItemImageSummary2025 key={item.id} soon={item.soon}>
                    <CardBoxItemImageImg2025 src={item.image} alt={item.name} />
                    <CardBoxItemLinkSummary2025 soon={item.soon}><IconPlus color="#ffffff" width="18px" /></CardBoxItemLinkSummary2025>
                  </CardBoxItemImageSummary2025>
                  <CardBoxImageNameSummary2025>{item.name}</CardBoxImageNameSummary2025>
                </a>
              ) : (
                <div>
                  <CardBoxItemImageSummary2025 key={item.id} soon={item.soon}>
                    <CardBoxItemImageImg2025 src={item.image} alt={item.name} />
                    <CardBoxItemLinkSummary2025 soon={item.soon}><IconPlus color="#ffffff" width="18px" /></CardBoxItemLinkSummary2025>
                  </CardBoxItemImageSummary2025>
                  <CardBoxImageNameSummary2025>{item.name}</CardBoxImageNameSummary2025>
                </div>
              )}
            </CardBoxItem2025>
          ))}
        </CardBoxListImagesContainer2025>
      )}
    </div>
  );
}
