import {
  RappelHeaderCardContainer,
  RappelHeaderCardTitle,
  RappelHeaderCardAmount,
} from './StyledDinamizations';

export default function RappelHeaderCardValue({ value = '0 €' }: any) {
  return (
    <RappelHeaderCardContainer>
      <RappelHeaderCardTitle>Total provisional</RappelHeaderCardTitle>
      <RappelHeaderCardAmount>{value}</RappelHeaderCardAmount>
    </RappelHeaderCardContainer>
  );
}
