import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { columnsGrouped, columnsGroupedCsv } from './dataCampaignsTeam';
import Theme from '../../../styles/Theme';
import { Table } from '../../atoms/Table/Table';
import { Tabs, TabPanel } from '../../atoms/Tabs';
import Loading from '../../atoms/Loading/Loading';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import GenericError from '../Error/GenericError';
import useYear from '../../../hooks/useYear';
import useFormatDate from '../../../hooks/useDate';
import useProcessData from '../../../hooks/useProcessData';
import useNumberFormat from '../../../hooks/useNumberFormat';
import useMappingColumnNames from '../../../hooks/useMappingColumnNames';
import FilterModel from '../../molecules/Filter/FilterModel';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_MY_TEAM_RESULTS_GQL from '../../../graphql/queries/getMyTeamResultsGql';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import GET_PLAN_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getPlanResultsByCodeGql';
import GET_MY_TEAM_GROUPED_RESULTS_GQL from '../../../graphql/queries/getMyTeamGroupedResults';

export interface PlanResultsByType {
  description: string,
  code: string,
  startDate:Date,
  endDate: Date,
  grouping: string,
  timeframe: string,
  translations: string,
  updatedAt: string,
  results: { nodes: IResult[] },
}

export interface IResult {
  amount: number,
  category: string,
  currency: string,
  dgt: string,
  dt: string,
  od: string,
  fulfills: boolean,
  nif: string,
  ranking: number,
  rankingGroup: number,
  status: any,
  points: string,
  updateDate: string,
  updatedAt: string,
  wins: any,
  extraFields: any,
  territoryNameDgt: string,
  territoryNameDt: string,
  territoryNameOd: string,
  firstName: string,
  lastName: string,
  valort1: string,
  valort2: string,
  valort3: string,
  valort4: string,
  valorAnual: string,
  dataDate: string,
}

const tabs = [
  { id: 'resultadosNominativos', label: 'Resultados agrupados' },
  { id: 'trimestre1', label: 'TRIMESTRE 1' },
  { id: 'trimestre2', label: 'TRIMESTRE 2' },
  { id: 'trimestre3', label: 'TRIMESTRE 3' },
  { id: 'trimestre4', label: 'TRIMESTRE 4' },
];

function getDates(temporalidad: string) {
  const { year } = useYear();

  switch (temporalidad) {
  case 'trimestre1':
    return { startDate: `${year}-01-01`, endDate: `${year}-03-31` };
  case 'trimestre2':
    return { startDate: `${year}-04-01`, endDate: `${year}-06-30` };
  case 'trimestre3':
    return { startDate: `${year}-07-01`, endDate: `${year}-09-30` };
  case 'trimestre4':
    return { startDate: `${year}-10-01`, endDate: `${year}-12-31` };
  default:
    return { startDate: '', endDate: '' };
  }
}

export default function CampaignResults() {
  const type = 'Campaign';
  const { year } = useYear();
  const { grouping } = useParams();
  const graphQLClient = createGraphQLClient();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const codePlan = queryParams.get('code');
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const [activeTab, setActiveTab] = useState('resultadosNominativos');

  if (grouping) {
    const variablesFilters = grouping !== 'resumen' ? {
      grouping: grouping.toUpperCase(),
      type,
      year,
    } : { type, year };
    const timeframeData = activeTab.includes('trimestre') ? 'TRIMESTRAL' : '';
    const datesData = getDates(activeTab);
    const variables:any = {
      type,
      grouping: grouping !== 'resumen' ? grouping.toUpperCase() : '',
      timeframe: timeframeData,
      startDate: datesData?.startDate,
      endDate: datesData?.endDate,
      category: globalFilter.category ? globalFilter.category : undefined,
      dgt: globalFilter.dgt ? globalFilter.dgt : undefined,
      dt: globalFilter.dt ? globalFilter.dt : undefined,
      od: globalFilter.od ? globalFilter.od : undefined,
      firstName: globalFilter.firstName ? globalFilter.firstName : undefined,
      lastName: globalFilter.lastName ? globalFilter.lastName : undefined,
    };
    Object.keys(variables).forEach(
      (key:any) => (variables[key] === undefined || variables[key] === '') && delete variables[key],
    );

    const {
      data, error, isLoading, refetch, isFetching,
    } = useQuery(
      [type, grouping, variables],
      async () => (graphQLClient && graphQLClient.request(
        GET_MY_TEAM_RESULTS_GQL,
        variables,
      )),
    );

    const {
      data: dataGroup,
      error: errorGroup,
      isLoading: isLoadingGroup,
      refetch: refetchGroup,
      isFetching: isFetchingGroup,
    } = useQuery(
      ['CampaignGrouped', variables],
      async () => (graphQLClient && graphQLClient.request(
        GET_MY_TEAM_GROUPED_RESULTS_GQL,
        variables,
      )),
    );

    const {
      data: dataPlan, error: errorPlan, isLoading: isLoadingPlan,
    } = useQuery(
      ['CampaignPlanByCode', { code: codePlan }],
      async () => (graphQLClient && graphQLClient.request(
        GET_PLAN_RESULTS_BY_CODE_GQL,
        { code: codePlan },
      )),
    );

    const {
      data: dataFilters,
      error: errorData,
      isLoading: isLoadingData,
    } = useQuery(
      ['CampaignFilters', variablesFilters],
      async () => (graphQLClient && graphQLClient.request(
        GET_MY_TEAM_DATA_FILTERS_GQL,
        variablesFilters,
      )),
    );

    useEffect(() => {
      if (activeTab !== 'resultadosNominativos') {
        refetch();
      } else {
        refetchGroup();
      }
    }, [globalFilter, activeTab]);

    if (isLoading || isLoadingData || isLoadingGroup || isLoadingPlan) {
      return (
        <Container>
          <Loading
            background="transparent"
            loadingColor="#ccc"
            message="Espere un momento por favor"
          />
        </Container>
      );
    }

    if (error || errorData || errorGroup || errorPlan) {
      return <GenericError />;
    }

    const translations = JSON.parse(dataPlan?.planByCode?.translations);

    const formatColumn = (extraField: string) => {
      if (translations[extraField]) {
        if (translations[extraField]?.includes('(decimales)')) {
          return translations[extraField]?.replace(/\(decimales\)/gi, '');
        }
        if (translations[extraField]?.includes('porcentaje')) {
          return translations[extraField]?.replace(/\(porcentaje\)/gi, '');
        }
        return translations[extraField];
      }
      return extraField;
    };

    const formatValue = (fieldValue: any, col: string) => {
      if (typeof (fieldValue) === 'number') {
        const colInTranslations = Object.values(translations).includes(col);
        const trValues = Object.values(translations);
        const translationsWithoutSpaces: any = {};
        if (typeof translations !== 'undefined') {
          Object.entries(translations)?.forEach(([keyT, valueT]) => {
            translationsWithoutSpaces[keyT.replace(/ /g, '')] = `${valueT}`.replace(/ /g, '');
          });
        }

        if (typeof fieldValue === 'number') {
          if (translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(decimales)')
            || translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(porcentaje)')
          ) {
            if (translationsWithoutSpaces[col.replace(/ /g, '')].includes('(porcentaje)')) {
              return `${useNumberFormat(fieldValue * 100, true)} %`;
            }
            return `${useNumberFormat(fieldValue, true)}`;
          }
        }

        if (colInTranslations
          && (`${trValues.find((e:any) => e === col)}`?.includes('(decimales)')
          || translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(porcentaje)'))) {
          if (`${trValues.find((e:any) => e === col)}`?.includes('(porcentaje)')) {
            return `${useNumberFormat(fieldValue * 100, true)} %`;
          }
          return `${useNumberFormat(fieldValue, true)}`;
        }
        return useNumberFormat(fieldValue, false);
      }
      return fieldValue;
    };

    const dataResults = data?.myTeamPlansResults?.nodes?.map((r:IResult) => ({
      dgt: r.territoryNameDgt ? `${r.dgt} - ${r?.territoryNameDgt}` : r.dgt,
      dt: `${r.dt} - ${r.territoryNameDt}`,
      od: `${r.od} - ${r.territoryNameOd}`,
      denomination: `${r.firstName} ${r.lastName}`,
      nif: r?.nif,
      dataDate: r?.dataDate,
      extraFields: r?.extraFields,
    }));

    let dataResultsExtraFields:any = {};
    const dataWithExtras:any = [];
    dataResults?.map((res:any) => {
      dataResultsExtraFields = {};
      Object.keys(res?.extraFields).map((k:string) => {
        if (k === 'FECHA ACT') {
          if (res?.dataDate) {
            dataResultsExtraFields['FECHA DATO'.replace(/ /g, '')] = useFormatDate(res?.dataDate);
          } else {
            dataResultsExtraFields['FECHA DATO'.replace(/ /g, '')] = useFormatDate(res?.extraFields[k]?.split('T')[0]);
          }
        } else {
          dataResultsExtraFields[k.replace(/ /g, '').split('.').join('')] = formatValue(res?.extraFields[k], k.replace('Objetivo', 'Ppto NP').replace('Presupuesto', '% Presupuesto'));
        }
        return dataResultsExtraFields;
      });
      const rowWithExtras = { ...res, ...dataResultsExtraFields };
      dataWithExtras.push(rowWithExtras);

      return res;
    });

    const orderedExtraFields:any = [];
    const orderedExtraFieldsCsv:any = [];
    let totalCols:any = [];
    let totalColsCsv:any = [];
    dataPlan?.planByCode?.planLevels?.sort((a: any, b: any) => a.order - b.order).map((pl:any) => {
      if (Object.keys(dataResultsExtraFields).map((e:any) => e.replace(/ /g, '')).includes(pl.field.replace(/ /g, '').split('.').join(''))) {
        orderedExtraFields.push({ Header: formatColumn(pl.field.split('.').join('')), accessor: pl.field.replace(/ /g, '').split('.').join('') });
      }
      return orderedExtraFields;
    });

    if (dataResults.length > 0) {
      delete dataResults[0].extraFields;

      Object.keys(dataResults[0]).map((col:any) => {
        if (col !== 'dataDate') {
          totalCols.push({ Header: useMappingColumnNames(col, 'rappel'), accessor: col.replace(/ /g, '') });
        }
        return totalCols;
      });
      totalCols = [totalCols, orderedExtraFields].flat();
    }

    dataPlan?.planByCode?.planLevels?.sort((a: any, b: any) => a.order - b.order).map((pl:any) => {
      if (Object.keys(dataResultsExtraFields).map((e:any) => e.replace(/ /g, '')).includes(pl.field.replace(/ /g, '').split('.').join(''))) {
        orderedExtraFieldsCsv.push({ label: formatColumn(pl.field.split('.').join('')), key: pl.field.replace(/ /g, '').split('.').join('') });
      }
      return orderedExtraFieldsCsv;
    });
    delete dataResults[0].extraFields;

    Object.keys(dataResults[0]).map((col:any) => {
      totalColsCsv.push({ label: useMappingColumnNames(col, 'rappel'), key: col.replace(/ /g, '') });
      return totalColsCsv;
    });
    totalColsCsv = [totalColsCsv, orderedExtraFieldsCsv].flat();

    const dataGroupedResults = dataGroup?.myTeamPlansGroupedResults?.map((result:IResult) => ({
      dgt: result.territoryNameDgt ? `${result.dgt} - ${result.territoryNameDgt}` : result.dgt,
      dt: result.territoryNameDt ? `${result.dt} - ${result.territoryNameDt}` : result.dt,
      od: result.territoryNameOd ? `${result.od} - ${result.territoryNameOd}` : result.od,
      denomination: (result.dt || result.od) ? `${result.firstName} ${result.lastName}` : '',
      nif: result?.nif,
      category: result?.category,
      valort1: useNumberFormat(result?.valort1, true),
      valort2: useNumberFormat(result?.valort2, true),
      valort3: useNumberFormat(result?.valort3, true),
      valort4: useNumberFormat(result?.valort4, true),
      valorTotal: (
        parseFloat(result?.valorAnual)
        + parseFloat(result?.valort1)
        + parseFloat(result?.valort2)
        + parseFloat(result?.valort3)
        + parseFloat(result?.valort4)
      ),
    }));

    const cols = activeTab === 'resultadosNominativos' ? columnsGrouped : totalCols;
    const colsCsv = activeTab === 'resultadosNominativos' ? columnsGroupedCsv : totalColsCsv;
    const dataTable = activeTab === 'resultadosNominativos' ? dataGroupedResults : dataWithExtras;

    const processDataTable = useProcessData(dataTable);

    return (
      <Container>
        <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>
        <Tabs
          theme={Theme.colorPrimary}
          tabs={tabs}
          activeTab={activeTab}
          onChangeTab={setActiveTab}
        />
        <TabPanel isActivePanel id={tabs[0].id}>
          <Typography
            align="center"
            color={Theme.colorCampaign}
            display="block"
            size="28px"
            margin="2rem auto"
            weight="500"
          >
            {`Resultados Campañas ${grouping !== 'resumen' ? grouping : ''}`}
          </Typography>
          {(cols && dataTable && dataFilters) ? (
            <>
              <FilterForm
                dataFilters={dataFilters}
                filter={globalFilter}
                setFilter={setGlobalFilter}
                type={type}
                grouping={grouping}
              />
              <CardDataExportXLSX
                headers={colsCsv}
                data={dataTable}
                margin="0 0 0.5rem auto"
                name={`campaign_results_${grouping}`}
              />
              <Table
                columns={cols}
                data={processDataTable}
                theme={Theme.colorCampaign}
              />
            </>
          ) : ''}
          {isFetching === true ? (
            <Loading />
          ) : ''}
          {isFetchingGroup === true ? (
            <Loading />
          ) : ''}
        </TabPanel>
      </Container>
    );
  }
  return (
    <Container>
      <Loading
        background="transparent"
        loadingColor="#ccc"
        message="Espere un momento por favor"
      />
    </Container>
  );
}
