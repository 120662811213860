import { gql } from 'graphql-request';

const GET_TERRITORY_STATISTICS_GQL = gql`
  query MyQuery($year: Int, $month: Int) {
    territoryStatistics(year: $year, month: $month) {
      nodes {
        id
        month
        year
        monthValue
        difference
        territory {
          code
          name
        }
      }
    }
  }
`;

export default GET_TERRITORY_STATISTICS_GQL;
