import {
  useState,
  useEffect,
  Fragment,
  useRef,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { columnsSummary, headers } from './dataCampaignsTeam';
import Theme from '../../../styles/Theme';
import { IFilter } from '../../../interfaces/IFilter.d';
import { Params } from '../../../interfaces/Params.d';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import { LinkTable, Table } from '../../atoms/Table/Table';
import { Tabs, TabPanel } from '../../atoms/Tabs';
import LinkTo from '../../atoms/Link/LinkTo';
import Container from '../../atoms/Container/Container';
import ImageBanner from '../../atoms/ImageBanner/ImageBanner';
import Typography from '../../atoms/Typography/Typography';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import { Card } from '../../atoms/Card/Card';
import Score from '../../molecules/Score/Score';
import FilterModel from '../../molecules/Filter/FilterModel';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import SubscriptionButton from '../../molecules/Subscriptios/SubscriptionButton';
import GenericError from '../Error/GenericError';
import useYear from '../../../hooks/useYear';
import useNumberFormat from '../../../hooks/useNumberFormat';
import useProcessData from '../../../hooks/useProcessData';
import useFormatDate from '../../../hooks/useDate';
import useCsvNumber from '../../../hooks/useCsvNumber';
import useRemoveUndefinedProperties from '../../../hooks/useRemoveUndefinedProperties';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_TEAM_TIMEFRAME_RESULTS_GQL from '../../../graphql/queries/getTeamTimeframeResultsGql';
import GET_MY_TEAM_PLANS_BY_PARAMS_GQL from '../../../graphql/queries/getMyTeamPlansByParamsGql';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import campaignBannerImage from '../../../assets/images/campaign.jpg';

export interface TeamCampaignParams {
  id: string;
  user: any;
  category: any;
}

export default function CampaignTeam({ id, user, category }:TeamCampaignParams) {
  const type = 'Campaign';
  const { grouping } = useParams();
  const { year } = useYear();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const [activeTab, setActiveTab] = useState(grouping || id || '');
  const [activeSubtab, setActiveSubtab] = useState('');
  const selectedFilters: IFilter = { temporalidad: [] };
  const graphQLClient = createGraphQLClient();

  const userId = user?.me?.id;
  const groupingList :any = queryClient.getQueryState(['GroupingList']);
  const list = groupingList?.data?.groupingList?.grouping;
  const tabs = list && list[year] ? list[year].Campaign : [];

  const variablesFilters = activeTab !== 'resumen' ? {
    grouping: activeTab.toUpperCase(),
    subgrouping: activeSubtab !== 'resumen' ? activeSubtab?.toUpperCase() : '',
    type,
    year,
  } : {
    type,
    year,
  };

  const campaignParams: Params = {
    type,
    grouping: activeTab !== 'resumen' ? activeTab.toUpperCase() : '',
    subgrouping: activeSubtab !== 'resumen' ? activeSubtab?.toUpperCase() : '',
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    category: globalFilter.category || undefined,
    dgt: globalFilter.dgt || undefined,
    dt: globalFilter.dt || undefined,
    od: globalFilter.od || undefined,
    year,
    firstName: globalFilter.firstName || undefined,
    lastName: globalFilter.lastName || undefined,
    nif: globalFilter.nif || undefined,
    search: globalFilter.search || '',
    status: globalFilter.status || '',
  };

  useRemoveUndefinedProperties(campaignParams);

  const {
    data: dataFilters,
    error: errorFilters,
    isFetching: isFetchingFilters,
  } = useQuery(
    ['CampaignTeamFilters', variablesFilters],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      variablesFilters,
    )),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: planCampaign,
    error: errorCampaign,
    refetch: refetchCampaign,
    isLoading: isLoadingCampaign,
    isFetching: isFetchingCampaign,
    status: statusCampaign,
  } = useQuery(
    ['Campaign_Team', campaignParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_PLANS_BY_PARAMS_GQL,
      campaignParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    setGlobalFilter({ ...globalFilter, grouping: activeTab });
  }, []);

  useEffect(() => {
    navigate(`/team/campaign/${activeTab}`, { replace: true });
    setGlobalFilter({ ...globalFilter, grouping: activeTab });

    const completeActiveTab :any = tabs.find((tab: any) => tab.id === activeTab);
    if (completeActiveTab?.subgroupings) {
      setActiveSubtab(completeActiveTab?.subgroupings[0].id);
    }
  }, [activeTab]);

  useEffect(() => {
    if (statusCampaign !== 'success') {
      refetchCampaign();
    }
  }, [globalFilter]);

  const debouncedSearch = (value :any, currentGlobalFilter :FilterModel) => {
    setGlobalFilter({ ...currentGlobalFilter, search: value });
  };

  const setServicesValueDebounced = useRef(_.debounce(debouncedSearch, 1000));

  const handleChange = (e :any) => {
    setServicesValueDebounced.current(e.currentTarget.value, globalFilter);
  };

  let dataCampaign:Array<[]> = [];
  dataCampaign = planCampaign?.myTeamPlans?.nodes?.map((c:any) => ({
    globalDgt: c?.globalDgt,
    campaignName: c?.planDescription,
    participants: useNumberFormat(c?.participantsNumber),
    wins: c?.winsNumber,
    amount: useNumberFormat(c?.resultsAmount),
    dataDate: useFormatDate(c?.planDataDate),
    status: c.planStatusName,
    CampaignResults: (
      <LinkTo
        to={`/team/campaign/resumen/${c?.planCode}${category?.userCategory ? `?userCategory=${encodeURIComponent(JSON.stringify(category?.userCategory))}` : ''}`}
        color={Theme.colorPrimary}
        style={{ display: 'block', textAlign: 'center' }}
      >
        Ver resultados
      </LinkTo>
    ),
    timeframe: c?.planTimeframe,
    startDate: c?.planStartDate,
    endDate: c?.planEndDate,
    grouping: c?.planGrouping,
    subscribed: <SubscriptionButton
      subscribed={c.planSubscribed}
      planCode={c.planCode}
      userId={userId}
    />,
    download: <LinkTable url={c?.planBasesUrl} title="Descargar" />,
  }));

  const campaignsByTimeFrame = dataCampaign && dataCampaign.reduce((rv:any, x:any) => {
    let dynamicTimeFrame = '';
    if (x.timeframe === 'TRIMESTRAL') {
      if (x.startDate.split('-')[1] === '01') {
        dynamicTimeFrame = '1T';
      } else if (x.startDate.split('-')[1] === '04') {
        dynamicTimeFrame = '2T';
      } else if (x.startDate.split('-')[1] === '07') {
        dynamicTimeFrame = '3T';
      } else if (x.startDate.split('-')[1] === '10') {
        dynamicTimeFrame = '4T';
      }
    }
    if (dynamicTimeFrame.length > 0) {
      (rv[dynamicTimeFrame] = rv[dynamicTimeFrame] || []).push(x);
    } else {
      (rv[x.timeframe] = rv[x.timeframe] || []).push(x);
    }
    return rv;
  }, {});

  const orderedCamps:any = {
    '1T': campaignsByTimeFrame?.['1T'] || [],
    '2T': campaignsByTimeFrame?.['2T'] || [],
    '3T': campaignsByTimeFrame?.['3T'] || [],
    '4T': campaignsByTimeFrame?.['4T'] || [],
    Anual: campaignsByTimeFrame?.ANUAL || [],
    Semestral: campaignsByTimeFrame?.SEMESTRAL || [],
    Mensual: campaignsByTimeFrame?.MENSUAL || [],
    Interanual: campaignsByTimeFrame?.INTERANUAL || [],
    Semanal: campaignsByTimeFrame?.SEMANAL || [],
    Quincenal: campaignsByTimeFrame?.QUINCENAL || [],
    Bimestral: campaignsByTimeFrame?.BIMESTRAL || [],
    Cuatrimestral: campaignsByTimeFrame?.CUATRIMESTRAL || [],
  };

  const csvData:any = {};
  Object.keys(orderedCamps).forEach((k) => {
    csvData[k] = [];
    if (orderedCamps[k].length) {
      Object.entries(orderedCamps[k])?.forEach(([key]: any) => {
        csvData[k][key] = {};
        Object.entries(orderedCamps[k][key])?.forEach(([key2, value2]: any) => {
          csvData[k][key][key2] = useCsvNumber(value2);
        });
      });
    }
    return null;
  });

  const variablesResults = activeTab !== 'resumen' ? {
    grouping: activeTab.toUpperCase(),
    subgrouping: activeSubtab !== 'resumen' ? activeSubtab?.toUpperCase() : '',
    type,
    myTeam: true,
    status: globalFilter.status,
    year,
  } : {
    type,
    myTeam: true,
    status: globalFilter.status,
    year,
  };

  const orderedData:any = {};
  Object.keys(orderedCamps).map((k:any) => {
    const {
      data: dataGetResults,
    } = useQuery(
      ['CampaignTeamResults', { ...variablesResults, timeframe: k }],
      async () => (graphQLClient && graphQLClient.request(
        GET_TEAM_TIMEFRAME_RESULTS_GQL,
        { ...variablesResults, timeframe: k },
      )),
      {
        staleTime: Infinity,
      },
    );

    orderedData[k] = dataGetResults?.summaryTeamChartByTimeframe;
    return orderedData;
  });

  const scoreIndicators = (k: any) => ([
    {
      id: 0,
      title: 'Participa',
      subtitle: '',
      score: orderedData[k]?.participantsCount,
      icon: 'participant',
    },
    {
      id: 1,
      title: 'Ganan',
      subtitle: '',
      score: orderedData[k]?.totalUserWinCount,
      icon: 'cup',
    },
  ]);

  const sectionTitle:any = {
    '1T': 'Trimestre 1',
    '2T': 'Trimestre 2',
    '3T': 'Trimestre 3',
    '4T': 'Trimestre 4',
    Semanal: 'Semanal',
    Quincenal: 'Quincenal',
    Mensual: 'Mensual',
    Bimestral: 'Bimestral',
    Cuatrimestral: 'Cuatrimestral',
    Semestral: 'Semestral',
    Anual: 'Anual',
    Interanual: 'Interanual',
  };

  if (errorCampaign || errorFilters) {
    return <GenericError />;
  }

  return (
    <Container>
      <ImageBanner img={campaignBannerImage} />
      <Typography color={Theme.colorCampaign} size="38px" margin="0 0 0.5rem" display="block" align="center" weight="500">Campañas</Typography>
      <ButtonBack onClick={() => navigate('/team/summary/general')} type="button" style={{ marginBottom: '30px' }}>Volver</ButtonBack>
      <Card background="transparent" display="flex" alignItems="flex-start" justifyContent="space-between">
        <GroupFilterGeneral
          selectedFilters={selectedFilters}
          filter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <input
          type="text"
          placeholder="Buscar por nombre de campaña"
          onChange={handleChange}
          onPaste={handleChange}
          className="search"
          style={{ height: '38px', maxWidth: '450px', margin: '1rem 0 0' }}
        />
      </Card>

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={setActiveTab}
        activeSubtab={activeSubtab}
        onChangeSubtab={setActiveSubtab}
        theme={Theme.colorCampaign}
      />

      <TabPanel isActivePanel id="campaign-tab-panel">
        {activeTab === 'retos' && (
          <div style={{ textAlign: 'right', margin: '3rem 0 2rem' }}>
            <LinkTo
              to={`/team/campaign/results/${activeTab}?code=${planCampaign?.myTeamPlans?.nodes[0]?.planCode}`}
              background={Theme.colorCampaign}
              border="8px"
              color={Theme.colorWhite}
              padding="0.72rem 1.2rem"
              underline="none"
            >
              {`Ver resultados nominales de ${activeTab}`}
            </LinkTo>
          </div>
        )}

        {!isFetchingFilters ? (
          <FilterForm
            dataFilters={dataFilters}
            filter={globalFilter}
            setFilter={setGlobalFilter}
            type={type}
            grouping={activeTab}
            fetching={isFetchingCampaign}
          />
        ) : <FilterFormSkeleton countFilters={10} />}

        {(
          !isLoadingCampaign
            && !isFetchingCampaign
            && columnsSummary
            && dataCampaign
            && dataFilters) ? (
            Object.keys(orderedCamps).map((k:any) => (
              orderedCamps[k].length ? (
                <Fragment key={k}>
                  <Typography
                    align="center"
                    color={Theme.colorCampaign}
                    display="block"
                    size="28px"
                    margin="2rem auto"
                    weight="500"
                  >
                    {sectionTitle[k]}
                  </Typography>

                  <Card
                    background={Theme.colorWhite}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap="1rem"
                    margin="0"
                  >
                    {headers && csvData[k].length > 0 && (
                      <CardDataExportXLSX
                        headers={headers}
                        data={csvData[k]}
                        margin="0 0 0.5rem auto"
                        name={`campaigns_team_${activeTab}_${k.toLowerCase()}`}
                      />
                    )}
                  </Card>

                  <Table
                    columns={columnsSummary}
                    data={useProcessData(orderedCamps[k])}
                    theme={Theme.colorCampaign}
                  />

                  <Score score={scoreIndicators(k)} cols={2} maxWidth="260px" margin="0 auto 4rem auto" />
                </Fragment>
              ) : ''
            ))
          ) : <TableSkeleton countCell={6} theme={Theme.colorCampaign} />}
      </TabPanel>
    </Container>
  );
}
