import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import Theme from '../../../styles/Theme';
import Button from '../../atoms/Button/Button';
import { Table } from '../../atoms/Table/Table';
import { Tabs, TabPanel } from '../../atoms/Tabs';
import Loading from '../../atoms/Loading/Loading';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import { columns, columnsGrouped, columnsGroupedCsv } from './dataRappelsTeam';
import FilterModel from '../../molecules/Filter/FilterModel';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import GenericError from '../Error/GenericError';
import useYear from '../../../hooks/useYear';
import useFormatDate from '../../../hooks/useDate';
import formatValue from '../../../hooks/useFormatValue';
import formatColumn from '../../../hooks/useFormatColumn';
import useProcessData from '../../../hooks/useProcessData';
import useNumberFormat from '../../../hooks/useNumberFormat';
import useMappingColumnNames from '../../../hooks/useMappingColumnNames';
import useRemoveUndefinedProperties from '../../../hooks/useRemoveUndefinedProperties';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_MY_TEAM_RESULTS_GQL from '../../../graphql/queries/getMyTeamResultsGql';
import GET_TERRITORY_USERS_GQL from '../../../graphql/queries/getTerritoriesUsersGql';
import GET_PLAN_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getPlanResultsByCodeGql';
import GET_MY_TEAM_GROUPED_RESULTS_GQL from '../../../graphql/queries/getMyTeamGroupedResults';

export interface PlanResultsByType {
  description: string,
  code: string,
  startDate:Date,
  endDate: Date,
  grouping: string,
  timeframe: string,
  translations: string,
  updatedAt: string,
  results: { nodes: IResult[] },
}

export interface IResult {
  amount: number,
  category: string,
  currency: string,
  dgt: string,
  dt: string,
  od: string,
  fulfills: boolean,
  nif: string,
  ranking: number,
  rankingGroup: number,
  status: any,
  points: string,
  updateDate: string,
  updatedAt: string,
  wins: any,
  extraFields: any,
  territoryNameDgt: string,
  territoryNameDt: string,
  territoryNameOd: string,
  firstName: string,
  lastName: string,
  valort1: string,
  valort2: string,
  valort3: string,
  valort4: string,
  valorAnual: string,
  valorTotal: string,
  dataDate: string,
}

const tabs = [
  { id: 'resultadosNominativos', label: 'Resultados agrupados' },
  { id: 'trimestre1', label: 'TRIMESTRE 1' },
  { id: 'trimestre2', label: 'TRIMESTRE 2' },
  { id: 'trimestre3', label: 'TRIMESTRE 3' },
  { id: 'trimestre4', label: 'TRIMESTRE 4' },
  { id: 'anual', label: 'ANUAL' },
];

function getDates(temporalidad: string) {
  const { year } = useYear();

  switch (temporalidad) {
  case 'trimestre1':
    return { startDate: `${year}-01-01`, endDate: `${year}-03-31` };
  case 'trimestre2':
    return { startDate: `${year}-04-01`, endDate: `${year}-06-30` };
  case 'trimestre3':
    return { startDate: `${year}-07-01`, endDate: `${year}-09-30` };
  case 'trimestre4':
    return { startDate: `${year}-10-01`, endDate: `${year}-12-31` };
  default:
    return { startDate: '', endDate: '' };
  }
}

export default function CampaignResults() {
  const { year } = useYear();
  const { grouping } = useParams();
  const graphQLClient = createGraphQLClient();
  const queryParams = new URLSearchParams(window.location.search);
  const codePlan = queryParams.get('code');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('resultadosNominativos');
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const [clearFilter, setClearFilter] = useState<boolean>(false);

  if (grouping) {
    const variablesFilters = grouping !== 'resumen' ? {
      grouping: grouping.toUpperCase(),
      type: 'Rappel',
      year,
    } : { type: 'Rappel', year };

    const timeframeData = activeTab.includes('trimestre') ? 'TRIMESTRAL' : 'ANUAL';

    const datesData = getDates(activeTab);

    const variables:any = {
      type: 'Rappel',
      grouping: grouping !== 'resumen' ? grouping.toUpperCase() : '',
      timeframe: timeframeData,
      startDate: datesData?.startDate,
      endDate: datesData?.endDate,
      category: globalFilter.category || undefined,
      dgt: globalFilter.dgt || undefined,
      dt: globalFilter.dt || undefined,
      od: globalFilter.od || undefined,
      userId: globalFilter.nif || undefined,
      nif: globalFilter?.nif?.toString() || undefined,
      firstName: globalFilter.firstName || undefined,
      lastName: globalFilter.lastName || undefined,
    };

    useRemoveUndefinedProperties(variables);

    const {
      data, error, refetch, isFetching,
    } = useQuery(
      ['RappelResults', variables],
      async () => {
        if (!graphQLClient) {
          throw new Error('No se puede realizar la consulta, falta el token de autenticación.');
        }
        return graphQLClient.request(GET_MY_TEAM_RESULTS_GQL, variables);
      },
      {
        staleTime: Infinity,
      },
    );

    const {
      data: dataPlan, error: errorPlan,
    } = useQuery(
      ['RappelByCode', codePlan],
      async () => (graphQLClient && graphQLClient.request(
        GET_PLAN_RESULTS_BY_CODE_GQL,
        { code: codePlan },
      )),
      {
        staleTime: Infinity,
        enabled: false,
      },
    );

    const {
      data: dataGroup,
      error: errorGroup,
      refetch: refetchGroup,
      isFetching: isFetchingGroup,
    } = useQuery(
      ['RappelGrouped', variables],
      async () => (graphQLClient && graphQLClient.request(
        GET_MY_TEAM_GROUPED_RESULTS_GQL,
        variables,
      )),
      {
        staleTime: Infinity,
        enabled: false,
      },
    );

    const {
      data: dataFilters,
      refetch: refetchFilters,
      error: errorData,
    } = useQuery(
      ['ResultsTeamFilters', variablesFilters],
      async () => (graphQLClient
        && graphQLClient.request(GET_TERRITORY_USERS_GQL, variablesFilters)),
      {
        staleTime: Infinity,
      },
    );

    const {
      data: dataNifFilter,
      refetch: refetchNifFilter,
    } = useQuery(
      ['ResultsNifFilter', variables],
      async () => (graphQLClient && graphQLClient.request(GET_TERRITORY_USERS_GQL, variables)),
      {
        staleTime: Infinity,
      },
    );

    const handleClearFilters = () => {
      const clearedFilter = new FilterModel();
      setGlobalFilter(clearedFilter);
      setClearFilter(true);
    };

    useEffect(() => {
      if (clearFilter) {
        setClearFilter(true);
        refetchFilters();
        refetchNifFilter();
      }
    }, [clearFilter]);

    if (dataNifFilter && dataNifFilter.dropdownTerritoriesUsers) {
      const { arrayUsersData } = dataNifFilter.dropdownTerritoriesUsers;
      dataNifFilter.dropdownTerritoriesUsers.arrayNifs = arrayUsersData;
      delete dataNifFilter?.dropdownTerritoriesUsers?.arrayPlanTypes;
    }

    useEffect(() => {
      if (activeTab !== 'resultadosNominativos') {
        refetch();
      } else {
        refetchGroup();
      }
    }, [globalFilter, activeTab]);

    if (error || errorData || errorGroup || errorPlan) {
      return <GenericError />;
    }

    const translations = (dataPlan?.planByCode?.translations !== undefined)
      ? JSON.parse(dataPlan?.planByCode?.translations)
      : {};

    const dataResults = data?.myTeamPlansResults?.nodes?.map((r:IResult) => ({
      dgt: r.territoryNameDgt ? `${r.dgt} - ${r?.territoryNameDgt}` : r.dgt,
      dt: r.territoryNameDt ? `${r.dt} - ${r.territoryNameDt}` : r.dt,
      od: r.territoryNameOd ? `${r.od} - ${r.territoryNameOd}` : r.od,
      denomination: `${r.firstName} ${r.lastName}`,
      nif: r?.nif,
      extraFields: r?.extraFields,
      dataDate: r?.dataDate,
    }));

    let dataResultsExtraFields:any = {};
    const dataWithExtras:any = [];
    dataResults?.map((res:any) => {
      dataResultsExtraFields = {};
      Object.keys(res?.extraFields).map((k:string) => {
        if (k === 'FECHA ACT') {
          if (res?.dataDate) {
            dataResultsExtraFields['FECHA DATO'.replace(/ /g, '')] = useFormatDate(res?.dataDate);
          } else {
            dataResultsExtraFields['FECHA DATO'.replace(/ /g, '')] = useFormatDate(res?.extraFields[k]?.split('T')[0]);
          }
        } else {
          dataResultsExtraFields[k.replace(/ /g, '').split('.').join('')] = formatValue(res?.extraFields[k], k, translations);
        }
        return dataResultsExtraFields;
      });
      const rowWithExtras = { ...res, ...dataResultsExtraFields };
      dataWithExtras.push(rowWithExtras);

      return res;
    });

    const orderedExtraFields:any = [];
    const orderedExtraFieldsCsv:any = [];
    let totalCols:any = [];
    let totalColsCsv:any = [];
    dataPlan?.planByCode?.planLevels?.sort((a: any, b: any) => a.order - b.order).map((pl:any) => {
      if (Object.keys(dataResultsExtraFields).map((e:any) => e.replace(/ /g, '')).includes(pl.field.replace(/ /g, '').split('.').join(''))) {
        orderedExtraFields.push({ Header: formatColumn(pl.field.split('.').join(''), translations), accessor: pl.field.replace(/ /g, '').split('.').join('') });
      }
      return orderedExtraFields;
    });

    if (dataResults && dataResults.length > 0) {
      delete dataResults[0].extraFields;

      Object.keys(dataResults[0]).map((col:any) => {
        if (col !== 'dataDate') {
          totalCols.push({ Header: useMappingColumnNames(col, 'rappel'), accessor: col.replace(/ /g, '') });
        }
        return totalCols;
      });
      totalCols = [totalCols, orderedExtraFields].flat();
    }

    dataPlan?.planByCode?.planLevels?.sort((a: any, b: any) => a.order - b.order).map((pl:any) => {
      if (Object.keys(dataResultsExtraFields).map((e:any) => e.replace(/ /g, '')).includes(pl.field.replace(/ /g, '').split('.').join(''))) {
        orderedExtraFieldsCsv.push({ label: formatColumn(pl.field.split('.').join(''), translations), key: pl.field.replace(/ /g, '').split('.').join('') });
      }
      return orderedExtraFieldsCsv;
    });
    if (dataResults && dataResults[0]?.extraFields !== undefined) {
      delete dataResults[0]?.extraFields;
    }

    if (dataResults && dataResults.length > 0) {
      Object.keys(dataResults[0]).map((col:any) => {
        totalColsCsv.push({ label: useMappingColumnNames(col, 'rappel'), key: col.replace(/ /g, '') });
        return totalColsCsv;
      });
      totalColsCsv = [totalColsCsv, orderedExtraFieldsCsv].flat();
    }

    const dataGroupedResults = dataGroup?.myTeamPlansGroupedResults?.map((result:IResult) => ({
      dgt: result.territoryNameDgt ? `${result.dgt} - ${result.territoryNameDgt}` : result.dgt,
      dt: result.territoryNameDt ? `${result.dt} - ${result.territoryNameDt}` : result.dt,
      od: result.territoryNameOd ? `${result.od} - ${result.territoryNameOd}` : result.od,
      denomination: (result.dt || result.od) ? `${result.firstName} ${result.lastName}` : '',
      nif: (result.dt || result.od) ? result?.nif : '',
      category: result?.category,
      valort1: useNumberFormat(result?.valort1, true),
      valort2: useNumberFormat(result?.valort2, true),
      valort3: useNumberFormat(result?.valort3, true),
      valort4: useNumberFormat(result?.valort4, true),
      valorAnual: result?.valorAnual,
      valorTotal: useNumberFormat(result?.valorTotal, true),
    }));

    const cols = activeTab === 'resultadosNominativos' ? columnsGrouped : totalCols;
    const colsCsv = activeTab === 'resultadosNominativos' ? columnsGroupedCsv : totalColsCsv;
    const dataTable = activeTab === 'resultadosNominativos' ? dataGroupedResults : dataWithExtras;

    const processDataTable = useProcessData(dataTable);

    return (
      <Container>
        <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>
        <Tabs
          theme={Theme.colorRappel}
          tabs={tabs}
          activeTab={activeTab}
          onChangeTab={setActiveTab}
        />
        <TabPanel isActivePanel id={tabs[0].id}>
          <Typography
            align="center"
            color={Theme.colorRappel}
            display="block"
            size="28px"
            margin="2rem auto"
            weight="500"
          >
            {`Resultados Rappeles ${grouping !== 'resumen' ? grouping : ''}`}
          </Typography>
          {(dataFilters) ? (
            dataNifFilter?.dropdownTerritoriesUsers && (
              <FilterForm
                dataFilters={dataNifFilter.dropdownTerritoriesUsers}
                filter={globalFilter}
                setFilter={setGlobalFilter}
                type="Rappel"
                grouping={grouping}
                fetching
                autoFilter={0}
                resetFilter={clearFilter}
              />
            )
          ) : <FilterFormSkeleton countFilters={1} buttons={false} />}
          <div className="filter__button">
            <Button type="button" onClick={handleClearFilters} padding="0.5rem 1rem" size={200}>
              Limpiar filtros
            </Button>
          </div>
          {(columns && dataTable && dataFilters) ? (
            <>
              <CardDataExportXLSX
                headers={colsCsv}
                data={dataTable}
                margin="0 0 0.5rem auto"
                name={`rappels_results_${grouping}`}
              />
              <Table
                columns={cols}
                data={processDataTable}
                theme={Theme.colorRappel}
              />
            </>
          ) : ''}
          {(isFetching === true || isFetchingGroup === true) ? (
            <Loading />
          ) : ''}
        </TabPanel>
      </Container>
    );
  }
  return (
    <Container>
      <Loading
        background="transparent"
        loadingColor="#ccc"
        message="Espere un momento por favor"
      />
    </Container>
  );
}
