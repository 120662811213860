import Button from '../../atoms/Button/Button';
import { Image } from '../../atoms/Image/Image';
import Container from '../../atoms/Container/Container';
import genericErrorImg from '../../../assets/images/generic_error_img_2.png';

interface IGenericError {
  showButton?: boolean;
}

export default function GenericError(props: IGenericError) {
  const { showButton } = props;
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '30px 0 30px 0' }}>
        <Image src={genericErrorImg} />
      </div>
      {showButton && (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '50px 0' }}>
          <Button onClick={handleReload} size={100}>
            <b>Recargar Página</b>
          </Button>
        </div>
      )}
    </Container>
  );
}
GenericError.defaultProps = {
  showButton: true,
};
