import Theme from '../../../styles/Theme';
import {
  CardCampaignContainer,
  CardCampaignHeaderStyled,
  CardCampaignContainerStyled,
  CardCampaignContainerValues,
  CardCampaignAmount,
  CardCampaignPercentage,
  DinamizationText,
} from './StyledDinamizations';
import CardPoints from './CardPoints';
import RoundedPercentageGraphic from './RoundedPercentageGraphic';

export default function CardCampaign(props: any) {
  const {
    title,
    subtitle,
    amount,
    totalAmount,
    points,
    percentage,
  } = props;
  const color = Theme.colorPrimary;

  return (
    <CardCampaignContainer>
      <CardCampaignHeaderStyled>
        <DinamizationText
          fontSize="20px"
          fontWeight="700"
          color={Theme.colorWhite}
          margin="0"
        >
          {title}
        </DinamizationText>
        <DinamizationText
          fontSize="16px"
          fontWeight="700"
          color={Theme.colorWhite}
          margin="0"
        >
          {subtitle}
        </DinamizationText>
      </CardCampaignHeaderStyled>
      <CardCampaignContainerValues>
        <CardCampaignContainerStyled>
          <CardCampaignAmount>
            <b>
              {amount}
              &nbsp;/&nbsp;
            </b>
            <br />
            {totalAmount}
          </CardCampaignAmount>
          <CardPoints color={color} title="PUNTOS" value={points} />
        </CardCampaignContainerStyled>
        <CardCampaignPercentage>
          <RoundedPercentageGraphic color={color} percentageValue={percentage} width="80">
            <DinamizationText
              fontSize="16px"
              fontWeight="600"
              color={Theme.colorPrimary}
              margin="0 auto"
            >
              {percentage}
              %
            </DinamizationText>
          </RoundedPercentageGraphic>
        </CardCampaignPercentage>
      </CardCampaignContainerValues>
    </CardCampaignContainer>
  );
}
