import { IIcon } from './Iicon';

export default function IconRoundedDownArrow({ color, height, width }: IIcon) {
  return (
    <svg width={width} height={height} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.6665 16C1.6665 7.71573 8.38223 1 16.6665 1C24.9508 1 31.6665 7.71573 31.6665 16C31.6665 24.2843 24.9508 31 16.6665 31C8.38223 31 1.6665 24.2843 1.6665 16Z" stroke={color} strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.4274 20.6305L21.6168 16.4411L22.6774 17.5018L16.6685 23.5107L10.6597 17.5018L11.7203 16.4411L15.9274 20.6482V8.50684H17.4274V20.6305Z" fill={color} />
    </svg>
  );
}
