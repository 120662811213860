import {
  RappelSegmentCardStyled,
  SegmentCardStyled,
  SegmentCardHeader,
  SegmentCardContainer,
  DelegatesInfo,
  IncentiveInfo,
  FirstSegmentCard,
  SegmentCardHeaderSubtitle,
  SegmentCardHeaderSubtitleValue,
} from './StyledDinamizations';

export default function RappelSegmentCard(props: any) {
  const {
    title,
    cumple,
    numberDelegates,
    incentives,
    moreInfo,
    isFirst,
  } = props;
  const firstDescription = 'No cumple incentivo';

  return (
    <RappelSegmentCardStyled>
      {isFirst ? (
        <FirstSegmentCard>
          {numberDelegates}
          <br />
          <br />
          {firstDescription}
        </FirstSegmentCard>
      ) : (
        <SegmentCardStyled>
          <SegmentCardHeader
            cumple={cumple}
            showOnlyHeader={numberDelegates === null && incentives === null}
          >
            {title}
            {moreInfo && (
              <SegmentCardHeaderSubtitle>
                {moreInfo.map(((info:any) => (
                  <>
                    <br />
                    {info.text}
                    <SegmentCardHeaderSubtitleValue>{info.value}</SegmentCardHeaderSubtitleValue>
                  </>
                )))}
              </SegmentCardHeaderSubtitle>
            )}
          </SegmentCardHeader>
          {(numberDelegates !== null || incentives !== null) && (
            <SegmentCardContainer
              cumple={cumple}
              center={numberDelegates === null || incentives === null}
            >
              {numberDelegates !== null && (
                <DelegatesInfo center={incentives === null}>
                  Nº DE DELEGADOS
                  <br />
                  <b>{numberDelegates}</b>
                </DelegatesInfo>
              )}
              {incentives !== null && (
                <IncentiveInfo center={numberDelegates === null}>
                  INCENTIVO
                  <br />
                  <b>{incentives}</b>
                </IncentiveInfo>
              )}
            </SegmentCardContainer>
          )}
        </SegmentCardStyled>
      )}
    </RappelSegmentCardStyled>
  );
}
