import { useQuery } from 'react-query';
import Theme from '../../../styles/Theme';
import { Card } from '../Card/Card';
import useYear from '../../../hooks/useYear';
import useRanking from '../../../hooks/useRanking';
import useNumberFormat from '../../../hooks/useNumberFormat';
import {
  PositionDate, RankingTableWrapper,
  RankingItemCard, PositionItemContentCard, RankingList, DGTName, RankingWrapper,
} from './StyledCardBox';
import Container from '../Container/Container';
import GenericError from '../../pages/Error/GenericError';
import GET_TERRITORY_STATISTICS_GQL from '../../../graphql/queries/getTerritoryStatisticsGql';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import { Table } from '../Table/Table';

export default function RankingCard() {
  const graphQLClient = createGraphQLClient();
  const { year } = useYear();

  const {
    data: dataRanking,
    error: errorRanking,
  } = useQuery(
    ['RankingTerritoryStatistics', { year }],
    async () => (graphQLClient && graphQLClient.request(
      GET_TERRITORY_STATISTICS_GQL,
      { year },
    )),
  );

  const { rankingResult } = useRanking(dataRanking?.territoryStatistics?.nodes, year);

  const headers = [
    { Header: 'Posición', accessor: 'position' },
    { Header: 'Nombre DGT', accessor: 'name' },
    { Header: 'Puntos', accessor: 'value' },
  ];

  const processedRankingResult = rankingResult.slice(3).map((result) => ({ ...result, value: useNumberFormat(result.value, true), position: `${result.position + 1} ` }));

  if (errorRanking) {
    return <GenericError />;
  }

  return (
    <Card
      background={Theme.colorSecondary4}
      padding={`${rankingResult?.length < -1 ? '0' : '4rem'} 0 2rem 0`}
      margin={`${rankingResult?.length < -1 ? '10rem' : '6rem'} 0 0.5rem`}
    >
      <Container>
        <RankingWrapper>
          <PositionDate>
            {`${dataRanking?.territoryStatistics?.nodes[0]?.month || ''} ${year}`}
          </PositionDate>
          {(rankingResult) && (
            <RankingList>
              {rankingResult?.slice(0, 3).map((item:any, index:any) => (
                <RankingItemCard
                  key={item?.position}
                  position={index}
                >
                  <PositionItemContentCard>
                    <div>
                      <img src={item?.icon} alt="position" />
                      {useNumberFormat(item?.value, true)}
                      <DGTName>{item?.name}</DGTName>
                    </div>
                  </PositionItemContentCard>
                </RankingItemCard>
              ))}
            </RankingList>
          )}
        </RankingWrapper>
        <RankingTableWrapper>
          <Table
            columns={headers}
            data={processedRankingResult}
            theme={Theme.colorDisabled}
            className="ranking-table"
          />
        </RankingTableWrapper>
      </Container>
    </Card>
  );
}
