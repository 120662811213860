import { useQuery } from 'react-query';
import useYear from '../../../hooks/useYear';
import useRanking from '../../../hooks/useRanking';
import {
  Ranking2025Container, RankingTitleContainer, RankingTitle, RankingSubtitle,
  RankingContainer, RankingWinnersContainer, RankingParticipantsContainer, SkeletonCustom,
  RankingBarContainer, RankingBigBar, RankingValueBar, ContainerItems, RankingValue,
  DGTName, DGTCode, RankingPositionContainer, RankingPosition, CardBoxImagesContainer2025,
} from '../../atoms/Ranking/StyledRanking';
import IconCup from '../../atoms/Icon/IconCup';
import IconRoundedEqual from '../../atoms/Icon/IconRoundedEqual';
import IconRoundedUpArrow from '../../atoms/Icon/IconRoundedUpArrow';
import CardBoxImagesList from '../../atoms/CardBox/CardBoxImagesList';
import IconRoundedDownArrow from '../../atoms/Icon/IconRoundedDownArrow';
import Theme from '../../../styles/Theme';
import GET_TERRITORY_STATISTICS_GQL from '../../../graphql/queries/getTerritoryStatisticsGql';
import { createGraphQLClient } from '../../../graphql/graphqlClient';

export default function Ranking2025(props:any) {
  const { isMediator } = props;
  const graphQLClient = createGraphQLClient();
  const { year } = useYear();

  const {
    data: dataRanking,
    isFetching,
  } = useQuery(
    ['RankingTerritoryStatistics', { year }],
    async () => (graphQLClient && graphQLClient.request(
      GET_TERRITORY_STATISTICS_GQL,
      { year },
    )),
  );

  const { rankingResult } = useRanking(dataRanking?.territoryStatistics?.nodes, year);
  const monthName = dataRanking?.territoryStatistics?.nodes[0]?.month || '';
  const winnerResults = rankingResult.slice(0, 3);
  const participantsResults = rankingResult.slice(3);

  const getIcon = (difference:any) => {
    let response = <div style={{ width: '40px', height: '40px' }}>&nbsp;</div>;
    if (difference < 0) {
      response = <IconRoundedDownArrow color="#d32321" height="40px" />;
    } else if (difference > 0) {
      response = <IconRoundedUpArrow color="#1c975b" height="40px" />;
    } else if (difference === 0) {
      response = <IconRoundedEqual color="#FF8C00" height="40px" />;
    }
    return response;
  };

  return (
    <>
      <Ranking2025Container>
        <RankingTitleContainer>
          <RankingTitle>
            Ranking DGT
          </RankingTitle>
          <RankingSubtitle>
            {monthName !== '' && `${monthName} ${year}`}
          </RankingSubtitle>
        </RankingTitleContainer>
      </Ranking2025Container>
      <RankingContainer>
        {isFetching ? (
          <RankingParticipantsContainer>
            {Array.from({ length: 10 }, (_, index) => (
              <SkeletonCustom key={index} width="88px" height="310px" />
            ))}
          </RankingParticipantsContainer>
        ) : (
          <div>
            {winnerResults?.length > 0 ? (
              <>
                <RankingWinnersContainer>
                  {winnerResults.map((result) => (
                    <RankingBarContainer>
                      <ContainerItems
                        display="inline-flex"
                        gap="2px"
                        alignItems="center"
                        margin="0 auto"
                        height="45px"
                      >
                        <RankingPositionContainer>
                          <IconCup height="38px" color={Theme.colorRanking} />
                          <RankingPosition>{result.position + 1}</RankingPosition>
                        </RankingPositionContainer>
                        <RankingValue>{result.value.toFixed(2).replace('.', ',')}</RankingValue>
                      </ContainerItems>
                      <RankingBigBar>
                        <RankingValueBar percentage={(result.value / 9.0) * 100} />
                      </RankingBigBar>
                      {getIcon(result.difference)}
                      <ContainerItems
                        display="grid"
                        gap="2px"
                        alignItems="center"
                        justifyItems="center"
                        margin="6px auto"
                        textAlign="center"
                        height="60px"
                        alignContent="center"
                      >
                        <DGTCode>{result.code}</DGTCode>
                        <DGTName>{result.name.toLowerCase()}</DGTName>
                      </ContainerItems>
                    </RankingBarContainer>
                  ))}
                </RankingWinnersContainer>
                <RankingParticipantsContainer>
                  {participantsResults.map((result) => (
                    <RankingBarContainer>
                      <ContainerItems
                        display="inline-flex"
                        gap="2px"
                        alignItems="center"
                        margin="0 auto"
                        height="45px"
                      >
                        <RankingValue>{result.value.toFixed(2).replace('.', ',')}</RankingValue>
                      </ContainerItems>
                      <RankingBigBar>
                        <RankingValueBar percentage={(result.value / 9.0) * 100} />
                      </RankingBigBar>
                      {getIcon(result.difference)}
                      <ContainerItems
                        display="grid"
                        gap="2px"
                        alignItems="center"
                        justifyItems="center"
                        margin="6px auto"
                        textAlign="center"
                        height="60px"
                        alignContent="center"
                      >
                        <DGTCode>{result.code}</DGTCode>
                        <DGTName>{result.name.toLowerCase()}</DGTName>
                      </ContainerItems>
                    </RankingBarContainer>
                  ))}
                </RankingParticipantsContainer>
              </>
            ) : (
              <p>No hay datos</p>
            )}
          </div>
        )}
      </RankingContainer>
      {!isMediator && (
        <CardBoxImagesContainer2025>
          <CardBoxImagesList />
        </CardBoxImagesContainer2025>
      )}
    </>
  );
}
