import { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Theme from '../../../styles/Theme';
import GenericError from '../Error/GenericError';
import Container from '../../atoms/Container/Container';
import { LinkTable, Table } from '../../atoms/Table/Table';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import useFormatDate from '../../../hooks/useDate';
import ParamsModel from '../../models/PaginationModel';
import IMPORTS_GQL from '../../../graphql/queries/getImportsGql';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import APPROVE_IMPORT_GQL from '../../../graphql/mutations/approveImportGql';
import { getTokenFromStore } from '../../../store/sessionStore';

const columns = [
  {
    Header: 'Plan',
    accessor: 'planName',
  },
  {
    Header: 'Activo',
    accessor: 'current',
  },
  {
    Header: 'Aprobado',
    accessor: 'approved',
  },
  {
    Header: 'Fecha dato',
    accessor: 'dataDate',
  },
  {
    Header: 'Fecha importación',
    accessor: 'importDate',
  },
  {
    Header: 'Aprobar',
    accessor: 'approve',
  },
  {
    Header: 'Previsualizar',
    accessor: 'preview',
  },
];

interface IPlan {
  description?: string,
  dataDate?: string,
}

interface IImportDataProps {
  id?: string,
  plan?: IPlan,
  current?: boolean,
  filename?: string,
  approved?: string,
  counts?: string,
  preview?: string,
  approve?: string,
  updatedAt: string,
}

export default function AdminImports() {
  const graphQLClient = createGraphQLClient();
  const token = getTokenFromStore();
  const [clicked, setClicked] = useState('');

  const [globalParams, setGlobalParams] = useState<ParamsModel>(new ParamsModel());

  const userParams = {
    numberElements: 10,
    numberPage: globalParams?.numberPage || 1,
    order: globalParams?.order || 'nif',
    orderType: globalParams?.orderType || 'desc',
  };

  const {
    data,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['Imports', userParams],
    async () => (graphQLClient && graphQLClient.request(IMPORTS_GQL, userParams)),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (globalParams.numberPage !== undefined
      || globalParams.order !== undefined
      || globalParams.orderType !== undefined) {
      refetch();
    }
  }, [globalParams.numberPage, globalParams.order, globalParams.orderType]);

  const auth = `Bearer ${token}`;
  const approveImport = (datum: any) => axios.post(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}`, {
    query: APPROVE_IMPORT_GQL,
    variables: {
      id: datum.id,
      valid: datum.valid,
    },
  }, {
    headers: {
      authorization: auth,
    },
  }).then(() => {
    setClicked('');
    refetch();
  });

  const { mutate } = useMutation(approveImport);
  const navigate = useNavigate();

  if (error) {
    return <GenericError />;
  }

  const setImport = (id: string) => {
    localStorage.setItem('importId', id);
    navigate('/global-position/summary', { replace: true });
    window.location.reload();
  };

  const approve = (id: string, valid: boolean) => {
    setClicked(id);
    const datum = {
      id,
      valid,
    };

    mutate(datum as any);
  };

  const imports = data?.imports?.map((importFile: IImportDataProps) => ({
    id: importFile.id,
    planName: importFile?.plan?.description,
    current: importFile.current ? 'Si' : 'No',
    filename: importFile.filename,
    approved: importFile.approved ? 'Si' : 'No',
    counts: importFile.counts,
    importDate: useFormatDate(importFile.updatedAt),
    dataDate: importFile?.plan?.dataDate ? useFormatDate(importFile?.plan?.dataDate) : '',
    approve: clicked === importFile.id ? '' : (!importFile.approved ? <LinkTable onClick={() => approve(importFile.id || '', true)} title="Aprobar" /> : <LinkTable onClick={() => approve(importFile.id || '', false)} title="Rechazar" />),
    preview: !importFile.approved ? <LinkTable onClick={() => setImport(importFile.id || '')} title="Previsualizar" /> : '',
  }));

  return (
    <Container>
      <div style={{ overflowX: 'auto' }}>
        {(!isLoading && !isFetching && columns && imports) ? (
          <Table
            columns={columns}
            data={imports}
            theme={Theme.colorCampaign}
            defaultPageSize={40}
            countsRecords={imports?.map((user: any) => user?.counts)[0]}
            countPerPage={10}
            globalParams={globalParams}
            setGlobalParams={setGlobalParams}
          />
        ) : <TableSkeleton countCell={7} theme={Theme.colorCampaign} />}
      </div>
    </Container>
  );
}
