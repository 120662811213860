import {
  RappelHeaderContainer,
  RappelHeaderTitle,
} from './StyledDinamizations';
import RappelHeaderCardValue from './RappelHeaderCardValue';

export default function RappelHeader({
  title = '',
  value = '',
}: any) {
  return (
    <RappelHeaderContainer>
      <RappelHeaderTitle>{title}</RappelHeaderTitle>
      <RappelHeaderCardValue value={value} />
    </RappelHeaderContainer>
  );
}
