export default function isMediator(category: any) {
  // Categorias de mediadores o no mediadores, que no deben ver el ranking
  const mediatorCategories = [
    'DELEGADOS',
    'DELEGADO',
    'AGENTES',
    'AGENTE',
    'APS',
    'GDLG',
    'AFIS',
    'APS/EFA RA',
    'AVINCULADO',
    'CORREDOR',
    'MEDIACONCES',
    'MEDIAINMOBI',
    'MEDIASALUD',
    'MEDIAVIDA',
    'OBSE',
    'OBSV',
    'EFA RV',
    'ACLIAPNV',
    'ACLICONC',
    'GESCLSANT',
    'GESTORCORR',
    'GESTREMOTO',
    'TECSAC',
    'GESTOPSTT',
    'RESREMOTO',
  ];

  return mediatorCategories.includes(category);
}
