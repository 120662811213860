import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Theme from '../../../styles/Theme';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import CardBoxList2024 from '../../atoms/CardBox/RankingCard';
// import ImageBanner from '../../atoms/ImageBanner/ImageBanner';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import CardBoxImagesList from '../../atoms/CardBox/CardBoxImagesList';
import Ranking2025 from './Ranking2025';
import useYear from '../../../hooks/useYear';
import isMediator from '../../../hooks/useUserType';
// import rankingBannerImage from '../../../assets/images/ranking.jpg';

export default function RankingTeam() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userCategory: any = queryClient.getQueryState(['UserCategory']);
  const userCategorId = userCategory?.data?.userCategory?.id;
  const { year } = useYear();

  return (
    <>
      <Container>
        {/* year <= 2024 && <ImageBanner img={rankingBannerImage} /> */}
        <ButtonBack onClick={() => navigate('/team/summary/general')} type="button" style={{ marginBottom: '30px' }}>Volver</ButtonBack>
      </Container>
      {userCategory?.data?.userCategory !== undefined && userCategory && (
        <>
          { year <= 2024 && (
            <>
              { !isMediator(userCategorId) && (
                <CardBoxImagesList />
              )}
              <Container>
                <Typography color={Theme.colorRanking} size="36px" weight="700" type="h1" display="block">
                  Ranking DGT
                </Typography>
              </Container>
              <CardBoxList2024 />
            </>
          )}
          {year >= 2025 && <Ranking2025 isMediator={isMediator(userCategorId)} />}
        </>
      )}
    </>
  );
}
