import { useState, useEffect, memo } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import Theme from '../../../styles/Theme';
import NavLinkTo from '../../atoms/Link/NavLinkTo';
import { LinkAnchor } from '../../atoms/Link/StyledLink';
import Typography from '../../atoms/Typography/Typography';
import data from './dataNavigation';
import Help from './Help';
import GET_CURRENT_USER_PLANS_GQL from '../../../graphql/queries/getCurrentUserPlansGql';
import GET_CURRENT_USER_DINAMIZATION_GQL from '../../../graphql/queries/getCurrentUserDinamizationGql';

import {
  Menu,
  MenuItem,
  SubMenu,
  SubMenuItem,
  MainSubMenu,
} from './StyledNavigation';
import Redeem from './Redeem';
import useYear from '../../../hooks/useYear';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import { getTokenFromStore } from '../../../store/sessionStore';

export interface IProps {
  isAdmin?: boolean,
  isCentral?: boolean,
  categoryId?: string,
}

function Navigation({ isAdmin, isCentral, categoryId }: IProps) {
  const SHOW_DINAMIZATIONS = true;
  const graphQLClient = createGraphQLClient();
  const isAuthorized = getTokenFromStore();

  const location = useLocation();
  const dataFiltered = data
    .filter((nav: any) => location.pathname.includes(nav.slug))
    .map((nav: any) => nav.id);
  const [navId, setNavId] = useState<number>();
  const [filter, setFilter] = useState<number>(dataFiltered[0] ?? navId);
  const [navigation, setNavigation] = useState<any[]>([]);
  const { year } = useYear();

  const subMenuHandle = (id: number) => setFilter(id);

  if (isAuthorized) {
    const {
      data: plansData,
      refetch: refetchPlanData,
    } = useQuery(
      ['MePlans', year],
      async () => (graphQLClient && graphQLClient.request(
        GET_CURRENT_USER_PLANS_GQL,
        { year },
      )),
      {
        staleTime: Infinity,
      },
    );

    const {
      data: dinamizationsData,
      refetch: refetchDinamizationData,
    } = useQuery(
      ['MeDinamizations', year],
      async () => (graphQLClient && graphQLClient.request(
        GET_CURRENT_USER_DINAMIZATION_GQL,
        { year },
      )),
      {
        staleTime: Infinity,
      },
    );

    useEffect(() => {
      refetchPlanData();
      refetchDinamizationData();
    }, [year]);

    useEffect(() => {
      const filtered = data
        .filter((nav: any) => nav.id === filter)
        .map((nav: any) => nav.tabs);

      setNavId(dataFiltered[0]);
      setNavigation(filtered[0]);

      if (location.pathname === '/home/summary') {
        const isHome = data.map((nav: any) => nav.tabs)[0];
        setNavigation(isHome);
      }

      if (location.pathname === '/global-position/campaign') {
        setFilter(1);
      }

      if (location.pathname === '/global-position/rappel') {
        setFilter(1);
      }

      if (location.pathname === '/global-position/convention') {
        setFilter(1);
      }

      if (location.pathname === '/global-position/ranking') {
        setFilter(1);
      }

      if (location.pathname === '/calendar/convention') {
        setFilter(3);
      }
    }, [{}, filter]);

    let subDataFilteredByCategory:any = [];

    const [dataFilteredByCategory, setDataFilteredByCategory] = useState<any[]>([]);

    useEffect(() => {
      // No mostrar dinamizaciones si el usuario no tiene o no hay que mostrarlas de forma general
      let result = data;
      if (!SHOW_DINAMIZATIONS || (dinamizationsData && Object
        .values(dinamizationsData.meDinamizations).every((value) => (value === false)))) {
        result = data.filter((item :any) => item.title !== 'Dinamizaciones');
      }
      if (!isAdmin && !isCentral && data !== undefined) {
        result = result
          .filter((nav: any) => (nav.categories.includes(categoryId) || nav.categories.includes('TODOS')));
      }
      setDataFilteredByCategory(result);
    }, [data, dinamizationsData, categoryId]);

    if (!location.pathname.includes('global-position')) {
      if (!isAdmin && !isCentral && data !== undefined && navigation !== undefined) {
        subDataFilteredByCategory = navigation
          .filter((nav: any) => (nav.categories.includes(categoryId) || nav.categories.includes('TODOS')));
      } else if (isCentral) {
        subDataFilteredByCategory = navigation
          .filter((nav: any) => (!nav.title.includes('Importaciones')));
      } else {
        subDataFilteredByCategory = navigation;
      }
    } else {
      subDataFilteredByCategory = navigation;

      if (!plansData || !plansData?.mePlans?.campaign) {
        subDataFilteredByCategory = subDataFilteredByCategory?.filter((nav: any) => (nav.type !== 'campaign'));
      }
      if (!plansData || !plansData?.mePlans?.rappel) {
        subDataFilteredByCategory = subDataFilteredByCategory?.filter((nav: any) => (nav.type !== 'rappel'));
      }
      if (!plansData || !plansData?.mePlans?.convention) {
        subDataFilteredByCategory = subDataFilteredByCategory?.filter((nav: any) => (nav.type !== 'convention'));
      }
      if (!plansData || !plansData?.mePlans?.ranking) {
        subDataFilteredByCategory = subDataFilteredByCategory?.filter((nav: any) => (nav.type !== 'ranking'));
      }
    }

    return (
      <nav>
        {isAuthorized ? (
          <>
            <Menu>
              {dataFilteredByCategory?.map((nav : any) => (
                <MenuItem key={nav.id}>
                  <NavLinkTo
                    to={nav.slug ?? '/'}
                    color={Theme.colorSecondary}
                    padding="8px 14px"
                    onClick={() => subMenuHandle(nav.id)}
                  >
                    <span style={{ fontSize: '18px' }}>{nav.title}</span>
                  </NavLinkTo>
                </MenuItem>
              ))}
            </Menu>
            <MainSubMenu>
              <SubMenu>
                {subDataFilteredByCategory?.map((tab: any) => (
                  <SubMenuItem key={tab?.id ?? filter}>
                    {(tab?.external === true && isAdmin === false && isCentral === false) && (
                      <LinkAnchor href={tab.slug ?? '/'} target="_blank">
                        <span style={{ fontSize: '17px' }}>{tab.title}</span>
                      </LinkAnchor>
                    )}
                    {tab?.external !== true && (
                      <NavLinkTo to={tab.slug ?? '/'}>
                        <span style={{ fontSize: '17px' }}>{tab.title}</span>
                      </NavLinkTo>
                    )}
                  </SubMenuItem>
                ))}
              </SubMenu>
              <Redeem
                message="Canjea aquí tus tréboles y puntos"
                url="https://plataformaretos.com/modules/requirements/form"
              />
              <Help message="¿En qué podemos ayudarte?" />
            </MainSubMenu>
          </>
        )
          : <Typography size="16px" weight="700">Plataforma de incentivos</Typography>}
      </nav>
    );
  }
  return (
    <nav>
      <Typography size="16px" weight="700">Plataforma de incentivos</Typography>
    </nav>
  );
}

Navigation.defaultProps = {
  isAdmin: false,
  isCentral: false,
  categoryId: undefined,
};

export default memo(Navigation);
