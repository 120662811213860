import {
  RappelInfoStyled,
  RappelDescription,
  RappelDescriptionData,
  RappelInfoContainer,
  RappelInfoSegment,
  RappelSegment,
  RappelExtraDataContainer,
  DinamizationText,
  ContainerItems,
  RoundedDelegateContainer,
  VerticalDivider,
} from './StyledDinamizations';
import RappelSegmentCard from './RappelSegmentCard';
import Theme from '../../../styles/Theme';

export default function RappelDelegatesInfo(props: any) {
  const { data } = props;
  const segmentsName = Object.keys(data?.segments);

  const firstSegment = segmentsName[0];
  const lastSegment = segmentsName[segmentsName.length - 1];
  const dataDescription = data.description.text;
  const descriptionSplit = dataDescription.split('XXX');

  return (
    <RappelInfoStyled>
      <ContainerItems
        width="100%"
        justifyContent="space-between"
        display="inline-flex"
        gap="8px"
        margin="auto"
        alignItems="center"
        textAlign="center"
      >
        {dataDescription.includes('XXX') ? (
          <ContainerItems
            width="fit-content"
            justifyContent="center"
            display="inline-flex"
            gap="8px"
            margin="0"
            alignItems="center"
            textAlign="center"
          >
            <RappelDescription fontWeight="400">{descriptionSplit[0]}</RappelDescription>
            {data.description.data
              && (<RappelDescriptionData>{data.description.data}</RappelDescriptionData>)}
            <RappelDescription fontWeight="400">{descriptionSplit[1]}</RappelDescription>
          </ContainerItems>
        ) : (
          <RappelDescription>{dataDescription}</RappelDescription>
        )}
        <RoundedDelegateContainer
          color={Theme.colorWhite}
          borderRadius="40px"
          margin="0 0 20px 0"
          showShadow
        >
          <ContainerItems
            width="fit-content"
            justifyContent="center"
            display="grid"
            gap="none"
            margin="auto"
            alignItems="center"
            textAlign="left"
          >
            <DinamizationText
              fontSize="12px"
              fontWeight="700"
              color={Theme.colorSecondary}
              margin="10px 10px 0 10px"
            >
              RAPPEL PROVISIONAL:
            </DinamizationText>
            <DinamizationText
              fontSize="23px"
              fontWeight="400"
              color={Theme.colorRappelDinamizations}
              margin="0 0 10px 10px"
            >
              {data.provisional_amount}
            </DinamizationText>
          </ContainerItems>
          <VerticalDivider
            color={Theme.colorSecondary3}
            height="40px"
          />
          <ContainerItems
            width="fit-content"
            justifyContent="center"
            display="grid"
            gap="none"
            margin="auto"
            alignItems="center"
            textAlign="left"
          >
            <DinamizationText
              fontSize="12px"
              fontWeight="700"
              color={Theme.colorSecondary}
              margin="10px 10px 0 10px"
            >
              NP:
            </DinamizationText>
            <DinamizationText
              fontSize="23px"
              fontWeight="400"
              color={Theme.colorRappelDinamizations}
              margin="0 0 10px 10px"
            >
              {data.np_amount}
            </DinamizationText>
          </ContainerItems>
        </RoundedDelegateContainer>
      </ContainerItems>
      <RappelInfoContainer>
        {segmentsName && segmentsName?.map((segment:any) => (
          <RappelInfoSegment key={segment}>
            <RappelSegment
              cumple={data.segments[segment]?.cumple}
              isFirst={firstSegment === segment}
              isLast={lastSegment === segment}
            />
            <RappelSegmentCard
              title={segment}
              cumple={data.segments[segment]?.cumple}
              numberDelegates={data.segments[segment]?.number_delegates}
              incentives={data.segments[segment]?.incentives}
              moreInfo={data.segments[segment]?.more_info}
              isFirst={firstSegment === segment}
            />
          </RappelInfoSegment>
        ))}
      </RappelInfoContainer>
      {data.extra_data && (
        <RappelExtraDataContainer>
          {data.extra_data.map((extra:any) => (
            <ContainerItems
              width="fit-content"
              justifyContent="center"
              display="inline-flex"
              gap="8px"
              margin="auto"
              alignItems="center"
              textAlign="center"
            >
              <DinamizationText
                fontSize="16px"
                fontWeight="700"
                color={Theme.colorSecondary}
                margin="0"
              >
                {extra.text}
              </DinamizationText>
              <DinamizationText
                fontSize="18px"
                fontWeight="400"
                color={Theme.colorRappelDinamizations}
                margin="0"
              >
                {extra.data}
              </DinamizationText>
            </ContainerItems>
          ))}
        </RappelExtraDataContainer>
      )}
    </RappelInfoStyled>
  );
}
