import { useState } from 'react';
import Theme from '../../../styles/Theme';
import RankingOneCard from './RankingOneCard';
import RankingMultipleCards from './RankingMultipleCards';
import {
  RankingCampaignContainerStyled,
  RankingCampaignContent,
  DinamizationText,
} from '../../organisms/Dinamizations/StyledDinamizations';
import HeaderCard from '../../organisms/Dinamizations/HeaderCard';
import DinamizationButtons from '../../organisms/Dinamizations/DinamizationButtons';

export default function RankingCampaignCard(props:any) {
  const {
    data,
    width,
    showDGT,
    showDT,
    showOD,
  } = props;
  const rankingData = data[0];

  // IMPORTANTE: Si se cambian estos copies, hay que cambiarlo abajo
  const getButtons = () => {
    const finalButtons = [];
    if (showDGT) finalButtons.push('RANKING DGT');
    if (showDT) finalButtons.push('RANKING NACIONAL DT');
    if (showOD) finalButtons.push(rankingData?.moreInfo?.odTitle);
    return finalButtons;
  };
  const buttons = getButtons();
  const [selectedButton, setSelectedButton] = useState<string>(buttons[0]);

  return (
    <HeaderCard
      title="Ranking de la campaña"
      color={Theme.colorPrimary}
      width={width}
      height="auto"
    >
      <RankingCampaignContainerStyled>
        <DinamizationButtons
          buttons={buttons}
          selected={selectedButton}
          setSelected={setSelectedButton}
          color={Theme.colorPrimary}
          margin="10px"
        />
        {rankingData?.moreInfo?.odSubtitle && selectedButton.includes('RANKING OFICINAS DIRECTAS') && (
          <DinamizationText
            fontSize="20px"
            fontWeight="700"
            color={Theme.colorPrimary}
            margin="20px 10px"
            textShadow="1px 1px 3px rgba(0, 0, 0, 0.1)"
          >
            {rankingData?.moreInfo?.odSubtitle}
          </DinamizationText>
        )}
        <RankingCampaignContent>
          {selectedButton === 'RANKING DGT' ? <RankingOneCard data={rankingData.DGT} /> : undefined}
          {selectedButton === 'RANKING NACIONAL DT' ? <RankingMultipleCards data={rankingData.DT} /> : undefined}
          {selectedButton.includes('RANKING OFICINAS DIRECTAS') ? <RankingMultipleCards data={rankingData.OD} subtitle={rankingData?.moreInfo?.odSubtitle} /> : undefined}
        </RankingCampaignContent>
      </RankingCampaignContainerStyled>
    </HeaderCard>
  );
}
