import { IIcon } from './Iicon';

export default function IconPlus({ color, height, width }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 14" fill="none">
      <rect x="5.65283" y="0.0805664" width="3.46633" height="13.8653" fill={color} />
      <rect x="14.3188" y="5.28003" width="3.46633" height="13.8653" transform="rotate(90 14.3188 5.28003)" fill={color} />
    </svg>
  );
}
