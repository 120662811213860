import {
  LinearPercentageGraphicStyled,
} from './StyledDinamizations';
import Theme from '../../../styles/Theme';

export default function LinearPercentageGraphic({
  percentageValue = 0,
  color = Theme.colorPrimary,
}: any) {
  return (
    <LinearPercentageGraphicStyled percentage={percentageValue} color={color} />
  );
}
