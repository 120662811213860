import Theme from '../../../styles/Theme';
import HeaderCard from '../../organisms/Dinamizations/HeaderCard';
import CardCampaignDelegates from '../../organisms/Dinamizations/CardCampaignDelegates';
import {
  CampaignCards,
} from '../../organisms/Dinamizations/StyledDinamizations';

export default function DelegatesChallengesCampaignCard(props:any) {
  const { info, products } = props;

  return (
    <HeaderCard
      title={info?.title}
      subtitle={info?.subtitle}
      color={Theme.colorPrimary}
    >
      <CampaignCards
        flex="1"
      >
        {products && products.length > 0 && products.map((product:any) => (
          <CardCampaignDelegates
            key={product.title}
            title={product.title}
            subtitle={product.subtitle}
            amount={product.amount}
            totalAmount={
              product.totalAmount
            }
            oldPosition={product.oldPosition}
            currentPosition={product.currentPosition}
          />
        ))}
      </CampaignCards>
    </HeaderCard>
  );
}
