import { useState } from 'react';
import RappelDinamization from './RappelDinamization';
import RankingCampaignCard from './RankingCampaignCard';
import RankingCampaignCardDoficorred from './RankingCampaignCardDoficorred';
import SummaryCampaignCard from './SummaryCampaignCard';
import SummaryAsesores from './SummaryAsesores';
import ChallengesCampaignCard from './ChallengesCampaignCard';
import Theme from '../../../styles/Theme';
import Container from '../../atoms/Container/Container';
import {
  DinamizationContainer,
  DinamizationHeaderContainer,
  DinamizationHeaderButtonsContainer,
  DinamizationContent,
  DinamizationText,
  CardsContainer,
} from '../../organisms/Dinamizations/StyledDinamizations';
import DinamizationBigButton from '../../organisms/Dinamizations/DinamizationBigButton';
import LastDinamizationUpdate from '../../organisms/Dinamizations/LastDinamizationUpdate';

export default function GeneralDinamizationsQuarterly(props:any) {
  const { data } = props;
  const buttons = [
    {
      title: 'MI POSICIÓN',
      subtitle: undefined,
      color: Theme.colorPrimary,
    },
    {
      title: 'MI EQUIPO',
      subtitle: undefined,
      color: Theme.colorPrimary,
    },
  ];
  const [selectedButton, setSelectedButton] = useState<string>(buttons[0]?.title);

  const configurationScope = [
    {
      scope: 'DGT',
      showSummary: true,
      showRankingDGT: true,
      showRankingDT: true,
      showRankingOD: true,
      showChallenges: true,
      showRappels: true,
    },
    {
      scope: 'DT',
      showSummary: true,
      showRankingDGT: false,
      showRankingDT: true,
      showRankingOD: true,
      showChallenges: true,
      showRappels: true,
    },
    {
      scope: 'OD',
      showSummary: true,
      showRankingDGT: false,
      showRankingDT: false,
      showRankingOD: true,
      showChallenges: true,
      showRappels: true,
    },
    {
      scope: 'DOFICORRED',
      showSummary: true,
      showRankingDGT: false,
      showRankingDT: false,
      showRankingOD: true,
      showChallenges: false,
      showRappels: false,
    },
    {
      scope: 'ACPROPATEM',
      showSummary: true,
      showRankingDGT: false,
      showRankingDT: false,
      showRankingOD: false,
      showChallenges: true,
      showRappels: true,
    },
    {
      scope: 'ACVISASOFI',
      showSummary: true,
      showRankingDGT: false,
      showRankingDT: false,
      showRankingOD: false,
      showChallenges: true,
      showRappels: true,
    },
  ];

  const userShow = configurationScope.find((conf:any) => (conf?.scope === data?.userInfo?.scope));
  const userShowRanking = userShow?.showRankingDGT
    || userShow?.showRankingDT || userShow?.showRankingOD;
  const asesoresCategories = ['ACVISASOFI', 'ACPROPATEM'];
  const isAsesor = asesoresCategories.includes(data?.userInfo?.category_code);

  return (
    <Container>
      <DinamizationContainer>
        <DinamizationHeaderContainer>
          <DinamizationHeaderButtonsContainer>
            {buttons.map((button:any) => (
              <DinamizationBigButton
                title={button.title}
                subtitle={button.subtitle}
                selected={selectedButton === button.title}
                onClick={() => setSelectedButton(button.title)}
                color={button.color}
                type="button"
                key={button.title}
              />
            ))}
          </DinamizationHeaderButtonsContainer>
          <LastDinamizationUpdate date={data?.summary?.info?.dataDate} />
        </DinamizationHeaderContainer>
        <DinamizationContent>
          <DinamizationText
            fontSize="18px"
            fontWeight="700"
            color={Theme.colorPrimary}
            margin="0"
          >
            CAMPAÑA NACIONAL
          </DinamizationText>
          <DinamizationText
            fontFamily="Fixture Condensed Medium, sans-serif"
            fontSize="46px"
            fontWeight="400"
            color={Theme.colorPrimary}
            margin="0 0 20px 0"
          >
            {data?.summary?.info?.campaignName}
          </DinamizationText>
          {selectedButton === buttons[0].title
            && (
              <div>
                {userShow?.showSummary && !isAsesor && (
                  <SummaryCampaignCard
                    info={data?.summary?.info}
                    cards={data?.summary?.cards}
                    products={data?.summary?.products}
                  />
                )}
                {userShow?.showSummary && isAsesor && (
                  <SummaryAsesores
                    info={data?.summary?.info}
                    products={data?.summary?.products}
                  />
                )}
              </div>
            )}
          {selectedButton === buttons[1].title
            && (
              <>
                <CardsContainer>
                  {userShowRanking && data?.userInfo?.category_code !== 'DOFICORRED' && (
                    <RankingCampaignCard
                      data={data?.ranking}
                      showDGT={userShow?.showRankingDGT}
                      showDT={userShow?.showRankingDT}
                      showOD={userShow?.showRankingOD}
                      width={userShow.showChallenges ? '59%' : '100%'}
                    />
                  )}
                  {userShowRanking && data?.userInfo?.category_code === 'DOFICORRED' && (
                    <RankingCampaignCardDoficorred
                      data={data?.ranking}
                      width="100%"
                    />
                  )}
                  {userShow?.showChallenges && (
                    <ChallengesCampaignCard
                      data={data?.challenges}
                      width={userShowRanking ? '38%' : '100%'}
                    />
                  )}
                </CardsContainer>
                {userShow?.showRappels && (
                  <RappelDinamization data={data?.rappels} />
                )}
              </>
            )}
        </DinamizationContent>
      </DinamizationContainer>
    </Container>
  );
}
