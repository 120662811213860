import Theme from '../../../styles/Theme';
import CardPoints from '../../organisms/Dinamizations/CardPoints';
import BigCardRanking from '../../organisms/Dinamizations/BigCardRanking';
import {
  RankingOneCardContainer,
  OneCardContent,
  ContainerItems,
  DinamizationText,
} from '../../organisms/Dinamizations/StyledDinamizations';

export default function RankingOneCard(props:any) {
  const { data } = props;
  const cardData = data?.[0];

  return (
    <RankingOneCardContainer>
      <BigCardRanking
        number={cardData?.number}
        title={cardData?.title}
        fromRankingOneCard
      >
        <CardPoints
          color={Theme.colorPrimary}
          title="PUNTOS"
          value={cardData?.points}
          colorBackground={Theme.colorWhite}
          fontSize="16px"
          showShadow
        />
      </BigCardRanking>
      <OneCardContent>
        <ContainerItems
          width="100%"
          display="grid"
          gap="18px"
        >
          {cardData?.products?.map((item:any) => (
            <ContainerItems
              width="100%"
              justifyContent="space-between"
              key={item.title}
            >
              <DinamizationText
                fontSize="18px"
                fontWeight="400"
                color={Theme.fontColorSecondary}
                margin="0"
              >
                {item.title}
              </DinamizationText>
              <DinamizationText
                fontSize="18px"
                fontWeight="700"
                color={Theme.fontColorSecondary}
                margin="0"
              >
                {item.value}
              </DinamizationText>
            </ContainerItems>
          ))}
        </ContainerItems>
      </OneCardContent>
    </RankingOneCardContainer>
  );
}
