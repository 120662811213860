import { useQuery } from 'react-query';
import { useState } from 'react';
import { IFilter } from '../../../interfaces/IFilter.d';
import { IPlan } from '../../../interfaces/IPlan.d';
import Theme from '../../../styles/Theme';
import Grid from '../../atoms/Grid/Grid';
import LinkTo from '../../atoms/Link/LinkTo';
import Container from '../../atoms/Container/Container';
import { WrapImage, Image } from '../../atoms/Image/Image';
import { LinkTable, Table } from '../../atoms/Table/Table';
import Typography from '../../atoms/Typography/Typography';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import { Card, CardBody, CardContentCover } from '../../atoms/Card/Card';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import FilterModel from '../../molecules/Filter/FilterModel';
import useYear from '../../../hooks/useYear';
import GenericError from '../Error/GenericError';
import { dataConventionCard, dgtLinks } from './dataConventionCalendar';
import { getGraphQLClient } from '../../../graphql/graphqlClient';
import GET_PLANS_BY_TYPE_GQL from '../../../graphql/queries/getPlansByTypeGql';
import GET_USER_CATEGORY_GQL from '../../../graphql/queries/getUserCategoryGql';

export default function ConventionCalendar() {
  const { year } = useYear();
  const graphQLClient = getGraphQLClient();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const selectedFilters: IFilter = { year: [] };

  const conventionParams:any = {
    type: 'Convention',
    year,
  };

  const {
    data: planConvention,
    error: errorConvention,
    isLoading: isLoadingConvention,
    isFetching: isFetchingConvention,
  } = useQuery(
    ['Convention_Calendar', conventionParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_PLANS_BY_TYPE_GQL,
      conventionParams,
    )),
  );

  const {
    data, error, isLoading,
  } = useQuery(
    ['ConventionsGrouping', year],
    async () => (graphQLClient && graphQLClient.request(GET_USER_CATEGORY_GQL, { year })),
  );

  const showCentralOrAdmin = (data?.userCategory?.roles?.includes('Central') || data?.userCategory?.roles?.includes('Admin'));

  if (error || errorConvention) {
    return <GenericError />;
  }

  const dataConventionFromQuery = planConvention?.planByType?.nodes?.map((plan: IPlan) => ({
    convention: plan?.description,
    downloadBase: plan?.basesUrl === '#' ? <Container>No disponible</Container> : <LinkTable url={plan?.basesUrl} title="Descargar" />,
  })).sort(
    (
      a: { convention: number; },
      b: { convention: number; },
    ) => ((a.convention > b.convention) ? 1 : -1),
  );

  const columnsConventionBases = [
    { Header: 'Convención', accessor: 'convention' },
    { Header: 'Descargar', accessor: 'downloadBase' },
  ];

  return (
    <Container>
      <GroupFilterGeneral
        selectedFilters={selectedFilters}
        filter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <Grid
        columnsLg={data?.userCategory?.dgt || showCentralOrAdmin ? 2 : 1}
        regular
        spaceBetween={1}
        rowHeight={['8rem', '428px']}
        margin="3rem auto"
      >
        {(!isLoadingConvention
          && !isFetchingConvention) ? (
            <Table
              columns={columnsConventionBases}
              data={dataConventionFromQuery}
              theme={Theme.colorConvention}
              className="tb__calendar"
              defaultPageSize={6}
            />
          ) : <TableSkeleton countCell={2} theme={Theme.colorConvention} />}
        { !isLoading && (data?.userCategory?.dgt || showCentralOrAdmin) && (
          year === 2025 ? (
            dataConventionCard.map((convention: any) => (
              <div
                style={{
                  position: 'relative',
                  minHeight: '45rem',
                }}
              >
                <a
                  href={
                    showCentralOrAdmin
                      ? dgtLinks[year].general
                      : dgtLinks[year][data.userCategory.dgt]
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WrapImage cover className="image__card">
                    <Image type="full" src={convention.image[year]} />
                  </WrapImage>
                </a>
              </div>
            ))
          ) : (
            dataConventionCard.map((convention: any) => (
              <Card
                key={convention.id}
                padding="1.5rem 2rem 6rem"
                display="flex"
                overlay
                minHeight="400px !important"
              >
                <CardBody>
                  <CardContentCover>
                    <Typography type="h3" className="card__title">{convention.title}</Typography>
                    {!!convention.cta
                      && (dgtLinks[year].general || dgtLinks[year][data.userCategory.dgt])
                      && (<Typography className="card__paragraph">{convention.paragraph}</Typography>)}
                  </CardContentCover>
                  <LinkTo to="/" className="link">
                    <span>{convention.linkText}</span>
                  </LinkTo>
                  {!!convention.cta
                    && (dgtLinks[year].general || dgtLinks[year][data.userCategory.dgt])
                    && (
                      <div style={{ width: 'fit-content' }}>
                        <a href={showCentralOrAdmin ? dgtLinks[year].general : dgtLinks[year][data.userCategory.dgt]} target="_blank" className="btn btn--primary" style={{ width: 'fit-content' }} rel="noreferrer">
                          <span>{convention.cta}</span>
                        </a>
                      </div>
                    )}
                </CardBody>
                <WrapImage cover className="image__card">
                  <Image type="full" src={convention.image[year]} />
                </WrapImage>
              </Card>
            ))
          )
        )}
      </Grid>
    </Container>
  );
}
