import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { Generic } from '../../../interfaces/Generic.d';
import Theme from '../../../styles/Theme';
import ParamsModel from '../../models/PaginationModel';
import { Card } from '../../atoms/Card/Card';
import { Table } from '../../atoms/Table/Table';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import ImageBanner from '../../atoms/ImageBanner/ImageBanner';
import Loading, { LoadingSpinner } from '../../atoms/Loading/Loading';
import GenericError from '../Error/GenericError';
import useYear from '../../../hooks/useYear';
import useRemoveUndefinedProperties from '../../../hooks/useRemoveUndefinedProperties';
import ComparativeAdapter from '../../../adapters/ComparativeAdapter';
import useExportStore from '../../../store/exportStore';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import conventionBannerImage from '../../../assets/images/convention_header.jpg';
import FilterModel from '../../molecules/Filter/FilterModel';
import CardDataComparativeExport from '../../molecules/Cards/CardDataComparativeExport';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import GET_CURRENT_USER_DATA_INFO_GQL from '../../../graphql/queries/getCurrentUserDataInfoGql';
import GET_COMPARATIVE_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getComparativeResultsByCodeGql';

export default function CampaignGPComparative() {
  const type = 'Convention';
  const { year } = useYear();
  const graphQLClient = createGraphQLClient();
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const [globalParams, setGlobalParams] = useState<ParamsModel>(new ParamsModel());

  const params: Generic = {
    type,
    grouping: globalFilter.grouping,
    subgrouping: globalFilter.subgrouping,
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    level: globalFilter.level,
    year,
    status: globalFilter.grouping === 'COMERCIAL' ? undefined : globalFilter.status,
  };

  useRemoveUndefinedProperties(params);

  const conventionParams = {
    ...params,
    numberElements: globalParams?.numberElements || 10,
    numberPage: globalParams?.numberPage || 1,
  };

  const {
    data: dataCurrentUserInfo,
    error: errorCurrentUserInfo,
  } = useQuery(
    ['ConventionCurrentUserInfo', conventionParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_CURRENT_USER_DATA_INFO_GQL,
      conventionParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const codeConvention = dataCurrentUserInfo?.me?.plans?.nodes[0]?.code;

  const conventionParamsComparative: Generic = {
    code: codeConvention,
    category: globalFilter.category || undefined,
    dgt: globalFilter.dgt || undefined,
    dt: globalFilter.dt || undefined,
    od: globalFilter.od || undefined,
    firstName: globalFilter.firstName || undefined,
    lastName: globalFilter.lastName || undefined,
    nif: globalFilter.nif || undefined,
    group: globalFilter.group || undefined,
    year,
    order: globalParams?.order || 'dgt',
    orderType: globalParams?.orderType || 'desc',
    numberElements: globalParams?.numberElements || 10,
    numberPage: globalParams?.numberPage || 1,
  };

  useRemoveUndefinedProperties(conventionParamsComparative);

  const {
    data: dataConventionComparative,
    error: errorConventionComparative,
    refetch: refetchConventionComparative,
    isFetching: isFetchingConventionComparative,
    status: statusCoventionComparative,
  } = useQuery(
    ['ConventionComparative', conventionParamsComparative],
    async () => (graphQLClient && graphQLClient.request(
      GET_COMPARATIVE_RESULTS_BY_CODE_GQL,
      conventionParamsComparative,
    )),
    {
      staleTime: Infinity,
      enabled: !!codeConvention,
    },
  );

  const filterParams = {
    type,
    code: codeConvention,
    year,
  };

  const {
    data: dataFilters,
    error: errorFilters,
  } = useQuery(
    ['ConventionFilters', filterParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      filterParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const conventionParamsComparativeExport = {
    ...conventionParamsComparative,
    numberElements: undefined,
    numberPage: undefined,
  };

  const {
    data: dataConventionComparativeXlsx,
    isLoading: isLoadingConventionComparativeXlsX,
    refetch: refetchConventionComparativeXlsx,
    status: statusCoventionComparativeXlsx,
  } = useQuery(
    ['ConventionComparativeXlsx', conventionParamsComparativeExport],
    async () => (graphQLClient && graphQLClient.request(
      GET_COMPARATIVE_RESULTS_BY_CODE_GQL,
      conventionParamsComparativeExport,
    )),
    {
      staleTime: Infinity,
      enabled: !!codeConvention,
    },
  );

  useEffect(() => {
    if (codeConvention !== undefined && statusCoventionComparative === 'success') {
      refetchConventionComparative();
    }
    if (codeConvention !== undefined && statusCoventionComparativeXlsx === 'success') {
      refetchConventionComparativeXlsx();
    }
  }, [globalFilter]);

  const {
    myOwnData,
    myOwnColumns,
    otherData,
    otherColumns,
  } = ComparativeAdapter(dataCurrentUserInfo, dataConventionComparative);

  const { readyToExport } = useExportStore((state) => ({
    readyToExport: state.readyToExport,
  }), shallow);

  if (errorConventionComparative || errorCurrentUserInfo || errorFilters) {
    return <GenericError />;
  }

  return (
    <Container>
      <ImageBanner img={conventionBannerImage} />
      <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>

      <Typography align="center" color={Theme.colorConvention} display="block" size="28px" margin="2rem auto" weight="500">
        {dataCurrentUserInfo?.me?.plans?.nodes[0]?.description}
      </Typography>

      {dataFilters ? (
        <FilterForm
          dataFilters={dataFilters}
          filter={globalFilter}
          setFilter={setGlobalFilter}
          type={type}
        />
      ) : (<FilterFormSkeleton countFilters={10} />)}

      {dataConventionComparative?.comparativeResultsByCode?.myOwnResult && (
        <Typography align="center" color={Theme.colorConvention} display="block" size="20px" margin="2rem auto" weight="500">
          {`Datos de ${Object.keys(myOwnData).length > 0 ? myOwnData?.denomination : ''}`}
        </Typography>
      )}

      {(!isFetchingConventionComparative
      && dataConventionComparative?.comparativeResultsByCode?.myOwnResult)
        ? (
          <Table
            columns={myOwnColumns}
            data={[myOwnData]}
            theme={Theme.colorConvention}
            className="table-campaign-GPComparative-1"
          />
        ) : <TableSkeleton countCell={6} theme={Theme.colorConvention} />}

      <br />

      {dataCurrentUserInfo && (
        <Typography align="center" color={Theme.colorConvention} display="block" size="20px" margin="2rem auto" weight="500">
          {`Datos convención ${dataCurrentUserInfo?.me?.plans?.nodes[0]?.description}`}
        </Typography>
      )}

      {!isLoadingConventionComparativeXlsX ? (
        <CardDataComparativeExport
          data={dataCurrentUserInfo}
          dependency={dataConventionComparativeXlsx}
          fileName="convention_mgp"
        />
      ) : (
        <Card background={Theme.colorWhite} display="flex" alignItems="center" justifyContent="flex-end" gap="1rem" margin="1rem 0 0.5rem">
          <button type="button" className="btn-outline-secondary">
            <span>
              <LoadingSpinner color="#666" width="16px" />
              Cargando XLSX...
            </span>
          </button>
        </Card>
      )}

      {readyToExport === true && (
        <Loading message="Estamos procesando su fichero. Esto puede tardar unos minutos." tooltip />
      )}

      {
        (!isFetchingConventionComparative && dataConventionComparative !== undefined) ? (
          <Table
            columns={otherColumns}
            data={otherData}
            theme={Theme.colorConvention}
            className="table-campaign-GPComparative-2"
            countsRecords={dataConventionComparative?.comparativeResultsByCode?.resultsCount}
            defaultPageSize={10}
            countPerPage={10}
            globalParams={globalParams}
            setGlobalParams={setGlobalParams}
          />
        ) : <TableSkeleton countCell={6} theme={Theme.colorConvention} />
      }
    </Container>
  );
}
